import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { AnalyticsState } from 'redux/reducers/analytics';

export const analyticsSelector = (state: RootState): AnalyticsState => state.analytics;

export const weeklyAnalyticsSelector = createSelector(
  analyticsSelector,
  (analytics) => analytics.weeklyAnalytics
);

export const newUsersStatusAnalyticsSelector = createSelector(
  analyticsSelector,
  (analytics) => analytics.newUsersStatusAnalytics
);

export const churnUsersAnalyticsSelector = createSelector(
  analyticsSelector,
  (analytics) => analytics.churnUsersAnalytics
);

export const usersSubscriptionAnalyticsSelector = createSelector(
  analyticsSelector,
  (analytics) => analytics.usersSubscriptionAnalytics
);

export const analyticsLoadingSelector = createSelector(
  analyticsSelector,
  (analytics) => analytics.loading
);