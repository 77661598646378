import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  GetWeeklyAnalyticsResponse,
  NewUsersStatusAnalyticsResponse,
  ChurnUsersAnalyticsResponse,
  UsersSubscriptionAnalyticsResponse,
  AnalyticsRequest,
} from 'types/analytics';

export interface AnalyticsState {
  loading: boolean;
  weeklyAnalytics: GetWeeklyAnalyticsResponse | null;
  newUsersStatusAnalytics: NewUsersStatusAnalyticsResponse | null;
  churnUsersAnalytics: ChurnUsersAnalyticsResponse | null;
  usersSubscriptionAnalytics: UsersSubscriptionAnalyticsResponse | null;
}

const initialState: AnalyticsState = {
  loading: false,
  weeklyAnalytics: null,
  newUsersStatusAnalytics: null,
  churnUsersAnalytics: null,
  usersSubscriptionAnalytics: null,
};

const analyticsSlice = createSlice({
  name: 'analytics',
  initialState: initialState,
  reducers: {
    getWeeklyAnalytics: (state: AnalyticsState) => {
      state.loading = true;
    },
    getWeeklyAnalyticsSuccess: (state: AnalyticsState, action: PayloadAction<GetWeeklyAnalyticsResponse>) => {
      state.loading = false;
      state.weeklyAnalytics = action.payload;
    },
    getWeeklyAnalyticsError: (state: AnalyticsState, action: PayloadAction<any>) => {
      state.loading = false;
    },
    getNewUsersStatusAnalytics: (state: AnalyticsState, action: PayloadAction<AnalyticsRequest>) => {
      state.loading = true;
    },
    getNewUsersStatusAnalyticsSuccess: (state: AnalyticsState, action: PayloadAction<NewUsersStatusAnalyticsResponse>) => {
      state.loading = false;
      state.newUsersStatusAnalytics = action.payload;
    },
    getNewUsersStatusAnalyticsError: (state: AnalyticsState, action: PayloadAction<any>) => {
      state.loading = false;
    },
    getChurnUsersAnalytics: (state: AnalyticsState, action: PayloadAction<AnalyticsRequest>) => {
      state.loading = true;
    },
    getChurnUsersAnalyticsSuccess: (state: AnalyticsState, action: PayloadAction<ChurnUsersAnalyticsResponse>) => {
      state.loading = false;
      state.churnUsersAnalytics = action.payload;
    },
    getChurnUsersAnalyticsError: (state: AnalyticsState, action: PayloadAction<any>) => {
      state.loading = false;
    },
    getUsersSubscriptionAnalytics: (state: AnalyticsState, action: PayloadAction<AnalyticsRequest>) => {
      state.loading = true;
    },
    getUsersSubscriptionAnalyticsSuccess: (state: AnalyticsState, action: PayloadAction<UsersSubscriptionAnalyticsResponse>) => {
      state.loading = false;
      state.usersSubscriptionAnalytics = action.payload;
    },
    getUsersSubscriptionAnalyticsError: (state: AnalyticsState, action: PayloadAction<any>) => {
      state.loading = false;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const analyticsActions = analyticsSlice.actions;

// Reducer
const analyticsReducer = analyticsSlice.reducer;
export default analyticsReducer;
