import { GuideCategoryEnum } from 'models/hotspot';

export const HOTSPOTS_CATEGORIES = [
  {
    label: '‘Hood Hopping',
    value: GuideCategoryEnum.HOOD_HOPPING,
  },
  {
    label: 'Classic Eats',
    value: GuideCategoryEnum.CLASSIC_EATS,
  },
  {
    label: 'Escape The City',
    value: GuideCategoryEnum.ESCAPE_CITY,
  },
  {
    label: 'Wine Guide',
    value: GuideCategoryEnum.WINE_GUIDE,
  },
  {
    label: 'Best Bars',
    value: GuideCategoryEnum.BEST_BARS,
  },
  {
    label: 'Boutique Hotels',
    value: GuideCategoryEnum.BOUTIQUE_HOTELS,
  },
  {
    label: '48 Hours In:',
    value: GuideCategoryEnum.HOURS_48_IN,
  },
  {
    label: 'SLAY',
    value: GuideCategoryEnum.SLAY,
  },
  {
    label: 'Work Vibez',
    value: GuideCategoryEnum.WORK_VIBEZ,
  },
  {
    label: 'Top 6',
    value: GuideCategoryEnum.TOP_6,
  },
  {
    label: 'Eat Like A Local',
    value: GuideCategoryEnum.LOCAL_EATS,
  },
];
