import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page, WhiteBox } from 'components';
import Stack from '@mui/material/Stack';
import { useEffect, useMemo } from 'react';
import { analyticsActions } from 'redux/reducers/analytics';
import AnalyticReportsBlock from './analyticReports';
import {
  newUsersStatusAnalyticsSelector,
  churnUsersAnalyticsSelector,
  usersSubscriptionAnalyticsSelector,
} from 'redux/selectors/analytics';
import { ReportData } from 'types/analytics';

const UsersAnalytics = () => {
  // selectors
  const newUsersStatusesAnalytics = useAppSelector(newUsersStatusAnalyticsSelector);
  const churnUsersAnalytics = useAppSelector(churnUsersAnalyticsSelector);
  const usersSubscriptionAnalytics = useAppSelector(usersSubscriptionAnalyticsSelector);

  // hooks
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(analyticsActions.getNewUsersStatusAnalytics({}));
    dispatch(analyticsActions.getUsersSubscriptionAnalytics({}));
    dispatch(analyticsActions.getChurnUsersAnalytics({}));
  }, [dispatch]);

  const newUsersStatusesAnalyticsReportData = useMemo(() => {
    if (!newUsersStatusesAnalytics) {
      return [];
    }
    const rejectedAnalytic = {
      label: 'Rejected users',
      count: +newUsersStatusesAnalytics.rejectedCount,
    };
    const activeAnalytic = {
      label: 'Active users',
      count: +newUsersStatusesAnalytics.activeCount,
    };
    return {
      reportData: [activeAnalytic, rejectedAnalytic],
      total: +newUsersStatusesAnalytics.newCount,
    };
  }, [newUsersStatusesAnalytics]) as ReportData;

  const usersSubscriptionAnalyticsReportData = useMemo(() => {
    if (!usersSubscriptionAnalytics) {
      return [];
    }
    const rejectedAnalytic = {
      label: 'Global to premium',
      count: +usersSubscriptionAnalytics.globalToPremiumCount,
      color: 'secondary',
    };
    const activeAnalytic = {
      label: 'Premium to global',
      count: +usersSubscriptionAnalytics.premiumToGlobalCount,
      color: 'secondary',
    };
    return { reportData: [activeAnalytic, rejectedAnalytic] };
  }, [usersSubscriptionAnalytics]) as ReportData;

  const churnUsersAnalyticsReportData = useMemo(() => {
    if (!churnUsersAnalytics) {
      return [];
    }
    const rejectedAnalytic = {
      label: 'Inactive',
      count: +churnUsersAnalytics.inactiveCount,
    };
    const activeAnalytic = {
      label: 'Paused',
      count: +churnUsersAnalytics.pausedCount,
    };
    return {
      reportData: [activeAnalytic, rejectedAnalytic],
      total: +churnUsersAnalytics.activeCount,
    };
  }, [churnUsersAnalytics]) as ReportData;

  return (
    <Page title="Users analytics">
      <WhiteBox>
        <Stack flexDirection="row" width="100%">
          <AnalyticReportsBlock
            title="New users"
            report={newUsersStatusesAnalyticsReportData}
            totalLabel='New users'
          />
          <AnalyticReportsBlock
            title="Churn users"
            report={churnUsersAnalyticsReportData}
            totalLabel='Active users'
          />
        </Stack>
        <AnalyticReportsBlock
          title="Subscriptions"
          report={usersSubscriptionAnalyticsReportData}
          percentageVisible={false}
        />
      </WhiteBox>
    </Page>
  );
};

export default UsersAnalytics;
