import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { AppState } from 'redux/reducers/app';

export const appSelector = (state: RootState): AppState => state.app;

export const toastSelector = createSelector(appSelector, (app) => app.toast);

export const countriesSelector = createSelector(appSelector, (app) => app.countries);

export const appPlacesSelector = createSelector(appSelector, (app) => app.places);
