import {
  dashboard,
  experiences,
  users,
  places,
  cities,
  products,
  checkIns,
  IconRooms,
  IconTransactions,
  announcements,
  guides,
} from 'assets/svg/sidebar';

export type RouteType = {
  path: string;
  label: string;
  icon?: string;
  sublinks?: RouteType[];
  handleOnClick?: () => void;
};

export type SideBarProps = {
  displayText: string;
  icon?: string;
};

const sideBarRoutes: RouteType[] = [
  {
    label: 'Dashboard',
    path: '/dashboard',
    icon: dashboard,
    sublinks: [
      { path: '/dashboard/home', label: 'Home' },
      { path: '/dashboard/users', label: 'Users' },
    ],
  },
  {
    label: 'Users',
    path: '/membership',
    icon: users,
    sublinks: [
      { path: '/membership/all-members', label: 'All members' },
      {
        path: '/membership/awaiting-review-members',
        label: 'Awaiting review',
        handleOnClick: () => {
          window.open('/membership/awaiting-review-members');
        },
      },
      { path: '/membership/heat-map', label: 'Heat map' },
    ],
  },
  {
    label: 'Experiences',
    path: '/experiences',
    icon: experiences,
    sublinks: [
      { path: '/experiences/upcoming', label: 'Upcoming' },
      { path: '/experiences/new', label: 'New' },
      { path: '/experiences/history', label: 'History' },
    ],
  },
  {
    label: 'Check-ins',
    path: '/checkins',
    icon: checkIns,
    sublinks: [
      { path: '/checkins', label: 'All check-ins' },
      { path: '/checkins/new', label: 'Manual check-in' },
    ],
  },
  {
    label: 'Reservations',
    path: '/reservations',
    icon: checkIns,
  },
  {
    label: 'Cities',
    path: '/cities',
    icon: cities,
    sublinks: [
      { path: '/cities/all-cities', label: 'All cities' },
      { path: '/cities/new', label: 'New' },
    ],
  },
  {
    label: 'Places',
    path: '/places',
    icon: places,
    sublinks: [
      { path: '/places/all-places', label: 'All places' },
      { path: '/places/new', label: 'New' },
    ],
  },
  {
    label: 'Y list',
    path: '/hotspots',
    icon: guides,
    sublinks: [
      { path: '/hotspots/all-hotspots', label: 'All Y spots' },
      { path: '/hotspots/new', label: 'New' },
    ],
  },
  {
    label: 'Matches',
    path: '/matches',
    icon: IconTransactions,
    sublinks: [
      { path: '/matches/all-matches', label: 'All Matches' },
      { path: '/matches/matches-review', label: 'Review Matches' },
      { path: '/matches/new', label: 'New' },
    ],
  },
  {
    label: 'Products',
    path: '/products',
    icon: products,
  },
  {
    label: 'Announcements',
    path: '/announcements',
    icon: announcements,
  },
  {
    label: 'Tags',
    path: '/tags',
    icon: products,
  },
  {
    label: 'Rooms',
    path: '/rooms',
    icon: IconRooms,
    handleOnClick: () => {
      window.open('/rooms');
    },
  },
  {
    label: 'Transactions',
    path: '/transactions',
    icon: IconTransactions,
  },
];

export default sideBarRoutes;
