import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page, WhiteBox } from 'components';
import { TagDto } from 'models/tag';
import React, { useCallback, useEffect, useState } from 'react';
import { tagsActions } from 'redux/reducers/tags';
import {
  allTagsSelector,
  tagsCategoryFilterSelector,
  tagsLoadingSelector,
  tagsSearchValueSelector,
  tagsTotalSelector,
} from 'redux/selectors/tags';
import { useDidUpdateEffect } from 'utils/hooks';
import NewTagForm from './newTagForm';
import TagsFilters from './tagsFilters';
import TagsTable from './tagsTable';

const Tags: React.FC = () => {
  const dispatch = useAppDispatch();
  const tags = useAppSelector(allTagsSelector);
  const searchValue = useAppSelector(tagsSearchValueSelector);
  const categoryFilter = useAppSelector(tagsCategoryFilterSelector);

  const loading = useAppSelector(tagsLoadingSelector);
  const total = useAppSelector(tagsTotalSelector);

  const [editableTag, setEditableTag] = useState<TagDto | null>(null);

  const [offset, setOffset] = useState(0);

  const onSearchChange = useCallback((searchText: string) => {
    dispatch(tagsActions.setSearchValue(searchText));
  }, []);

  const handleEditTag = useCallback((tag: typeof editableTag) => {
    setEditableTag(tag);
  }, []);

  useEffect(() => {
    setOffset(0);
    dispatch(
      tagsActions.getTags({
        category: categoryFilter,
      })
    );

    return () => {
      dispatch(tagsActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(
      tagsActions.getTags({
        query: searchValue,
        offset: 0,
        category: categoryFilter,
      })
    );
  }, [searchValue, categoryFilter]);

  const loadMore = () => {
    dispatch(
      tagsActions.getTags({
        query: searchValue,
        offset: offset + 10,
        category: categoryFilter,
      })
    );
    setOffset(offset + 10);
  };

  useDidUpdateEffect(() => {
    dispatch(tagsActions.updateOffset({ offset: offset }));
  }, [offset]);

  return (
    <Page title="Tags">
      <Stack direction="row" marginTop={2}>
        <WhiteBox width="66%" height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            borderBottom="1px solid rgb(247, 247, 255)"
            paddingBottom="20px"
          >
            <Typography variant="h2">Total: {total}</Typography>
            <TagsFilters
              categoryFilterSelectedValue={categoryFilter}
              onCategoryFilterChange={(val) =>
                dispatch(tagsActions.setCategoryFilter(val))
              }
              onSearchChange={onSearchChange}
              searchValue={searchValue}
            />
          </Stack>
          <TagsTable
            total={total || 0}
            loading={loading}
            tags={tags || []}
            loadMore={loadMore}
            handleEditTag={handleEditTag}
            onDelete={(id: string) => dispatch(tagsActions.deleteTag({ id }))}
          />
        </WhiteBox>
        <WhiteBox width="33%" margin="0 0 0 16px" height="fit-content">
          <NewTagForm editableTag={editableTag} handleEditTag={handleEditTag} />
        </WhiteBox>
      </Stack>
    </Page>
  );
};

export default Tags;
