import { useEffect, useCallback, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import { Page, WhiteBox } from 'components';
import Typography from '@mui/material/Typography';
import MembersTable from './membersTable';
import MembersFilters, { CMOption, RegionOption, StatusOption } from './membersFilters';
import { usersActions } from 'redux/reducers/users';
import {
  allUsersSelector,
  totalUsersSelector,
  usersLoadingSelector,
} from 'redux/selectors/users';
import { useDidUpdateEffect } from 'utils/hooks';
import { SortOrder } from 'types/common';
import { MembershipSortBy } from 'types/users';
import {
  MEMBERS_FILTER_CM,
  MEMBERS_FILTER_REGION,
  MEMBERS_FILTER_STATUS,
  MEMBERS_SEARCH_VALUE,
  MEMBERS_SORT_BY,
  MEMBERS_SORT_DIRECTION,
} from 'utils/storageKeys';

const Membership: React.FC = () => {
  const statusStorage = sessionStorage.getItem(MEMBERS_FILTER_STATUS) as StatusOption;
  const regionStorage = sessionStorage.getItem(MEMBERS_FILTER_REGION) as RegionOption;
  const cmFilterStorage = sessionStorage.getItem(MEMBERS_FILTER_CM) as CMOption;

  const searchValueStorage = sessionStorage.getItem(MEMBERS_SEARCH_VALUE);
  const sortByStorage = sessionStorage.getItem(MEMBERS_SORT_BY) as MembershipSortBy;
  const sortOrderStorage = sessionStorage.getItem(MEMBERS_SORT_DIRECTION) as SortOrder;
  const [searchValue, setSearchValue] = useState(searchValueStorage || '');
  const [statusFilter, setStatusFilter] = useState<StatusOption>(statusStorage || 'all');
  const [regionFilter, setRegionFilter] = useState<RegionOption>(regionStorage || 'all');
  const [cmFilter, setCmFilter] = useState<CMOption>(cmFilterStorage || 'all');

  const [offset, setOffset] = useState(0);
  const [sortBy, setSortBy] = useState<MembershipSortBy | null>(
    sortByStorage || 'createdAt'
  );
  const [sortDirection, setSortDirection] = useState<SortOrder | null>(
    sortOrderStorage || 'desc'
  );

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const users = useAppSelector(allUsersSelector);
  const total = useAppSelector(totalUsersSelector);
  const loading = useAppSelector(usersLoadingSelector);
  const statusValue = statusFilter == 'all' ? '' : statusFilter;
  const regionValue = regionFilter == 'all' ? '' : regionFilter;
  const cmFilterValue = cmFilter == 'all' ? '' : cmFilter;
  const sort = `&sort[${sortBy}]=${sortDirection}`;

  useEffect(() => {
    if (!users?.length) {
      setOffset(0);
      dispatch(
        usersActions.getUsers({
          query: searchValue,
          status: statusValue,
          region: regionValue,
          cmFilter: cmFilterValue,
          offset: 0,
          sort,
        })
      );
    }

    return () => {
      dispatch(usersActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(
      usersActions.getUsers({
        query: searchValue,
        status: statusValue,
        region: regionValue,
        cmFilter: cmFilterValue,
        offset: 0,
        sort,
      })
    );
  }, [searchValue, statusFilter, regionFilter, cmFilter, sortBy, sortDirection]);

  useDidUpdateEffect(() => {
    dispatch(usersActions.updateOffset({ offset: offset }));
  }, [offset]);

  const loadMore = () => {
    dispatch(
      usersActions.getUsers({
        query: searchValue,
        status: statusValue,
        region: regionValue,
        cmFilter: cmFilterValue,
        offset: offset + 10,
        sort,
      })
    );
    setOffset(offset + 10);
  };

  const onStatusFilterChange = useCallback(
    (status: StatusOption) => {
      setStatusFilter(status);
      sessionStorage.setItem(MEMBERS_FILTER_STATUS, status);
    },
    [navigate, dispatch]
  );

  const onCmFilterChange = useCallback(
    (cmFilter: CMOption) => {
      setCmFilter(cmFilter);
      sessionStorage.setItem(MEMBERS_FILTER_CM, cmFilter);
    },
    [navigate, dispatch]
  );

  const onRegionFilterChange = useCallback(
    (region: RegionOption) => {
      setRegionFilter(region);
      sessionStorage.setItem(MEMBERS_FILTER_REGION, region);
    },
    [navigate, dispatch]
  );

  const onSearchChange = useCallback(
    (searchText: string) => {
      setSearchValue(searchText);
      sessionStorage.setItem(MEMBERS_SEARCH_VALUE, searchText);
    },
    [navigate, dispatch]
  );

  const onSortHandler = useCallback(
    (orderBy: MembershipSortBy, order: SortOrder) => {
      setSortBy(orderBy);
      setSortDirection(order);
      sessionStorage.setItem(MEMBERS_SORT_BY, orderBy);

      sessionStorage.setItem(MEMBERS_SORT_DIRECTION, order);
    },
    [navigate, dispatch]
  );

  return (
    <Page className="membership-page" title="Membership">
      <WhiteBox>
        <Box className="membership-header">
          <Typography variant="h2">Total: {total}</Typography>
          <MembersFilters
            onStatusChange={onStatusFilterChange}
            onRegionChange={onRegionFilterChange}
            onCmFilterChange={onCmFilterChange}
            onSearchChange={onSearchChange}
            status={statusFilter}
            region={regionFilter}
            cmFilter={cmFilter}
            searchValue={searchValue}
          />
        </Box>
        <MembersTable
          loading={loading}
          total={total}
          users={users || []}
          onLoadMore={loadMore}
          order={sortDirection}
          orderBy={sortBy}
          onSort={onSortHandler}
        />
      </WhiteBox>
    </Page>
  );
};

export default Membership;
