import Stack from '@mui/material/Stack';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { SearchInput } from 'components';
import PlacesAutocomplete from 'components/common/PlacesAutocomplete';
import React, { useEffect, useMemo } from 'react';
import { appActions } from 'redux/reducers/app';
import { appPlacesSelector } from 'redux/selectors/app';
import { AutoSelectOptionType } from 'types/common';
import { DateFilterOption } from 'types/experiences';
import DateFilters, { DateRange } from './dateFilter';

type Props = {
  onSearchChange: (value: string) => void;
  onDateChange: (value: DateRange) => void;
  onPlaceChange: (value: AutoSelectOptionType[]) => void;
  searchValue: string;
  selectedPlaces: string[];
  options: DateFilterOption[];
  defaultOption: DateFilterOption;
};

const ReservationsFilters: React.FC<Props> = ({
  onSearchChange,
  onDateChange,
  onPlaceChange,
  searchValue,
  selectedPlaces,
  options,
  defaultOption,
}) => {
  const dispatch = useAppDispatch();
  const places = useAppSelector(appPlacesSelector);

  const tranformedPlaces = useMemo(() => {
    return selectedPlaces.map((place) => {
      const pl = places.find((e) => e._id === place);
      return {
        label: pl?.name || '',
        value: pl?._id || '',
      };
    });
  }, [places, selectedPlaces]);

  useEffect(() => {
    if (!places.length) {
      dispatch(appActions.getAllPlaces());
    }
  }, [dispatch, places]);

  return (
    <Stack
      width="90%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack width="30%" maxWidth={300}>
        <PlacesAutocomplete
          label="Place"
          name="place"
          sx={{ maxWidth: 500 }}
          selected={tranformedPlaces ?? undefined}
          hasAllOption
          multiple={true}
          onChange={(selected) => {
            if (Array.isArray(selected)) {
              onPlaceChange(selected);
            }
          }}
        />
      </Stack>
      <DateFilters
        options={options}
        defaultOption={defaultOption}
        onDateRangeChange={onDateChange}
      />
      <Stack width="30%" marginLeft={3} maxWidth={300}>
        <SearchInput
          className="filters-item"
          name="search"
          onChange={(v) => onSearchChange(v)}
          value={searchValue}
        />
      </Stack>
    </Stack>
  );
};

export default ReservationsFilters;
