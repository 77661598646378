import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppSelector } from 'app/hooks';
import { Page, WhiteBox } from 'components';
import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { appActions } from "redux/reducers/app";
import { paymentsActions } from 'redux/reducers/payments';
import { paymentsTotalSelector } from 'redux/selectors/payments';
import { SortOrder } from 'types/common';
import { DateRange } from "types/experiences";
import { PaymentsSortBy } from 'types/payments';
import { useDidUpdateEffect } from 'utils/hooks';
import PaymentsTable from './paymentsTable';
import TransactionsFilters from './transactionsFilters';
import { CURRENCY_LIST } from "constans/currency";

const Payments: React.FC = () => {
  const dispatch = useDispatch();
  const [searchValue, setSearchValue] = useState('');
  const total = useAppSelector(paymentsTotalSelector);
  const [offset, setOffset] = useState(0);
  const [rangeDate, setRangeDate] = useState<DateRange | null>({ startDate: '', endDate: ''});
  const [type, setType] = useState('all');
  const [currentCurrency, setCurrentCurrency] = useState('USD');
  const [status, setStatus] = useState('all');
  const [sortBy, setSortBy] = useState<PaymentsSortBy | null>('transactionDate');
  const [sortDirection, setSortDirection] = useState<SortOrder | null>('desc');
  const sort = `&sort[${sortBy}]=${sortDirection}`;
  const typeValue = type === 'all' ? '' : type;

  const onTypeChange = (t: string) => {
    setType(t);
  };

  const onStatusChange = (status: string) => {
    setStatus(status);
  };

  useEffect(() => {
    
    setOffset(0);
    dispatch(paymentsActions.getPayments({ offset: 0, sort, type: typeValue, startDate: rangeDate?.startDate, endDate: rangeDate?.endDate }));

    return () => {
      dispatch(paymentsActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useEffect(() => {
    CURRENCY_LIST.forEach(currenncy => {
      dispatch(appActions.getCurrencies(currenncy));
    })
  }, []);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(paymentsActions.getPayments({ offset: 0, sort, type: typeValue, startDate: rangeDate?.startDate, endDate: rangeDate?.endDate, status, query: searchValue }));
  }, [sortBy, sortDirection, type, status, searchValue]);

  const loadMore = () => {
    dispatch(
      paymentsActions.getPayments({
        offset: offset + 10,
        sort,
        type: typeValue,
        startDate: rangeDate?.startDate, 
        endDate: rangeDate?.endDate
      })
    );
    setOffset(offset + 10);
  };

  useDidUpdateEffect(() => {
    dispatch(paymentsActions.updateOffset({ offset: offset }));
  }, [offset]);

  const onSortHandler = useCallback((orderBy: PaymentsSortBy, order: SortOrder) => {
    setSortBy(orderBy);
    setSortDirection(order);
  }, []);

  const onDateChange = (data: DateRange) => {
    if(!data){
      setRangeDate({startDate: '', endDate: ''});
    }else{
      setRangeDate(data);
    }
    dispatch(paymentsActions.getPayments({ offset: 0, sort, type: typeValue, startDate: data?.startDate, endDate: data?.endDate, status, query: searchValue }));
  }
  
  const handleDownloadFile = () => {
    dispatch(paymentsActions.getPaymentCsv({ offset: 0, sort, type: typeValue, startDate: rangeDate?.startDate, endDate: rangeDate?.endDate, status, query: searchValue, base: currentCurrency }));
  }

  const onCurrencyChange = (value: string) => {
    setCurrentCurrency(value)
  }

  return (
    <Page title="Transactions" className="transactions">
      <WhiteBox>
        <Stack
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid rgb(247, 247, 255)"
          paddingBottom="20px"
        >
          <Typography variant="h2">Total: {total}</Typography>
          <TransactionsFilters type={type} currency={currentCurrency} onTypeChange={onTypeChange} setSearchValue={setSearchValue} searchValue={searchValue} onDateChange={onDateChange} onCurrencyChange={onCurrencyChange} onStatusChange={onStatusChange} status={status} />
          <button className="download-csv" onClick={() => handleDownloadFile()}>Export CSV</button>
        </Stack>
        <PaymentsTable
          loadMore={loadMore}
          total={total}
          onSort={onSortHandler}
          order={sortDirection}
          orderBy={sortBy}
          currency={currentCurrency}
        />
      </WhiteBox>
    </Page>
  );
};

export default Payments;
