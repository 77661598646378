import { AxiosResponse } from 'axios';
import { CurrentUserResponse, LoginRequest, LoginResponse } from 'types/auth';
import axiosApi, { publicAxiosApi } from './config';
import { GetAllCountriesResponse } from 'types/countries';

const appApi = {
  async getCurrencies(currency: string): Promise<any> {
    const url = `https://api.exchangerate.host/latest?access_key=${process.env.REACT_APP_CURRENCY_API_KEY}&base=${currency.toUpperCase()}&symbols=GBP,EUR,USD,MXN`;
    const response = await fetch(url);
    const result = await response.json()

    return result;
  },
  getAllCountries(): Promise<GetAllCountriesResponse> {
    const url = 'countries';
    return axiosApi.get(url, { baseURL: process.env.REACT_APP_API_URL + '/api/dictionary' });
  },
};

export default appApi;
