import { Button } from '@mui/material';
import Stack from '@mui/material/Stack';
import { CustomSelect } from 'components';
import { MatchStatus } from 'models/match';
import React from 'react';

export type StatusOption = MatchStatus | 'all';

type SelectOption = {
  value: string;
  label: string;
};

export const statusOptions: SelectOption[] = [
  { value: 'all', label: 'All' },
  { value: MatchStatus.LIVE, label: 'Live' },
  { value: MatchStatus.CONNECTED, label: 'Connected' },
  { value: MatchStatus.QUEUED, label: 'Queued' },
  { value: MatchStatus.IGNORED, label: 'Ignored' },
];

type Props = {
  onStatusChange: (status: StatusOption) => void;
  onPublishClick: () => void;
  onLoadQueueClick: () => void;
  status: StatusOption;
};

const MatchesFilters: React.FC<Props> = ({
  onStatusChange,
  onPublishClick,
  onLoadQueueClick,
  status,
}) => {
  return (
    <Stack width="90%" flexDirection="row" alignItems="center" justifyContent="flex-end">
      <Stack flexDirection="row" marginRight={'24px'} gap={'12px'}>
        <Button
          name="load-queue"
          variant="contained"
          color="info"
          type="button"
          onClick={onLoadQueueClick}
        >
          {'LOAD THE QUEUE'}
        </Button>
        <Button
          name="publish"
          variant="contained"
          color="success"
          type="button"
          onClick={onPublishClick}
        >
          {'PUBLISH THE QUEUE'}
        </Button>
      </Stack>
      <Stack width="30%" maxWidth={300}>
        <CustomSelect
          className="filters-item"
          name="status"
          label="Status"
          options={statusOptions}
          value={status}
          onChange={(e) => onStatusChange(e.target.value)}
        />
      </Stack>
    </Stack>
  );
};

export default MatchesFilters;
