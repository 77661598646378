import { FC } from 'react';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';

interface Props {
  label: string;
  value: string;
  onValueClick?: () => void;
};

const MemberInfoLine: FC<Props> = ({ label, value, onValueClick }) => {
const isClickable = !!value && !!onValueClick;

const handleClick = () => {
  if (!isClickable) return;
  onValueClick();
};

  return (
    <Stack
      sx={{ marginBottom: '8px' }}
      direction={'row'}
      alignItems={'baseline'}
    >
      <Typography sx={{ marginRight: '8px' }} variant="caption">
        {label}:
      </Typography>
      <Typography
        onClick={handleClick}
        className="text-break"
        sx={{
          width: isClickable ? 'auto' : '100%',
          maxWidth: isClickable ? 'auto' : '70%',
          textDecoration: isClickable ? 'underline' : 'none',
          cursor: isClickable ? 'pointer' : 'auto',
        }}
        variant="body2"
      >
        {value}
      </Typography>
    </Stack>
  );
};

export default MemberInfoLine;
