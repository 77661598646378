import { Page, WhiteBox } from 'components';
import { FC } from 'react';
import { useParams } from 'react-router-dom';
import MatchForm from '../matchForm';

type QueryParams = {
  matchId: string;
};

const EditMatch: FC = () => {
  const { matchId } = useParams<QueryParams>();
  return (
    <Page title="Edit match">
      <WhiteBox>
        <MatchForm matchId={matchId} />
      </WhiteBox>
    </Page>
  );
};

export default EditMatch;
