import React from 'react';
import Stack from '@mui/material/Stack';
import { CustomSelect, SearchInput } from 'components';
import { TagCategoryEnum } from 'types/tag';

export const TAG_FILTERS = [
  {
    value: TagCategoryEnum.COMMUNITY,
    label: 'Community',
  },
  {
    value: TagCategoryEnum.INDUSTRY,
    label: 'Industry',
  },
  {
    value: TagCategoryEnum.INTEREST,
    label: 'Interest',
  },
  {
    value: TagCategoryEnum.PROFESSIONAL,
    label: 'Professional',
  },
  {
    value: TagCategoryEnum.TRAVEL_GROUP,
    label: 'Travel Group',
  },
];

type Props = {
  onSearchChange: (value: string) => void;
  searchValue: string;
  onCategoryFilterChange: (value: TagCategoryEnum) => void;
  categoryFilterSelectedValue: string;
};

const TagsFilters: React.FC<Props> = ({
  onSearchChange,
  onCategoryFilterChange,
  searchValue,
  categoryFilterSelectedValue,
}) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <CustomSelect
        sx={{ marginRight: '24px', width: '180px' }}
        label="Category"
        name="category"
        options={TAG_FILTERS}
        value={categoryFilterSelectedValue}
        onChange={(e) => onCategoryFilterChange(e.target.value)}
      />
      <SearchInput
        sx={{ width: '250px' }}
        name="search"
        onChange={(v) => onSearchChange(v)}
        value={searchValue}
      />
    </Stack>
  );
};

export default TagsFilters;
