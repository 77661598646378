import React, { useState, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import { PopUp } from 'components';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { Calendar } from 'react-date-range';
import { CalendarIcon } from 'assets/svg/svg-components';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import moment from 'moment';

type Props = {
  onDateChange: (value: Date) => void;
  selectedDate: Date;
};

const DateFilter: React.FC<Props> = ({ onDateChange, selectedDate }) => {
  const [activeOption, setActiveOption] = useState('today');

  useEffect(() => {
    if (!moment(selectedDate).isSame(moment(), 'day')) {
      setActiveOption('other')
    }
  }, [selectedDate]);

  const handleOptionClick = (opt: string) => {
    setActiveOption(opt);
    if (opt === 'today') {
      onDateChange(moment().startOf('day').toDate());
    }
  };

  return (
    <Stack
      sx={{
        padding: '3px',
        border: '1px solid rgb(247, 247, 255)',
        borderRadius: '17px',
        marginLeft: '18px',
      }}
      direction={'row'}
    >
      <Box
        onClick={() => handleOptionClick('today')}
        className={`event-date-filter-item ${activeOption === 'today' ? 'active' : ''}`}
      >
        <Typography className="filter-text" variant="body2">
          Today
        </Typography>
      </Box>
      <PopUp
        isOpen={false}
        onOpen={() => handleOptionClick('other')}
        position={'bottom right'}
        className="date-pop-up"
        trigger={
          <Box
            className={`event-date-filter-item ${
              activeOption === 'other' ? 'active' : ''
            }`}
          >
            <Typography
              sx={{ marginRight: '6px' }}
              className="filter-text"
              variant="body2"
            >
              Other
            </Typography>
            <Box className="date-icon-wrap">
              <CalendarIcon />
            </Box>
          </Box>
        }
        content={
          <Calendar
            editableDateInputs
            onChange={(date) => onDateChange(moment(date).startOf('day').toDate())}
            date={selectedDate}
            color={'#4c4ca0'}
          />
        }
      />
    </Stack>
  );
};

export default DateFilter;
