import { Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page, WhiteBox } from 'components';
import { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { matchesActions } from 'redux/reducers/matches';
import { matchesLoadingSelector, matchesReviewSelector } from 'redux/selectors/matches';
import MatchesReviewTable from './matchesReviewTable';

const MatchesReview: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const matchesReview = useAppSelector(matchesReviewSelector);
  const loading = useAppSelector(matchesLoadingSelector);

  const total = matchesReview.length;

  useEffect(() => {
    dispatch(matchesActions.getMatchesReview());
  }, [dispatch]);

  return (
    <Page title="Tags">
      <Stack direction="row" marginTop={2}>
        <WhiteBox width="100%" height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            borderBottom="1px solid rgb(247, 247, 255)"
            paddingBottom="20px"
          >
            <Typography variant="h2">Total: {total}</Typography>
          </Stack>
          <MatchesReviewTable loading={loading} matchReview={matchesReview || []} />
        </WhiteBox>
      </Stack>
    </Page>
  );
};

export default MatchesReview;
