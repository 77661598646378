import { FC, useCallback } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledTableCell, StyledTableRow, ReactLoader } from 'components';
import { useAppSelector } from 'app/hooks';
import { allHotspotsSelector, hotspotsLoadingSelector } from 'redux/selectors/hotspots';
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import { hotspotCitiesToString } from 'utils/helpers/hotspots';

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

const HotspotsTable: FC = () => {
  const navigate = useNavigate();
  const hotspots = useAppSelector(allHotspotsSelector);
  const loading = useAppSelector(hotspotsLoadingSelector);

  const onClickHandler = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    hotspotId: string
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(
        `${window.location.origin}/hotspots/details/${hotspotId}`,
        '_blank',
        'noreferrer'
      );
    } else {
      navigate(`/hotspots/details/${hotspotId}`);
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {!loading ? (
        hotspots?.length ? (
          <TableContainer sx={{ maxHeight: '69vh' }}>
            <Table aria-label="customized table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">
                    <TableSortLabel disabled>Name</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel disabled>Author</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel disabled>Cities</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel disabled>CreatedAt</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {hotspots.map((hotspot) => (
                  <StyledTableRow
                    key={hotspot._id}
                    className="table-row"
                    onClick={(e) => onClickHandler(e, hotspot._id)}
                  >
                    <StyledTableCell sx={mainTextStyles} align="left">
                      {hotspot.name}
                    </StyledTableCell>
                    <StyledTableCell sx={mainTextStyles} align="left">
                      {hotspot?.user ? `${hotspot?.user?.profile?.firstName} ${hotspot?.user?.profile?.lastName}` : hotspot?.author || ''}
                    </StyledTableCell>
                    <StyledTableCell sx={mainTextStyles} align="left">
                      {hotspot?.isGlobal ? 'Global' : hotspotCitiesToString(hotspot.cities) || ''}
                    </StyledTableCell>
                    <StyledTableCell sx={mainTextStyles} align="left">
                      {moment(hotspot.createdAt).format('DD-MM-YYYY HH:mm')}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null
      ) : (
        <ReactLoader />
      )}
    </Paper>
  );
};

export default HotspotsTable;
