import {
  HotspotsResponse,
  CreateHotspotRequest,
  CreateHotspotResponse,
  GetHotspotByIdResponse,
  EditHotspotRequest,
  EditHotspotResponse,
  DeleteHotspotResponse,
  HotspotsRequest,
} from 'types/hotspots';
import axiosApi from './config';

const hotspotsApi = {
  createHotspot(data: CreateHotspotRequest): Promise<CreateHotspotResponse> {
    const url = 'hotspots';
    return axiosApi.post(url, data);
  },
  editHotspot(data: EditHotspotRequest): Promise<EditHotspotResponse> {
    const url = `hotspots/${data.id}`;
    return axiosApi.put(url, data.data);
  },
  getHotspotById(id: string): Promise<GetHotspotByIdResponse> {
    const url = `hotspots/${id}`;
    return axiosApi.get(url);
  },
  getHotspots({ query = '', cities = '', limit = '10', offset = 0, isGlobal }: HotspotsRequest): Promise<HotspotsResponse[]> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });
    const url = `/hotspots?${params.toString()}${cities ? `&filter[cities]=${cities}` : ''}${isGlobal ? `&filter[isGlobal]=true` : ''}`;
    
    return axiosApi.get(url);
  },
  deleteHotspot(id: string): Promise<DeleteHotspotResponse> {
    const url = `hotspots/${id}`;
    return axiosApi.delete(url);
  },
};

export default hotspotsApi;
