import { FC } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import MemberInfoLine from 'pages/memberships/memberProfile/memberInfoLine';
import { Hotspot } from 'models/hotspot';
import moment from 'moment';
import { hotspotCitiesToString } from 'utils/helpers/hotspots';
import { CopyClickboardInput, WhiteBox } from 'components';

type Props = {
  hotspot: Hotspot | null;
};

const HotspotDetailsInfo: FC<Props> = ({ hotspot }) => {
  if (!hotspot) return null;
  return (
    <Stack direction="column">
      <Typography sx={{ marginBottom: '18px' }} variant="h2">
        Information
      </Typography>
      <MemberInfoLine value={hotspot?.name || ''} label="Name" />
      <MemberInfoLine value={hotspot?._id || ''} label="ID" />
      <MemberInfoLine
        value={
          hotspot?.user
            ? `${hotspot?.user?.profile?.firstName} ${hotspot?.user?.profile?.lastName}`
            : hotspot?.author || ''
        }
        label="Author"
      />
      <MemberInfoLine
        value={hotspot?.isGlobal ? 'Global' : hotspotCitiesToString(hotspot?.cities)}
        label="City"
      />
      <MemberInfoLine
        value={moment(hotspot?.createdAt).format('DD-MM-YYYY HH:mm') || ''}
        label="Created At"
      />
      <MemberInfoLine
        value={moment(hotspot?.updatedAt).format('DD-MM-YYYY HH:mm') || ''}
        label="Updated At"
      />
      <Stack style={{ marginTop: '16px' }}>
        <Typography sx={{ marginBottom: '16px' }} variant="h2">
          Links
        </Typography>
        <CopyClickboardInput
          sx={{ marginBottom: '12px', justifyContent: 'flex-start' }}
          inputSx={{ width: '350px' }}
          title="Direct to app"
          inputValue={`yayem://hotspots?id=${hotspot._id}`}
        />
        <CopyClickboardInput
          sx={{
            marginBottom: '12px',
            justifyContent: 'flex-start',
          }}
          inputSx={{ width: '350px' }}
          title="Dynamic Link"
          inputValue={`${process.env.REACT_APP_API_SIMPLIFIED_URL}/api/d/hs/${hotspot._id}`}
        />
      </Stack>
    </Stack>
  );
};

export default HotspotDetailsInfo;
