import { Box, Stack } from "@mui/material";
import Typography from '@mui/material/Typography';
import { GoogleMap, HeatmapLayer, useJsApiLoader, Libraries } from "@react-google-maps/api";

import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page } from 'components';
import { Member } from "models/member";
import { useEffect, useMemo, useRef, useState } from "react";
import { usersActions } from 'redux/reducers/users';
import HeatMapCitiesTable from "./heatMapCitiesTable";
import HeatMapMembersTable from "./heatMapMembersTable";

const containerStyle = {
    width: '100%',
    height: '700px'
};

const defaultCenter = {
    lat: 38.72272288,
    lng:  -9.144866305
};

const options = {
    streetViewControl: false,
    mapTypeControl: false,
    styles: [
        {
            "featureType": "all",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "weight": "2.00"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "geometry.stroke",
            "stylers": [
                {
                    "color": "#9c9c9c"
                }
            ]
        },
        {
            "featureType": "all",
            "elementType": "labels.text",
            "stylers": [
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#f2f2f2"
                }
            ]
        },
        {
            "featureType": "landscape",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [
                {
                    "saturation": -100
                },
                {
                    "lightness": 45
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#eeeeee"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#7b7b7b"
                }
            ]
        },
        {
            "featureType": "road",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        },
        {
            "featureType": "road.highway",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "simplified"
                }
            ]
        },
        {
            "featureType": "road.arterial",
            "elementType": "labels.icon",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [
                {
                    "color": "#46bcec"
                },
                {
                    "visibility": "on"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "geometry.fill",
            "stylers": [
                {
                    "color": "#c8d7d4"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.fill",
            "stylers": [
                {
                    "color": "#070707"
                }
            ]
        },
        {
            "featureType": "water",
            "elementType": "labels.text.stroke",
            "stylers": [
                {
                    "color": "#ffffff"
                }
            ]
        }
    ]
}

const mapLibraries: Libraries = ['visualization']

const HeatMap: React.FC = () => {
    const dispatch = useAppDispatch();
    const locations: Member[] = useAppSelector(state => state.users.locations);
    const locationsCount = useAppSelector(state => state.users.locationsCount);
    const locationsMembers = useAppSelector(state => state.users.locationsMembers);
    const [activeCitie, setActiveCitie] = useState(0);
    const [center, setCenter] = useState(defaultCenter);
    const limit = 10;
    const [offset, setOffset] = useState(0);

    const mapRef = useRef<google.maps.Map | null>(null);
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: process.env.REACT_APP_GOOGLE as string,
        libraries: mapLibraries,
    });

    useEffect(() => {
        dispatch(usersActions.getLocationsCount());
    }, []);

    useEffect(() => {
        if(locationsCount && locationsCount[activeCitie]){
            getLocationsMembers(offset);
        }
    }, [locationsCount, activeCitie]);

    var heatmapData = useMemo(() => {
        if (!isLoaded || !locations.length) return []

        return locations.map((member: Member) => {
            return new google.maps.LatLng(member.location[1], member.location[0])
        });
    }, [isLoaded, locations])

    const getLocationsMembers = (offset?: number) => {
        const { location } = locationsCount[activeCitie];

        const payload = !!location ? { lat: location[1], lng: location[0] } : { lat: null, lng: null }
        dispatch(usersActions.getLocationsMembers({...payload, offset, limit}));
    }

    const getCurrentBounds = () => {
        if (mapRef.current) {
            const northeast = mapRef.current.getBounds()?.getNorthEast();
            const southwest = mapRef.current.getBounds()?.getSouthWest();
            return {
                northeast: {
                    lat: northeast?.lat(),
                    lng: northeast?.lng()
                },
                southwest: {
                    lat: southwest?.lat(),
                    lng: southwest?.lng()
                }
            };
        }
    }

    const onLoad = (map: google.maps.Map) => {
        map.addListener('idle', () => {
            mapRef.current = map;
            dispatch(usersActions.getLocations(getCurrentBounds()));
        });
    }

    const handleClickCity = (index: number, city: any) => {
        if(city.location){
        setActiveCitie(index);
        setOffset(0);
            setCenter({
                lat: city.location[1],
                lng: city.location[0],
            })
        }
    }

    const loadMore = () => {
        getLocationsMembers(offset + limit)
        setOffset(offset + limit);
    }

    const handleDownloadFile = () => {
        dispatch(usersActions.getLocationsMembersCsv({ ...locationsCount[activeCitie], offset: 0, limit: 0 }));
      }

    return (
        <Page className="heat-map-page" title="Heat Map">
            {isLoaded ? (
                <GoogleMap
                    onLoad={onLoad}
                    mapContainerStyle={containerStyle}
                    center={center}
                    zoom={10}
                    options={options}
                >
                    <HeatmapLayer data={heatmapData} />
                </GoogleMap>
            ) : <></>
            }
            <Stack>
                <button className="download-csv" onClick={() => handleDownloadFile()}>Export CSV</button>
            </Stack>
            <Stack className="heat-map-wrapper" flexDirection={'row'}>
                <Stack className="heat-map-cities">
                    <HeatMapCitiesTable data={locationsCount} onClickRow={handleClickCity} />
                </Stack>
                <Stack className="heat-map-members">
                    <HeatMapMembersTable data={locationsMembers.data} onLoadMore={loadMore} total={locationsMembers.total} />
                </Stack>
            </Stack>
        </Page>
    );
};

export default HeatMap;
