import React, { useState } from 'react';
import Stack from '@mui/material/Stack';
import { CustomSelect } from 'components';
import DateFilters from './dateFilter';
import { DateFilterOption } from 'types/experiences';
import { Input } from '@mui/material';
import { SearchInput } from 'components';

type Props = {
  onTypeChange: (type: string) => void;
  onCurrencyChange: (currency: string) => void;
  onStatusChange: (type: string) => void;
  onDateChange: (range: any) => void;
  setSearchValue: (query: any) => void;
  type: string;
  currency: string;
  status: string;
  searchValue: string;
};

const typeOptions = [
  { value: 'all', label: 'All' },
  { value: 'experience', label: 'Experience' },
  { value: 'product', label: 'Product' },
  { value: 'daily_pass', label: 'Daily Pass' },
];
const currencyOptions = [
  { value: 'USD', label: 'USD' },
  { value: 'EUR', label: 'EUR' },
  { value: 'GBP', label: 'GBP' },
];

const statusOptions = [
  { value: 'all', label: 'All' },
  { value: 'succeeded', label: 'Succeeded' },
  { value: 'failed', label: 'Failed' },
  { value: 'canceled', label: 'Canceled' },
  { value: 'refunded', label: 'Refunded' },
];

const FILTER_OPTIONS: DateFilterOption[] = [
  {
    key: 'today',
    label: 'Today',
  },
  {
    key: 'week',
    label: 'This week',
  },
  {
    key: 'all',
    label: 'All',
  },
];

const TransactionsFilters: React.FC<Props> = ({
  searchValue,
  onStatusChange,
  setSearchValue,
  onTypeChange,
  type,
  onDateChange,
  onCurrencyChange,
  currency,
  status,
}) => {
  const onSearchChange = (v: any) => {};
  return (
    <Stack
      width="90%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack width="100%" className="transaction-select-filter selector-wrapper">
        <Stack width="100%" maxWidth={150}>
          <CustomSelect
            label="Type"
            name="type"
            options={typeOptions}
            value={type}
            onChange={(e) => onTypeChange(e.target.value)}
          />
        </Stack>
        <Stack width="30%" maxWidth={150}>
          <CustomSelect
            label="Status"
            name="status"
            options={statusOptions}
            value={status}
            onChange={(e) => onStatusChange(e.target.value)}
          />
        </Stack>
        <Stack className="selector-currency">
          <CustomSelect
            label="Currency"
            name="currency"
            options={currencyOptions}
            value={currency}
            onChange={(e) => onCurrencyChange(e.target.value)}
          />
        </Stack>
        <Stack>
          <SearchInput
            className="filters-item"
            name="search"
            onChange={(v) => setSearchValue(v)}
            value={searchValue}
          />
        </Stack>
      </Stack>
      <Stack>
        <DateFilters
          options={FILTER_OPTIONS}
          defaultOption={FILTER_OPTIONS.find((o) => o.key === 'all') as DateFilterOption}
          onDateRangeChange={onDateChange}
        />
      </Stack>
    </Stack>
  );
};

export default TransactionsFilters;
