import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material/styles';
import { downArrow } from 'assets/svg';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import { useDebounceSearch } from 'utils/hooks';
import Typography from '@mui/material/Typography';
import { Place } from 'models/place';
import placesApi from 'api/places';
import { AutoSelectOptionType } from 'types/common';

type Props = {
  label: string;
  selected?: AutoSelectOptionType | AutoSelectOptionType[];
  onChange: (value: AutoSelectOptionType | AutoSelectOptionType[]) => void;
  sx?: SxProps<Theme>;
  name?: string;
  multiple?: boolean;
  error?: boolean;
  errorText?: string;
  hasAllOption?: boolean;
};

const PlacesAutocomplete: React.FC<Props> = ({
  onChange,
  label,
  sx,
  selected,
  multiple = true,
  error = false,
  errorText,
  hasAllOption,
}) => {
  const [offset, setOffset] = useState(0);
  const [options, setOptions] = useState<Place[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounceSearch(searchValue, 50);

  useEffect(() => {
    getPlaces();
  }, [debounceSearchValue, offset]);

  const getPlaces = async () => {
    const result = await placesApi.getPlaces({
      query: searchValue,
      offset: offset,
      limit: '30',
    });
    setOptions(result.data);
  };

  const availableOptions: AutoSelectOptionType[] = useMemo(() => {
    const result = options.map((o) => ({
      label: `${o.name} (${o.city.name})`,
      value: o._id,
    }));

    if (hasAllOption) {
      result.unshift({label: 'All', value: 'all'});
    }
    return result;
  }, [options]);

  return (
    <>
      <Autocomplete
        className={error ? 'error-border' : ''}
        multiple={multiple}
        sx={sx}
        value={selected}
        onChange={(event, newValue) => {
          if (Array.isArray(newValue)) {
            onChange(newValue);
          } else {
            onChange(newValue);
          }
        }}
        disableClearable={true}
        options={availableOptions}
        getOptionLabel={(option: AutoSelectOptionType) => option.label}
        isOptionEqualToValue={(option, value) => option.value === value.value}
        renderOption={(props, option) => <li key={option.value} {...props}>{option.label}</li>}
        popupIcon={
          <img
            style={{
              padding: '8px',
              backgroundColor: 'transparent',
              right: '18px',
              top: 'calc(50% - 0.4em)',
            }}
            src={downArrow}
            alt=""
          />
        }
        renderInput={(params) => (
          <TextField
            {...params}
            value={searchValue}
            label={label}
            type='text'
            onChange={(e) => setSearchValue(e.target.value)}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.label}
              {...getTagProps({ index })}
              sx={{
                marginRight: '5px',
                backgroundColor: 'transparent',
                border: '1px solid rgba(30, 30, 112, 0.05)',
              }}
            />
          ))
        }
      />
      {error && (
        <Typography sx={{ margin: '-10px 0px 0 14px' }} color="error" variant="caption">
          {errorText || ''}
        </Typography>
      )}
    </>
  );
};

export default PlacesAutocomplete;
