import { HotspotMemberType } from 'models/hotspot';
import { SuggestedMemberDto } from 'models/member';
import { AutoSelectOptionType } from 'types/common';

export const membersToSelectOptions = (
  members: SuggestedMemberDto[] | HotspotMemberType[]
): AutoSelectOptionType[] => {
  if (!members?.length) return [];
  return members.map((m) => ({
    label: m.email,
    value: m._id,
  }));
};
