import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { AnnouncementsState } from 'redux/reducers/announcements';

export const announcementsSelector = (state: RootState): AnnouncementsState => state.announcements;

export const allAnnouncementsSelector = createSelector(
  announcementsSelector,
  (announcements) => announcements.announcements
);

export const announcementsLoadingSelector = createSelector(
  announcementsSelector,
  (announcements) => announcements.loading
);

export const announcementsErrorSelector = createSelector(
  announcementsSelector,
  (announcements) => announcements.error
);

export const announcementsTotalSelector = createSelector(
  announcementsSelector,
  (announcements) => announcements.total
);

export const announcementsSearchValueSelector = createSelector(
  announcementsSelector,
  (announcements) => announcements.searchValue
);

export const announcementsExpirationDateFilterSelector = createSelector(
  announcementsSelector,
  (announcements) => announcements.expirationDateFilter
);
