import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import usersApi from 'api/users';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { doneIcon, editIcon } from 'assets/svg';
import axios from 'axios';
import {
  CopyClickboardInput,
  CustomSelect,
  Page,
  ReactLoader,
  StatusBadge,
  WhiteBox,
} from 'components';
import SubscriptionStatusBadge from 'components/SubscriptionStatusBadge';
import CustomAutocomplete from 'components/common/CustomAutocomplete';
import UsersAutocomplete from 'components/common/UsersAutocomplete';
import ConfirmationModal from 'components/confirmModal';
import { useFormik } from 'formik';
import { Region, Status } from 'models/member';
import { ReferralDto } from 'models/referral';
import moment from 'moment';
import { FC, useEffect, useMemo, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { appActions } from 'redux/reducers/app';
import { citiesActions } from 'redux/reducers/cities';
import { usersActions } from 'redux/reducers/users';
import { countriesSelector } from 'redux/selectors/app';
import { allCitiesSelector } from 'redux/selectors/cities';
import {
  selectedUserCheckinsSelector,
  selectedUserMatchesSelector,
  selectedUserReferralsSelector,
  selectedUserSelector,
  selectedUserSuggestionsSelector,
} from 'redux/selectors/users';
import { AutoSelectOptionType } from 'types/common';
import { UpdateUserStatus } from 'types/users';
import { renderCurrency } from 'utils/currency';
import { useDidUpdateEffect } from 'utils/hooks';
import { membersToSelectOptions } from 'utils/user';
import * as yup from 'yup';
import UserAvatar from './avatar';
import MemberCheckinsTable from './memberCheckinsTable';
import MemberInfoLine from './memberInfoLine';
import MemberMatchesTable from './memberMatchesTable';
import MemberReferralsTable from './memberReferralsTable';
import MemberSuggestionsTable from './memberSuggestionsTable';

type QueryParams = {
  memberId: string;
};

enum UserAction {
  APPROVE = 'APPROVE',
  APPROVE_FORM = 'APPROVE_FORM',
  REJECT = 'REJECT',
  REJECT_FORM = 'REJECT_FORM',
  WAITING_LIST = 'WAITING_LIST',
}

type EditProfileValues = {
  cities: string[];
  region: Region | undefined;
  country: string;
};

const validationSchema = yup.object().shape({
  cities: yup.array().min(1, 'Required'),
  region: yup.string().required('Required'),
  country: yup.string().required('Required'),
});

const MemberProfile: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isPromoInputsActive, setPromoInputsActive] = useState(false);
  const [expediteCodeError, setExpediteCodeError] = useState(null);
  const [promo, setPromo] = useState('');
  const [expedite, setExpedite] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isAddSuggestionMode, setIsAddSuggestionMode] = useState(false);
  const [actionType, setActionType] = useState<UserAction | null>(null);

  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const { memberId } = useParams<QueryParams>();

  const member = useAppSelector(selectedUserSelector);
  const membersReferrals = useAppSelector(selectedUserReferralsSelector);
  const memberCheckins = useAppSelector(selectedUserCheckinsSelector);
  const memberSuggestions = useAppSelector(selectedUserSuggestionsSelector);
  const memberMatches = useAppSelector(selectedUserMatchesSelector);

  const [suggestedUsers, setSuggestedUsers] = useState<AutoSelectOptionType[]>(
    membersToSelectOptions(memberSuggestions)
  );

  const cities = useAppSelector(allCitiesSelector);
  const countries = useAppSelector(countriesSelector);

  const promoCode = useMemo(
    () => member?.onboardingSettings?.promoCode,
    [member?.onboardingSettings]
  );
  const expediteCode = useMemo(
    () => member?.onboardingSettings?.expediteBy?.expediteReferralCode,
    [member?.onboardingSettings?.expediteBy]
  );

  useEffect(() => {
    if (!cities?.length) {
      dispatch(citiesActions.getAllCities());
    }
  }, [cities]);

  useEffect(() => {
    if (!countries?.length) {
      dispatch(appActions.getAllCountries());
    }
  }, [countries]);

  useEffect(() => {
    if (memberId) {
      dispatch(usersActions.getUserById({ id: memberId }));
      dispatch(usersActions.getUserCheckins({ id: memberId }));
      dispatch(usersActions.getUserReferrals({ id: memberId }));
      dispatch(usersActions.getUserSuggestedMembers({ id: memberId }));
      dispatch(usersActions.getUserMatches({ id: memberId }));
    }

    return () => {
      dispatch(usersActions.clearSelectedUser());
    };
  }, [dispatch, memberId]);

  useEffect(() => {
    setPromo(promoCode || '');
    setExpedite(expediteCode || '');
  }, [promoCode, expediteCode]);

  const handleApprove = () => {
    setIsModalOpen(false);
    if (memberId)
      dispatch(
        usersActions.updateUserStatus({ id: memberId, status: UpdateUserStatus.APPROVE })
      );
  };

  useDidUpdateEffect(() => {
    setSuggestedUsers(membersToSelectOptions(memberSuggestions));
  }, [memberSuggestions]);

  const handleWaitingList = () => {
    setIsModalOpen(false);
    if (memberId)
      dispatch(
        usersActions.updateUserStatus({ id: memberId, status: UpdateUserStatus.WAITLIST })
      );
  };

  const handleReject = () => {
    setIsModalOpen(false);
    if (memberId)
      dispatch(
        usersActions.updateUserStatus({ id: memberId, status: UpdateUserStatus.REJECT })
      );
  };

  const handleApproveForm = () => {
    setIsModalOpen(false);
    if (memberId)
      dispatch(
        usersActions.updateUserStatus({
          id: memberId,
          status: UpdateUserStatus.APPROVE_FORM,
        })
      );
  };

  const handleRejectForm = () => {
    setIsModalOpen(false);
    if (memberId)
      dispatch(
        usersActions.updateUserStatus({
          id: memberId,
          status: UpdateUserStatus.REJECT_FORM,
        })
      );
  };

  const handleConfirm = () => {
    if (!actionType) return;

    switch (actionType) {
      case UserAction.APPROVE:
        handleApprove();
        break;
      case UserAction.WAITING_LIST:
        handleWaitingList();
        break;
      case UserAction.REJECT:
        handleReject();
        break;
      case UserAction.APPROVE_FORM:
        handleApproveForm();
        break;
      case UserAction.REJECT_FORM:
        handleRejectForm();
        break;
      default:
        return null;
    }
  };

  const handleBrazeClick = () => {
    if (!member?.brazeId) return;
    window.open(
      `https://dashboard-01.braze.eu/users/user_search/${member.brazeId}?locale=en`,
      '_blank',
      'noreferrer'
    );
  };

  const handleStripeClick = () => {
    if (!member?.customerId) return;
    window.open(
      `https://dashboard.stripe.com/customers/${member.customerId}`,
      '_blank',
      'noreferrer'
    );
  };

  const handlePromoInputsSave = async () => {
    if (!memberId) return;
    setPromoInputsActive(!isPromoInputsActive);
    try {
      if (isPromoInputsActive) {
        if (promo !== promoCode || expedite !== expediteCode) {
          await usersApi.setUserPromoCodes({
            id: memberId,
            promoCode: promo,
            expediteCode: expedite,
          });
        }
      }
    } catch (error) {
      if (axios.isAxiosError(error)) {
        setExpediteCodeError(error.response?.data.message);
      }
    }
  };

  const getReferralsCount = () => {
    if (!membersReferrals?.length) return null;
    const active = membersReferrals?.filter(
      (item: ReferralDto) =>
        item.status === Status.ACTIVE || item.status === Status.PAST_DUE
    );
    const pending = membersReferrals?.filter(
      (item: ReferralDto) =>
        item.status === Status.AWAITING_REVIEW || item.status === Status.APPROVED
    );

    return {
      active: active?.length || 0,
      pending: pending?.length || 0,
    };
  };

  const handleSubmit = async (values: EditProfileValues): Promise<void> => {
    if (!memberId) return;
    setIsEditMode(false);

    dispatch(
      usersActions.updateUserProfile({
        id: memberId,
        profile: {
          secondaryCities: values.cities,
          region: values.region,
          countryOfResidence: values.country,
        },
      })
    );
  };

  const handleSubmitSuggestions = () => {
    if (!memberId) return;
    setIsAddSuggestionMode(false);
    const ids = suggestedUsers.map((u) => u.value);

    dispatch(
      usersActions.setUserSuggestions({
        id: memberId,
        suggestedMembers: ids,
      })
    );
  };

  const formik = useFormik({
    initialValues: {
      cities: member?.profile?.secondaryCities || [],
      region: member?.profile?.region,
      country: member?.profile?.countryOfResidence || '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
    enableReinitialize: true,
  });

  if (!member) return <ReactLoader />;

  const isPromoVisible =
    member?.status === Status.AWAITING_REVIEW ||
    member?.status === Status.WAITLIST ||
    member?.status === Status.NEW ||
    member?.status === Status.APPROVED;

  const referralsCount = getReferralsCount();

  const handleSaveChanges = () => {
    formik.handleSubmit();
  };

  const isActionsVisible =
    member?.status === Status.AWAITING_REVIEW || member?.status === Status.WAITLIST;

  const isFormUsersActionsVisible = member?.status === Status.FORM;

  return (
    <Page className="member-page" onBackClick={() => navigate(-1)}>
      <Grid marginBottom={'24px'} container spacing={2}>
        <Grid item xs={7}>
          <WhiteBox height="100%">
            <Stack direction="row">
              <Grid item xs={8}>
                <Stack>
                  <Stack marginBottom={2}>
                    <Typography variant="h1">
                      {member.profile?.firstName} {member.profile?.lastName}
                    </Typography>
                  </Stack>
                  <Typography
                    sx={{ marginBottom: '8px', color: 'rgb(116, 116, 129)' }}
                    component={'span'}
                    variant="caption"
                  >
                    EMAIL:{' '}
                    <Typography component={'span'} variant="h3">
                      {member.email}
                    </Typography>
                  </Typography>
                  <Typography
                    sx={{ marginBottom: '8px', color: 'rgb(116, 116, 129)' }}
                    component={'span'}
                    variant="caption"
                  >
                    USER ID:{' '}
                    <Typography component={'span'} variant="h3">
                      {member._id}
                    </Typography>
                  </Typography>
                  {member?.profile?.phoneNumber && (
                    <Typography
                      sx={{ color: 'rgb(116, 116, 129)' }}
                      component={'span'}
                      variant="caption"
                    >
                      PHONE:{' '}
                      <Typography component={'span'} variant="h3">
                        {member?.profile?.phoneNumber}
                      </Typography>
                    </Typography>
                  )}
                  <StatusBadge className="member-profile-status" status={member.status} />
                </Stack>
              </Grid>
              <Grid item xs={4} className="member-profile-avatar">
                <UserAvatar src={member.profile?.avatar || ''} />
              </Grid>
            </Stack>
          </WhiteBox>
        </Grid>
        <Grid item xs={5}>
          {isPromoVisible ? (
            <WhiteBox>
              <Stack alignItems={'center'} direction={'row'}>
                <TextField
                  onChange={(e) => setPromo(e.target.value)}
                  className="promo-item"
                  disabled={!isPromoInputsActive}
                  label="Promo"
                  value={promo.toLocaleUpperCase()}
                  error={!!expediteCodeError}
                />
                <TextField
                  onChange={(e) => setExpedite(e.target.value)}
                  className="promo-item"
                  disabled={!isPromoInputsActive}
                  label="Expedite"
                  value={expedite.toLocaleUpperCase()}
                  error={!!expediteCodeError}
                />
                <IconButton className="profile-promo-btn" onClick={handlePromoInputsSave}>
                  <img src={isPromoInputsActive ? doneIcon : editIcon} alt="btn-icon" />
                </IconButton>
              </Stack>
              {expediteCodeError && (
                <Typography
                  sx={{ margin: '12px 0 0 12px' }}
                  variant="caption"
                  color="error"
                >
                  {expediteCodeError}
                </Typography>
              )}
              {isActionsVisible && (
                <>
                  <Button
                    onClick={() => {
                      setIsModalOpen(true);
                      setActionType(UserAction.APPROVE);
                    }}
                    sx={{ marginTop: '24px', color: '#fff' }}
                    variant="contained"
                    color="success"
                    fullWidth
                  >
                    APPROVE APPLICATION
                  </Button>
                  {member?.status != Status.WAITLIST && (
                    <Button
                      onClick={() => {
                        setIsModalOpen(true);
                        setActionType(UserAction.WAITING_LIST);
                      }}
                      sx={{ marginTop: '24px', height: 50 }}
                      variant="outlined"
                      color="custom"
                      fullWidth
                    >
                      WAITING LIST
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      setIsModalOpen(true);
                      setActionType(UserAction.REJECT);
                    }}
                    sx={{ marginTop: '24px', height: 50 }}
                    variant="outlined"
                    color="error"
                    fullWidth
                  >
                    REJECT
                  </Button>
                </>
              )}
            </WhiteBox>
          ) : null}
          {isFormUsersActionsVisible && (
            <>
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                  setActionType(UserAction.APPROVE_FORM);
                }}
                sx={{ marginTop: '24px', color: '#fff' }}
                variant="contained"
                color="success"
                fullWidth
              >
                YES
              </Button>
              <Button
                onClick={() => {
                  setIsModalOpen(true);
                  setActionType(UserAction.REJECT_FORM);
                }}
                sx={{ marginTop: '24px', height: 50 }}
                variant="outlined"
                color="error"
                fullWidth
              >
                NO
              </Button>
            </>
          )}
          <ConfirmationModal
            onSubmit={handleConfirm}
            okButtonText={actionType?.replace(/_/g, ' ') || ''}
            setIsModalOpen={() => setIsModalOpen(false)}
            onCancel={() => setActionType(null)}
            isModalOpen={isModalOpen}
            message="This action will trigger comms to the user, and cannot be reversed"
          />
          <WhiteBox>
            <Stack direction="row" alignItems="center">
              <Grid item xs={12}>
                <Stack
                  sx={{
                    display: 'flex',
                    justifyContent: 'space-between',
                    flexDirection: 'row',
                    alignItems: 'center',
                    marginBottom: '18px',
                  }}
                >
                  <Typography sx={{ display: 'inline' }} variant="h2">
                    Subscription info
                  </Typography>
                  <SubscriptionStatusBadge status={member.subscription?.status} />
                </Stack>
                {member.subscription ? (
                  <Stack>
                    <MemberInfoLine
                      label={'Product'}
                      value={member.subscription?.productName}
                    />
                    {member.subscription?.createdAt && (
                      <MemberInfoLine
                        label={'Created At'}
                        value={moment(member.subscription?.createdAt).format(
                          'DD/MM/YYYY'
                        )}
                      />
                    )}
                    {member.subscription?.startedAt && (
                      <MemberInfoLine
                        label={'Current Period Started At'}
                        value={moment(member.subscription?.startedAt).format(
                          'DD/MM/YYYY'
                        )}
                      />
                    )}
                    {member.subscription?.expiredAt && (
                      <MemberInfoLine
                        label={'Current Period Expires At'}
                        value={moment(member.subscription?.expiredAt).format(
                          'DD/MM/YYYY'
                        )}
                      />
                    )}
                    <MemberInfoLine
                      label={'Amount'}
                      value={
                        renderCurrency(member.subscription.currency) +
                        parseInt(member.subscription.amount, 10) / 100
                      }
                    />
                  </Stack>
                ) : (
                  <Typography variant="body2">No subscription info</Typography>
                )}
              </Grid>
            </Stack>
          </WhiteBox>
        </Grid>
      </Grid>
      <Grid container sx={{ marginBottom: '24px' }} spacing={2}>
        <Grid item xs={12}>
          <WhiteBox>
            <Stack flexDirection={'row'}>
              <Typography sx={{ marginBottom: '18px', marginRight: '16px' }} variant="h2">
                Member details
              </Typography>
              {!isEditMode ? (
                <Button
                  onClick={() => setIsEditMode(true)}
                  variant="contained"
                  size="small"
                >
                  Edit
                </Button>
              ) : (
                <Stack flexDirection={'row'}>
                  <Button
                    onClick={handleSaveChanges}
                    sx={{ marginRight: '16px' }}
                    variant="contained"
                    size="small"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setIsEditMode(false)}
                    variant="outlined"
                    size="small"
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Stack>
            <Grid marginBottom={'24px'} container spacing={2}>
              <Grid item xs={6}>
                {!isEditMode ? (
                  <>
                    {member.profile?.currentCity && (
                      <MemberInfoLine
                        label={'Current City'}
                        value={member.profile?.currentCity}
                      />
                    )}
                    {member.profile?.nextCity && (
                      <MemberInfoLine
                        label={'Next City'}
                        value={member.profile?.nextCity}
                      />
                    )}
                    {member.profile?.secondaryCities && (
                      <MemberInfoLine
                        label={'Secondary Cities'}
                        value={member.profile?.secondaryCities?.join(', ') || ''}
                      />
                    )}
                    {member?.profile?.region && (
                      <MemberInfoLine
                        label={'Region'}
                        value={member?.profile?.region || ''}
                      />
                    )}
                    {member?.profile?.countryOfResidence && (
                      <MemberInfoLine
                        label={'Country'}
                        value={member?.profile?.countryOfResidence || ''}
                      />
                    )}
                    {member.profile?.tags && (
                      <MemberInfoLine
                        label={'Tags'}
                        value={member.profile?.tags.map((t) => t.value)?.join(', ') || ''}
                      />
                    )}
                  </>
                ) : (
                  <>
                    <CustomAutocomplete
                      multiple
                      label="Cities"
                      sx={{ marginBottom: '18px', maxWidth: 400 }}
                      options={[...cities]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c) => ({
                          value: c.name,
                          label: c.name,
                        }))}
                      value={formik.values.cities}
                      onChange={(selected) => {
                        formik.setFieldValue('cities', selected);
                      }}
                    />
                    <CustomSelect
                      id={'region'}
                      name={'region'}
                      label={'Region'}
                      sx={{ marginBottom: '18px', maxWidth: 400 }}
                      options={Object.keys(Region).map((region) => ({
                        value: region,
                        label: region,
                      }))}
                      value={formik.values.region || ''}
                      onChange={formik.handleChange}
                      error={formik.touched.region && Boolean(formik.errors.region)}
                    />
                    <CustomAutocomplete
                      label="Country"
                      sx={{ marginBottom: '18px', maxWidth: 400 }}
                      options={[...countries]
                        .sort((a, b) => a.name.localeCompare(b.name))
                        .map((c) => ({
                          value: c.code,
                          label: c.name,
                        }))}
                      value={formik.values.country}
                      onChange={(selected) => {
                        formik.setFieldValue('country', selected);
                      }}
                    />
                  </>
                )}
                {member?.profile?.gender && (
                  <MemberInfoLine
                    label={'Pronouns'}
                    value={member?.profile?.gender || ''}
                  />
                )}

                <MemberInfoLine
                  label={'Braze'}
                  onValueClick={handleBrazeClick}
                  value={member.brazeId || ''}
                />
                <MemberInfoLine
                  onValueClick={handleStripeClick}
                  label={'Stripe'}
                  value={member.customerId || ''}
                />
                {(member.onboardingSettings?.otherText ||
                  member.onboardingSettings?.howYouKnowAboutUs) && (
                  <MemberInfoLine
                    label={'How you know about us'}
                    value={
                      member.onboardingSettings.otherText ||
                      member.onboardingSettings.howYouKnowAboutUs
                    }
                  />
                )}
              </Grid>
              <Grid item xs={6}>
                {member.profile?.industry && (
                  <MemberInfoLine
                    label={'Industry'}
                    value={member.profile?.industry || ''}
                  />
                )}
                {member.profile?.instagram && (
                  <MemberInfoLine
                    label={'Instagram'}
                    value={member.profile?.instagram || ''}
                  />
                )}
                {member.profile?.linkedin && (
                  <MemberInfoLine
                    label={'Linkedin'}
                    value={member.profile?.linkedin || ''}
                  />
                )}
                {member.referralCode && (
                  <MemberInfoLine label={'Referral'} value={member.referralCode || ''} />
                )}
              </Grid>
            </Grid>
            <Grid item xs={12}>
              {member.profile?.aboutMe && (
                <MemberInfoLine
                  label={'About me'}
                  value={member.profile?.aboutMe || ''}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {member.profile?.travelStory && (
                <MemberInfoLine
                  label={'Trevel story'}
                  value={member.profile?.travelStory || ''}
                />
              )}
            </Grid>
            <Grid item xs={12}>
              {member.profile?.perfectDay && (
                <MemberInfoLine
                  label={'Perfect day'}
                  value={member.profile?.perfectDay || ''}
                />
              )}
            </Grid>
          </WhiteBox>
          <WhiteBox sx={{ marginBottom: '16px' }}>
            <Typography sx={{ marginBottom: '16px' }} variant="h2">
              Links
            </Typography>
            <CopyClickboardInput
              sx={{ marginBottom: '12px', justifyContent: 'flex-start' }}
              inputSx={{ width: '350px' }}
              title="Direct to app"
              inputValue={`yayem://profile?id=${member._id}`}
            />
            <CopyClickboardInput
              sx={{
                marginBottom: '12px',
                justifyContent: 'flex-start',
              }}
              inputSx={{ width: '350px' }}
              title="Dynamic Link"
              inputValue={`${process.env.REACT_APP_API_SIMPLIFIED_URL}/api/d/p?id=${member._id}`}
            />
          </WhiteBox>
        </Grid>
      </Grid>
      <Grid sx={{ marginBottom: '24px' }} container spacing={2}>
        <Grid item xs={12}>
          <WhiteBox>
            <Stack direction={'row'} alignItems={'baseline'}>
              <Typography sx={{ marginBottom: '48px', marginRight: '16px' }} variant="h2">
                Suggested members
              </Typography>
              {!isAddSuggestionMode ? (
                <Button
                  variant="contained"
                  size="small"
                  onClick={() => setIsAddSuggestionMode(true)}
                >
                  {suggestedUsers?.length > 0 ? 'Update' : 'Add'}
                </Button>
              ) : (
                <Stack flexDirection={'row'}>
                  <Button
                    onClick={handleSubmitSuggestions}
                    sx={{ marginRight: '16px' }}
                    variant="contained"
                    size="small"
                  >
                    Save
                  </Button>
                  <Button
                    onClick={() => setIsAddSuggestionMode(false)}
                    variant="outlined"
                    size="small"
                  >
                    Cancel
                  </Button>
                </Stack>
              )}
            </Stack>
            <Grid container spacing={2}>
              {isAddSuggestionMode ? (
                <UsersAutocomplete
                  label="Find the member"
                  sx={{ marginBottom: '18px', width: '100%', maxWidth: 400 }}
                  selected={suggestedUsers}
                  onChange={(selected) => {
                    if (Array.isArray(selected)) {
                      setSuggestedUsers(selected);
                    }
                  }}
                />
              ) : memberSuggestions?.length > 0 ? (
                <MemberSuggestionsTable suggestions={memberSuggestions} />
              ) : (
                <Typography sx={{ marginLeft: '16px' }} variant="body2">
                  No suggestions
                </Typography>
              )}
            </Grid>
          </WhiteBox>
        </Grid>
      </Grid>
      <Grid sx={{ marginBottom: '24px' }} container spacing={2}>
        <Grid item xs={12}>
          <WhiteBox>
            <Stack
              direction={'row'}
              alignItems={'baseline'}
              justifyContent={'space-between'}
            >
              <Typography sx={{ marginBottom: '48px' }} variant="h2">
                Referrals
              </Typography>
              {referralsCount && (
                <Stack direction={'row'}>
                  <Stack direction={'row'} alignItems={'center'}>
                    <Typography variant="caption">Pending</Typography>
                    <Box className="referral-badge pending">
                      {referralsCount?.pending}
                    </Box>
                  </Stack>
                  <Stack
                    sx={{ marginLeft: '14px' }}
                    direction={'row'}
                    alignItems={'center'}
                  >
                    <Typography variant="caption">Successful</Typography>
                    <Box className="referral-badge success">{referralsCount?.active}</Box>
                  </Stack>
                </Stack>
              )}
            </Stack>
            <Grid container spacing={2}>
              {membersReferrals?.length > 0 ? (
                <MemberReferralsTable referrals={membersReferrals} />
              ) : (
                <Typography sx={{ marginLeft: '16px' }} variant="body2">
                  No referrals
                </Typography>
              )}
            </Grid>
          </WhiteBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WhiteBox>
            <Typography sx={{ marginBottom: '48px' }} variant="h2">
              Checkins
            </Typography>
            <Grid container spacing={2}>
              {memberCheckins?.length > 0 ? (
                <MemberCheckinsTable checkins={memberCheckins} />
              ) : (
                <Typography sx={{ marginLeft: '16px' }} variant="body2">
                  No checkins
                </Typography>
              )}
            </Grid>
          </WhiteBox>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <WhiteBox>
            <Typography sx={{ marginBottom: '48px' }} variant="h2">
              Matches
            </Typography>
            <Grid container spacing={2}>
              {memberMatches?.length > 0 ? (
                <MemberMatchesTable matches={memberMatches} myUserId={memberId || ''} />
              ) : (
                <Typography sx={{ marginLeft: '16px' }} variant="body2">
                  No Matches
                </Typography>
              )}
            </Grid>
          </WhiteBox>
        </Grid>
      </Grid>
    </Page>
  );
};

export default MemberProfile;
