import { AnnouncementDto } from 'models/announcement';

export type GetAnnouncementsResponse = {
  data: AnnouncementDto[];
  total: number;
};

export type GetAnnouncementsRequest = {
  query?: string;
  limit?: string;
  offset?: number;
  expirationDateFilter?: AnnouncementFilter;
  expirationDate?: string;
};

export type CreateAnnouncementsResponse = Partial<AnnouncementDto>;

export type CreateAnnouncementsRequest = {
  content: string;
  city?: string;
  expirationDate: string;
  isGlobal: boolean;
  url?: string;
};

export type EditAnnouncementRequest = {
  data: CreateAnnouncementsRequest;
  id: string;
};

export type DeleteAnnouncementsRequest = {
  id: string;
};

export enum AnnouncementFilter {
  EXPIRED = 'expired',
  ACTIVE = 'active',
};
