import { FC } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from '@mui/material/styles/styled';
import { CheckinDto } from 'models/checkin';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

interface Props {
  checkins: CheckinDto[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '8px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: '1px solid rgb(247, 247, 255)',
    padding: '8px 16px',
  },
}));

const MemberCheckinsTable: FC<Props> = ({ checkins }) => {
  const navigate = useNavigate();
  if (!checkins?.length) return null;

  return (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">Place name</StyledTableCell>
            <StyledTableCell align="left">Checkin date</StyledTableCell>
            <StyledTableCell align="left">Hosted by</StyledTableCell>
            <StyledTableCell align="left">Place Code</StyledTableCell>
            <StyledTableCell align="left">Event Only</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {checkins.map((checkin) => (
            <StyledTableRow key={checkin._id}>
              <StyledTableCell align="left">
                {checkin.place?.name || 'N/A'}
              </StyledTableCell>
              <StyledTableCell>
                {moment(checkin.checkinDate)
                  ?.tz(checkin?.place?.city?.timeZone)
                  ?.format('ddd DD MMM HH:mm')}
              </StyledTableCell>
              <StyledTableCell align="left">
                {checkin.invitedBy && (
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      navigate(`/membership/profile/${checkin.invitedBy?._id}`)
                    }
                  >
                    {checkin.invitedBy?.email}
                  </Typography>
                )}
              </StyledTableCell>
              <StyledTableCell align="left">{checkin.place?.code || ''}</StyledTableCell>
              <StyledTableCell align="left">
                {checkin.experienceOnly ? 'Yes' : 'No'}
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MemberCheckinsTable;
