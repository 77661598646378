import placesApi from 'api/places'
import { SignedUrlResponse } from 'types/places';
import { placesActions } from 'redux/reducers/places';
import { useAppDispatch } from 'app/hooks';
import { appActions } from 'redux/reducers/app';

export const useCreateSignedUrls = () => {
  const dispatch = useAppDispatch();
  const getSignedUrls = async (images: File[], defaultImageIndex = 0) => {
    try {
      // set loading state to "true"
      dispatch(placesActions.uploadSignedUrlImages());

      let getSignedS3UrlPromises: Promise<SignedUrlResponse>[] = [];

      images.forEach(file => {
        // get files types
        const fileType = file.type;
        const imageFormat = fileType.split('/')[1];
        // get signed urls from the server by file types
        getSignedS3UrlPromises.push(placesApi.getPlaceImageSignedUrl(imageFormat));
      });

      const getSignedS3UrlResults: SignedUrlResponse[] = await Promise.all(getSignedS3UrlPromises);

      // update loading state to "false" if signed urls created successfully
      dispatch(placesActions.uploadSignedUrlImagesSuccess());

      return { signedUrls: getSignedS3UrlResults }

    } catch (error) {
      dispatch(placesActions.uploadSignedUrlImagesError(error));
      dispatch(
        appActions.setToast({
          open: true,
          message: 'Failed to fetch signed urls, please try again later',
          severity: 'error',
        })
      );
    }
  }
  // This is necessary in order to use the hook in nested component functions.
  return { getSignedUrls };
}

export const useUploadImagesToS3Bucket = () => {
  const dispatch = useAppDispatch();
  const uploadImagesToS3Bucket = async (images: File[], signedUrls: SignedUrlResponse[]) => {
    try {
      // set loading state to "true"
      dispatch(placesActions.uploadSignedUrlImages());

      let uploadPhotoToS3BucketPromises: Promise<any>[] = [];
      // upload all images to S3 bucket via signed urls
      signedUrls.forEach(async (signedS3Url, index) => {
        uploadPhotoToS3BucketPromises.push(fetch(signedS3Url.url, {
          method: 'PUT',
          body: images[index],
        }));
      });
      // wait for upload all files
      await Promise.all(uploadPhotoToS3BucketPromises);

      // update loading state to "false" if images uploading success
      dispatch(placesActions.uploadSignedUrlImagesSuccess());

    } catch (error) {
      dispatch(placesActions.uploadSignedUrlImagesError(error));
      dispatch(
        appActions.setToast({
          open: true,
          message: 'Failed to upload images, please try again later',
          severity: 'error',
        })
      );
    }
  }
  // This is necessary in order to use the hook in nested component functions.
  return { uploadImagesToS3Bucket };
}