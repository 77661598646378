import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { PaymentsState } from 'redux/reducers/payments';

export const paymentsSelector = (state: RootState): PaymentsState => state.payments;

export const allPaymentsSelector = createSelector(
  paymentsSelector,
  (payments) => payments.payments
);

export const paymentsLoadingSelector = createSelector(
  paymentsSelector,
  (payments) => payments.loading
);

export const paymentsErrorSelector = createSelector(
  paymentsSelector,
  (payments) => payments.error
);

export const paymentsTotalSelector = createSelector(
  paymentsSelector,
  (payments) => payments.total
);
