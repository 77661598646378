import { Page, WhiteBox } from 'components';
import { FC } from 'react';
import MatchForm from '../matchForm';

const CreateNewMatch: FC = () => {
  return (
    <Page title="Create Match">
      <WhiteBox>
        <MatchForm matchId={undefined} />
      </WhiteBox>
    </Page>
  );
};

export default CreateNewMatch;
