import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Partner } from 'models/partner';
import { GetPartnersResponse, GetPartnersRequest } from 'types/partners';

export interface PartnersState {
  loading: boolean;
  partners: Partner[];
  error: any;
};

const initialState: PartnersState = {
  loading: false,
  partners: [],
  error: null,
};

const partnersSlice = createSlice({
  name: 'partners',
  initialState: initialState,
  reducers: {
    getPartners(state: PartnersState, action: PayloadAction<GetPartnersRequest>) {
      state.loading = true;
    },
    getPartnersSuccess(state: PartnersState, action: PayloadAction<GetPartnersResponse>) {
      state.loading = false;
      state.partners = action.payload.data;
    },
    getPartnersFailed(state: PartnersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const partnersActions = partnersSlice.actions;

// Reducer
const partnersReducer = partnersSlice.reducer;
export default partnersReducer;
