import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { citiesActions } from 'redux/reducers/cities';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { allEnabledCitiesSelector } from 'redux/selectors/cities';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CustomSelect, FileUploader, FormikInput, TagsSelector } from 'components';
import { convertFileToBlob } from 'utils/helpers/filesHelper';
import { isValidFile, isValidFileType } from 'constans/files';
import { hotspotByIdSelector } from 'redux/selectors/hotspots';
import { hotspotsActions } from 'redux/reducers/hotspots';
import { GuideCategoryEnum, Hotspot } from 'models/hotspot';
import { Document, Page, pdfjs } from 'react-pdf';
import 'react-pdf/dist/esm/Page/TextLayer.css';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import Typography from '@mui/material/Typography';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import UsersAutocomplete from 'components/common/UsersAutocomplete';
import CustomAutocomplete from 'components/common/CustomAutocomplete';
import { HOTSPOTS_CATEGORIES } from 'constans/hotspots';
import { AutoSelectOptionType } from 'types/common';
pdfjs.GlobalWorkerOptions.workerSrc = `//unpkg.com/pdfjs-dist@${pdfjs.version}/build/pdf.worker.min.js`;

const validationSchema = yup.object().shape({
  name: yup.string().required('Hotspot name is required'),
  imageUrl: yup.string().required('Hotspot image is required'),
  pdfUrl: yup.string().required('PDF image is required'),
  user: yup.object().notRequired(),
  cities: yup.string().when('isGlobal', {
    is: false,
    then: () => yup.array().min(1).required('City is required'),
    otherwise: () => yup.array().notRequired(),
  }),
  category: yup.string().notRequired(),
  description: yup.string().max(50).notRequired(),
});

interface HotspotFormType {
  name: string;
  cities: string[];
  imageUrl: string | null;
  pdfUrl: string | null;
  isGlobal: boolean;
  user: AutoSelectOptionType | null;
  description: string;
  category: GuideCategoryEnum;
}

type ComponentProps = {
  hotspotId: string | undefined;
};

const HotspotForm: React.FC<ComponentProps> = ({ hotspotId }) => {
  const dispatch = useAppDispatch();
  const cities = useAppSelector(allEnabledCitiesSelector);
  const editableHotspot = useAppSelector(hotspotByIdSelector);

  const [uploadedFile, setUploadedFile] = useState<File | null>(null);
  const [editImageUrl, setEditImageUrl] = useState<string | undefined>(undefined);

  const [uploadedPdf, setUploadedPdf] = useState<File | null>(null);
  const [editPdfUrl, setEditPdfUrl] = useState<string | undefined>(undefined);

  const [fileError, setFileError] = useState<string | null>(null);
  const [pdfError, setPdfError] = useState<string | null>(null);
  const [pdfBlobUrl, setPdfBlobUrl] = useState<string | null>(null); // to hold the preview blob URL
  const [pdfPagesNum, setPdfPagesNum] = useState(0);

  const isEditModeActive = !!hotspotId;

  useEffect(() => {
    if (hotspotId) {
      dispatch(hotspotsActions.getHotspotById(hotspotId));
    }

    return () => {
      resetFormHandler();
      dispatch(hotspotsActions.clearSelectedHotspot());
    };
  }, [hotspotId, dispatch]);

  const resetFormHandler = () => {
    formik.resetForm();
    setUploadedFile(null);
    setUploadedPdf(null);
    handleClearImageUrl();
    handleClearPdfUrl();
  };

  const handleSubmit = useCallback(
    async (values: HotspotFormType): Promise<void> => {
      if (!values.user) return;
      const requestData = {
        name: values.name,
        cities: values.cities,
        imageUrl: values.imageUrl || '',
        pdfUrl: values.pdfUrl || '',
        isGlobal: values.isGlobal,
        user: values.user?.value || undefined,
        category: values.category || undefined,
        description: values.description || undefined,
      };
      if (hotspotId) {
        dispatch(
          hotspotsActions.editHotspot({
            data: requestData,
            id: hotspotId,
          })
        );
      } else {
        dispatch(hotspotsActions.createHotspot(requestData));
      }
    },
    [dispatch]
  );

  const formik = useFormik<HotspotFormType>({
    initialValues: {
      cities: [] as string[],
      name: '',
      imageUrl: '',
      pdfUrl: '',
      isGlobal: false,
      user: {
        label: '',
        value: '',
      },
      category: '' as GuideCategoryEnum,
      description: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
  });

  const setFormValues = useCallback(
    (hotspot: Hotspot) => {
      const { setValues } = formik;
      setEditImageUrl(hotspot.imageUrl);
      setValues({
        name: hotspot.name,
        cities: hotspot?.cities.map((c) => c._id),
        imageUrl: hotspot.imageUrl,
        pdfUrl: hotspot.pdfUrl,
        isGlobal: hotspot.isGlobal,
        user: {
          label: hotspot.user?.email || '',
          value: hotspot.user?._id || '',
        },
        description: hotspot.description,
        category: hotspot.category || '',
      });
    },
    [formik]
  );

  const handleUploadImage = async (file: File | null) => {
    setFileError(null);

    if (file) {
      const isValidType = isValidFileType(file?.name, 'image');
      const isValidSize = file.size <= 20000000;

      if (!isValidType) {
        setFileError('Not a valid image type');
        return;
      }

      if (!isValidSize) {
        setFileError('The file size should be less then 20 Mb');
        return;
      }

      const blob = await convertFileToBlob(file);

      setUploadedFile(file);
      formik.setFieldValue('imageUrl', blob);
    } else {
      setUploadedFile(null);
      setEditImageUrl(editableHotspot?.imageUrl);
      formik.setFieldValue('imageUrl', editableHotspot?.imageUrl);
    }
  };

  const handleUploadPdf = async (file: File | null) => {
    setPdfError(null);

    if (file) {
      const isValidType = isValidFile(file?.name, 'pdf');
      const isValidSize = file.size <= 30000000;

      if (!isValidType) {
        setPdfError('Not a valid pdf file');
        return;
      }

      if (!isValidSize) {
        setPdfError('The file size should be less then 30 Mb');
        return;
      }

      const blob = await convertFileToBlob(file);

      setPdfBlobUrl(blob);
      setUploadedPdf(file);
      formik.setFieldValue('pdfUrl', blob);
    } else {
      setPdfBlobUrl(null);
      setUploadedPdf(null);
      setEditPdfUrl(editableHotspot?.pdfUrl);
      formik.setFieldValue('pdfUrl', editableHotspot?.pdfUrl);
    }
  };

  useEffect(() => {
    if (!cities.length) {
      dispatch(citiesActions.getEnabledCities());
    }
  }, [dispatch, cities]);

  const citiesOptions = useMemo(
    () =>
      cities.length
        ? cities.map((city) => ({
            value: city?._id,
            label: city?.name,
          }))
        : [],
    [cities]
  );

  useEffect(() => {
    if (hotspotId && editableHotspot) {
      setFormValues(editableHotspot);
      setEditPdfUrl(editableHotspot.pdfUrl);
    }
  }, [editableHotspot, hotspotId]);

  const handleClearImageUrl = () => {
    formik.setFieldValue('imageUrl', null);
    setEditImageUrl(undefined);
  };

  const handleClearPdfUrl = () => {
    formik.setFieldValue('pdfUrl', null);
    setEditPdfUrl(undefined);
  };

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setPdfPagesNum(numPages);
  }

  const handleIsGlobalChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      formik.setFieldValue('city', []);
    }

    formik.setFieldValue('isGlobal', checked);
  };

  return (
    <Stack component="form" onSubmit={formik.handleSubmit}>
      <Stack flexDirection="row">
        <Stack width="50%" className="new-place-form" marginRight={4}>
          <FormikInput name="name" label="Hotspot name" formik={formik} />
          <UsersAutocomplete
            label="Find the member"
            sx={{ width: '100%', maxWidth: 400 }}
            selected={formik.values.user ? formik.values.user : undefined}
            multiple={false}
            onChange={(selected) => {
              formik.setFieldValue('user', selected);
            }}
            error={formik.touched.user && Boolean(formik.errors.user)}
            errorText={(formik.touched.user && formik.errors.user) || ''}
          />
          <FormikInput
            formik={formik}
            name="description"
            label="Description"
            rowsCount={3}
            multiline
          />
          <FormControlLabel
            sx={{
              marginTop: '24px',
            }}
            control={
              <Checkbox
                name="isGlobal"
                color="info"
                onChange={handleIsGlobalChange}
                value={formik.values.isGlobal}
                checked={formik.values.isGlobal}
              />
            }
            label="Global"
          />
          <CustomSelect
            className="experience-item"
            name="category"
            label="Category"
            options={HOTSPOTS_CATEGORIES}
            value={formik.values.category || ''}
            onChange={formik.handleChange}
            error={formik.touched.category && Boolean(formik.errors.category)}
            helperText={formik.touched.category && formik.errors.category}
          />
          <CustomAutocomplete
            multiple
            label="Cities"
            disabled={formik.values.isGlobal}
            options={citiesOptions}
            value={formik.values.cities}
            onChange={(selected) => {
              formik.setFieldValue('cities', selected);
            }}
            error={formik.touched.cities && Boolean(formik.errors.cities)}
            errorText={(formik.touched.cities && (formik.errors.cities as string)) || ''}
          />
          {(pdfBlobUrl || editPdfUrl) && (
            <Stack
              flexDirection={'column'}
              sx={{
                width: '100%',
                maxHeight: '300px',
                overflow: 'hidden',
                borderRadius: '12px',
              }}
            >
              <Stack sx={{ overflowY: 'scroll' }}>
                <Document
                  file={pdfBlobUrl || editPdfUrl}
                  onLoadSuccess={onDocumentLoadSuccess}
                >
                  {Array.from({ length: pdfPagesNum }, (_, index) => (
                    <Page key={index + 1} pageNumber={index + 1} />
                  ))}
                </Document>
              </Stack>
            </Stack>
          )}
          <FileUploader
            label="UPLOAD HOTSPOT PDF"
            onChange={(file) => handleUploadPdf(file)}
            file={uploadedPdf}
            selectedFile={editPdfUrl}
            clearFileName={handleClearPdfUrl}
            hasError={
              (formik.touched.pdfUrl && Boolean(formik.errors.pdfUrl)) ||
              Boolean(pdfError)
            }
            errorText={pdfError || formik.errors.pdfUrl}
          />
        </Stack>
        <Stack width="50%" className="new-place-form">
          <Typography sx={{ color: '#1E1E70', opacity: 0.5 }} variant="caption">
            Upload a high-quality square image that represents this list. It should be
            visually appealing, without any text or logos. Consider finding one of the Y
            List places on Instagram and pulling an image from there
          </Typography>
          <FileUploader
            label="UPLOAD IMAGE"
            onChange={(file) => handleUploadImage(file)}
            file={uploadedFile}
            selectedFile={editImageUrl}
            clearFileName={handleClearImageUrl}
            hasError={
              (formik.touched.imageUrl && Boolean(formik.errors.imageUrl)) ||
              Boolean(fileError)
            }
            errorText={fileError || formik.errors.imageUrl}
            previewVisible
          />
          <Button variant="contained" color="primary" fullWidth type="submit">
            {isEditModeActive ? 'SAVE' : 'SUBMIT'}
          </Button>
        </Stack>
      </Stack>
    </Stack>
  );
};

export default HotspotForm;
