import { CheckinDto } from 'models/checkin';
import { Match } from 'models/match';
import {
  CommunityManagmentFilters,
  Member,
  MemberProfile,
  Region,
  Status,
  SuggestedMemberDto,
} from 'models/member';
import { ReferralDto } from 'models/referral';

export type GetUsersResponse = {
  data: Member[];
  total: number;
};
export type GetCitiesCount = {
  city: string;
  location: number[];
  _id: string;
  count: number;
};

export type GetUserByIdRequest = {
  id: string;
};

export enum UpdateUserStatus {
  APPROVE = 'approve',
  APPROVE_FORM = 'approve_form',
  REJECT = 'reject',
  REJECT_FORM = 'reject_form',
  WAITLIST = 'waitlist',
}

export type UpdateUserStatusRequest = {
  id: string;
  status: UpdateUserStatus;
  expediteCode?: string | null;
  promoCode?: string | null;
};

export type GetUsersRequest = {
  query?: string;
  status?: Status | '';
  region?: Region | '';
  cmFilter?: CommunityManagmentFilters | '';
  limit?: string;
  offset?: number;
  sort?: string | null;
};

export type UpdateUserProfileRequest = {
  id: string;
  profile: Partial<MemberProfile>;
};

export type GetUserByIdResponse = Member;
export type GetUserReferralsResponse = ReferralDto[];
export type GetUserCheckinsResponse = CheckinDto[];
export type GetUserSuggestedMembersResponse = SuggestedMemberDto[];
export type GetUserMatchesResponse = Match[];

export type SetUserPromoRequest = {
  id: string;
  expediteCode: string | null;
  promoCode: string | null;
};

export type SetUserSuggestionsRequest = {
  id: string;
  suggestedMembers: string[];
};

export type MembershipSortBy =
  | 'email'
  | 'profile.firstName'
  | 'subscription.productName'
  | 'profile.birthday'
  | 'subscription.createdAt'
  | 'successfulReferrals'
  | 'experienceBookingsCountLast3Month'
  | 'checkinsCountLast3Month'
  | 'profile.countryOfResidence'
  | 'createdAt'
  | 'status'
  | 'profile.region';
