import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { CustomDatePicker, CustomSelect, FormikInput, SearchSelect } from 'components';
import { announcementsActions } from 'redux/reducers/announcements';
import { AnnouncementDto } from 'models/announcement';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { allCitiesSelector } from 'redux/selectors/cities';
import { citiesActions } from 'redux/reducers/cities';
import { CreateAnnouncementsRequest } from 'types/announcements';
import moment from 'moment';
import { DateType } from 'types/experiences';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

const validationSchema = yup.object().shape({
  content: yup.string().required('Content required'),
  expirationDate: yup.string().required('Expiration date required'),
  id: yup.string(),
  city: yup.string().when('isGlobal', {
    is: false,
    then: () => yup.string().required('City required'),
  }),
});

type NewAnnouncementForm = {
  content: string;
  expirationDate: string;
  city: string;
  id: string;
  isGlobal: boolean;
  url: string;
};

type ComponentProps = {
  editableAnnouncement: AnnouncementDto | null;
  handleEditAnnouncement: (product: AnnouncementDto | null) => void;
};

const NewAnnouncementForm: React.FC<ComponentProps> = ({
  editableAnnouncement,
  handleEditAnnouncement,
}) => {
  const dispatch = useAppDispatch();
  const [expirationDateError, setExpirationDateError] = useState<string | null>(null);
  const isEditModeActive = useMemo(
    () => Boolean(editableAnnouncement),
    [editableAnnouncement]
  );
  const cities = useAppSelector(allCitiesSelector);

  useEffect(() => {
    if (!cities.length) {
      dispatch(citiesActions.getAllCities());
    }
  }, [cities]);

  const validateExpirationDate = (date: string) => {
    if (!date || moment(date).isBefore(moment())) {
      return { isValid: false, message: 'Invalid date' };
    }

    return { isValid: true, message: '' };
  };

  const handleSubmit = useCallback(
    async (values: NewAnnouncementForm): Promise<void> => {
      if (expirationDateError) setExpirationDateError(null);
      const dateValidation = validateExpirationDate(values.expirationDate);

      if (!dateValidation?.isValid) {
        setExpirationDateError(dateValidation.message);
        return;
      }

      const requestData: CreateAnnouncementsRequest = {
        content: values.content,
        city: !!values.city ? values.city : undefined,
        expirationDate: values.expirationDate,
        isGlobal: values.isGlobal,
        url: !!values.url ? values.url : undefined,
      };

      if (values.id) {
        await dispatch(
          announcementsActions.editAnnouncement({
            data: requestData,
            id: values.id,
          })
        );
        resetFormHandler();
      } else {
        await dispatch(announcementsActions.createAnnouncement(requestData));
        formik.resetForm();
      }
    },
    [dispatch]
  );

  const citiesOptions = useMemo(() => {
    const options =
      cities?.length > 0
        ? cities.map((c) => ({
            value: c?._id,
            label: c?.name,
          }))
        : [{ value: '', label: '' }];
    return [...options.sort((a, b) => a.label.localeCompare(b.label))];
  }, [cities]);

  const formik = useFormik({
    initialValues: {
      content: '',
      expirationDate: '',
      city: '',
      id: '',
      isGlobal: false,
      url: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
  });

  const setFormValues = (announcement: AnnouncementDto) => {
    const { setValues } = formik;
    setValues({
      content: announcement.content,
      expirationDate: announcement.expirationDate,
      city: announcement?.city?._id || '',
      id: announcement._id,
      isGlobal: announcement?.isGlobal,
      url: announcement?.url || '',
    });
  };

  useEffect(() => {
    if (editableAnnouncement) {
      setFormValues(editableAnnouncement);
    }
  }, [editableAnnouncement]);

  const resetFormHandler = () => {
    handleEditAnnouncement(null);
    setExpirationDateError(null);
    formik.resetForm();
  };

  const handleDateChange = (date: DateType) => {
    if (expirationDateError) setExpirationDateError(null);
    const expDate = moment(date).utc(true).toDate();
    formik.setFieldValue('expirationDate', expDate);
  };

  const handleIsGlobalChange = (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean
  ) => {
    if (checked) {
      formik.setFieldValue('city', '');
    }

    formik.setFieldValue('isGlobal', checked);
  };

  return (
    <Stack component="form" onSubmit={formik.handleSubmit} className="new-place-form">
      <Typography
        sx={{ fontWeight: '600' }}
        className="form-title"
        variant="body1"
        component="p"
      >
        {isEditModeActive ? 'Edit Announcement' : 'New Announcement'}
      </Typography>
      <FormikInput
        formik={formik}
        name="content"
        label="Content"
        rowsCount={3}
        multiline
      />
      <FormikInput
        formik={formik}
        name="url"
        label="Announcement url"
      />
      <Stack>
        <FormControlLabel
          sx={{
            marginBottom: '24px',
          }}
          control={
            <Checkbox
              name="isGlobal"
              color="info"
              onChange={handleIsGlobalChange}
              value={formik.values.isGlobal}
              checked={formik.values.isGlobal}
            />
          }
          label="Global"
        />
        <SearchSelect
          name="city"
          label="City"
          disabled={formik.values.isGlobal}
          options={citiesOptions}
          value={formik.values.city}
          onChange={(value: string) => formik.setFieldValue('city', value)}
          error={formik.touched.city && Boolean(formik.errors.city)}
          helperText={formik.touched.city && formik.errors.city}
          sx={{
            marginBottom: '24px',
          }}
        />
        <CustomDatePicker
          label="Expiration Date"
          value={moment.utc(formik.values.expirationDate)}
          onChange={(date) => handleDateChange(date)}
          disablePast
          minDate={moment()}
        />
        {expirationDateError && (
          <Typography sx={{ margin: '0 14px 14px 14px' }} color="error" variant="caption">
            {expirationDateError}
          </Typography>
        )}
      </Stack>
      <Stack marginBottom={3}>
        {isEditModeActive ? (
          <Stack>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={resetFormHandler}
            >
              CANCEL
            </Button>
          </Stack>
        ) : null}
      </Stack>
      <Button variant="contained" color="primary" fullWidth type="submit">
        {isEditModeActive ? 'SAVE' : 'SUBMIT'}
      </Button>
    </Stack>
  );
};

export default NewAnnouncementForm;
