import { FC } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import { StatusBadge } from 'components';
import { ReferralDto } from 'models/referral';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from '@mui/material/styles/styled';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';

interface Props {
  referrals: ReferralDto[];
};

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '8px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: '1px solid rgb(247, 247, 255)',
    padding: '8px 16px',
  },
}));

const MemberReferralsTable: FC<Props> = ({ referrals }) => {
  const navigate = useNavigate();
  if (!referrals?.length) return null;

  return (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Successful Referrals</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {referrals.map((ref) => (
            <StyledTableRow key={ref.email}>
              <StyledTableCell align="left">
                <Typography
                    variant='body2'
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() =>
                      navigate(`/membership/profile/${ref._id}`)
                    }
                  >
                    {ref.email || ''}
                  </Typography>
              </StyledTableCell>
              <StyledTableCell>
                {ref.profile?.firstName || ''} {ref.profile?.lastName || ''}
              </StyledTableCell>
              <StyledTableCell align="left">
                {ref.successfulReferrals || '0'}
              </StyledTableCell>
              <StyledTableCell align="left">
                <StatusBadge
                  className="member-table-status"
                  status={ref.status}
                />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MemberReferralsTable;
