import React, { useEffect, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { SearchInput } from 'components';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { allCitiesSelector } from 'redux/selectors/cities';
import { citiesActions } from 'redux/reducers/cities';
import CustomAutocomplete from 'components/common/CustomAutocomplete';
import { isArray } from 'lodash';

type Props = {
  onSearchChange: (value: string) => void;
  onCityChange: (value: string) => void;
  searchValue: string;
  selectedCityOption: string;
};

const HotspotsFilters: React.FC<Props> = ({
  onSearchChange,
  onCityChange,
  searchValue,
  selectedCityOption,
}) => {
  const cities = useAppSelector(allCitiesSelector);
  const dispatch = useAppDispatch();

  useEffect(() => {
    if (!cities.length) {
      dispatch(citiesActions.getAllCities());
    }
  }, [dispatch, cities]);

  const citiesOptions = useMemo(() => {
    const options = cities.length
      ? cities.map((city) => ({ label: city.name, value: city._id }))
      : [];
    return [{ label: 'All', value: 'all' }, { label: 'Global', value: 'global' }, ...options.sort((a, b) => a.label.localeCompare(b.label))];
  }, [cities]);
  
  return (
    <Stack width="90%" flexDirection="row" alignItems="center" justifyContent="flex-end">
      <Stack width="30%" maxWidth={300}>
          <CustomAutocomplete
            label="City"
            options={citiesOptions}
            value={selectedCityOption}
            onChange={(selected) => {
              if (!isArray(selected)) onCityChange(selected);
            }}
          />
      </Stack>
      <Stack width="30%" maxWidth={300} sx={{ marginLeft: '18px' }}>
        <SearchInput
          className="filters-item"
          name="search"
          onChange={(v) => onSearchChange(v)}
          value={searchValue}
        />
      </Stack>
    </Stack>
  );
};

export default HotspotsFilters;
