import { PayloadAction } from '@reduxjs/toolkit';
import tagsApi from 'api/tags';
import { RootState } from 'app/store';
import axios from 'axios';
import { call, put, select, takeLatest } from 'redux-saga/effects';
import { appActions } from 'redux/reducers/app';
import { tagsActions } from 'redux/reducers/tags';
import {
  CreateTagsRequest,
  CreateTagsResponse,
  DeleteTagRequest,
  EditTagRequest,
  GetTagsRequest,
  GetTagsResponse,
} from 'types/tag';

function* getTags(data: PayloadAction<GetTagsRequest>) {
  try {
    const response: GetTagsResponse = yield call(tagsApi.getTags, data.payload);
    yield put(tagsActions.getTagsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch tags`, error);
    if (axios.isAxiosError(error)) {
      yield put(tagsActions.getTagsFailed(error.response?.data.message));
    }
  }
}

function* updateTagsList() {
  try {
    const { searchValue, categoryFilter } = yield select(
      (state: RootState) => state.tags
    );

    const updatedTags: GetTagsResponse = yield call(tagsApi.getTags, {
      query: searchValue,
      category: categoryFilter,
    });
    yield put(tagsActions.getTagsSuccess(updatedTags));
  } catch (error) {
    console.log(`Failed to update announcements list`, error);
  }
}

function* createTag(data: PayloadAction<CreateTagsRequest>) {
  try {
    const response: CreateTagsResponse = yield call(tagsApi.createTag, data.payload);
    yield put(tagsActions.createTagSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'The tag was successfuly created!',
        severity: 'success',
      })
    );
    yield call(updateTagsList);
  } catch (error) {
    console.log(`Failed to create tag`, error);
    if (axios.isAxiosError(error)) {
      yield put(tagsActions.createTagFailed(error.response?.data.message));
    }
  }
}

function* editTag(data: PayloadAction<EditTagRequest>) {
  try {
    const response: CreateTagsResponse = yield call(tagsApi.editTag, data.payload);
    yield put(tagsActions.editTagSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'The tag was successfuly updated!',
        severity: 'success',
      })
    );
    yield call(updateTagsList);
  } catch (error) {
    console.log(`Failed to edit tag`, error);
    if (axios.isAxiosError(error)) {
      yield put(tagsActions.editTagFailed(error.response?.data.message));
    }
  }
}

function* deleteTag(data: PayloadAction<DeleteTagRequest>) {
  try {
    const response: CreateTagsResponse = yield call(tagsApi.deleteTag, data.payload);
    yield put(tagsActions.deleteTagSuccess(response));
    yield call(updateTagsList);
  } catch (error) {
    console.log(`Failed to delete tag`, error);
    if (axios.isAxiosError(error)) {
      yield put(tagsActions.deleteTagFailed(error.response?.data.message));
    }
  }
}

export default function* tagsSaga() {
  yield takeLatest(tagsActions.getTags.type, getTags);
  yield takeLatest(tagsActions.createTag.type, createTag);
  yield takeLatest(tagsActions.editTag.type, editTag);
  yield takeLatest(tagsActions.deleteTag.type, deleteTag);
}
