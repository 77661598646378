import { PayloadAction } from '@reduxjs/toolkit';
import hotspotsApi from 'api/hotspots';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { hotspotsActions } from 'redux/reducers/hotspots';
import {
  CreateHotspotRequest,
  HotspotsRequest,
  HotspotsResponse,
  CreateHotspotResponse,
  GetHotspotByIdResponse,
  EditHotspotRequest,
  EditHotspotResponse,
  DeleteHotspotResponse,
} from 'types/hotspots';
import { appActions } from 'redux/reducers/app';
import history from 'utils/route-history';

function* getHotspots(data: PayloadAction<HotspotsRequest>) {
  try {
    const response: HotspotsResponse = yield call(hotspotsApi.getHotspots, data.payload);
    yield put(hotspotsActions.getHotspotsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch hotspots`, error);
    if (axios.isAxiosError(error)) {
      yield put(hotspotsActions.getHotspotsFailed(error.response?.data.message));
    }
  }
}

function* createHotspot(data: PayloadAction<CreateHotspotRequest>) {
  try {
    const response: CreateHotspotResponse = yield call(
      hotspotsApi.createHotspot,
      data.payload
    );
    yield put(hotspotsActions.createHotspotSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'Hotspot was successfully created!',
        severity: 'success',
      })
    );

    history.push('/hotspots/all-hotspots');
  } catch (error) {
    console.log(`Failed to create hotspot`, error);
    if (axios.isAxiosError(error)) {
      yield put(hotspotsActions.createHotspotFailed(error.response?.data.message));
      yield put(
        appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        })
      );
    }
  }
}

function* getHotspotById(data: PayloadAction<string>) {
  try {
    const response: GetHotspotByIdResponse = yield call(
      hotspotsApi.getHotspotById,
      data.payload
    );
    yield put(hotspotsActions.getHotspotByIdSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch hotspot by id`, error);
    if (axios.isAxiosError(error)) {
      yield put(hotspotsActions.getHotspotByIdFailed(error.response?.data.message));
    }
  }
}

function* editHotspot(data: PayloadAction<EditHotspotRequest>) {
    try {
      const response: EditHotspotResponse = yield call(hotspotsApi.editHotspot, data.payload);
      yield put(hotspotsActions.editHotspotSuccess(response));
      yield put(appActions.setToast({
        open: true,
        message: 'Hotspot successfully edited!',
        severity: 'success',
      }));
      history.push(`/hotspots/details/${response._id}`);
    } catch (error) {
      console.log(`Failed to edit hotspot`, error);
      if (axios.isAxiosError(error)) {
        yield put(hotspotsActions.editHotspotFailed(error.response?.data.message));
        yield put(appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        }));
      }
    }
  }

  function* deleteHotspot(data: PayloadAction<string>) {
    try {
      const response: DeleteHotspotResponse = yield call(hotspotsApi.deleteHotspot, data.payload);
      yield put(hotspotsActions.deleteHotspotSuccess(response));
      yield put(appActions.setToast({
        open: true,
        message: 'Hotpsot successfully deleted!',
        severity: 'success',
      }));
      history.push('/hotspots/all-hotspots');
    } catch (error) {
      console.log(`Failed to delete hotspot`, error);
      if (axios.isAxiosError(error)) {
        yield put(hotspotsActions.deleteHotspotFailed(error.response?.data.message));
        yield put(appActions.setToast({
          open: true,
          message: error?.response?.data.message,
          severity: 'error',
        }));
      }
    }
  }

export default function* hotspotsSaga() {
  yield takeLatest(hotspotsActions.getHotspots.type, getHotspots);
  yield takeLatest(hotspotsActions.createHotspot.type, createHotspot);
  yield takeLatest(hotspotsActions.getHotspotById.type, getHotspotById);
  yield takeLatest(hotspotsActions.editHotspot.type, editHotspot);
  yield takeLatest(hotspotsActions.deleteHotspot.type, deleteHotspot);
}
