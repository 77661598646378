import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Checkin, Reservation } from 'models/checkin';
import {
  CreateCheckInRequest,
  GetCheckInsRequest,
  GetCheckInsResponse,
  GetReservationsResponse,
  UpdateCheckInRequest,
} from 'types/check-in';

export interface CheckInState {
  loading: boolean;
  checkIns: Checkin[];
  reservations: Reservation[];
  error: any;
  total: number | null;
  offset: number;
}

const initialState: CheckInState = {
  loading: false,
  checkIns: [],
  reservations: [],
  error: null,
  total: null,
  offset: 0,
};

const checkinSlice = createSlice({
  name: 'checkin',
  initialState: initialState,
  reducers: {
    getCheckIns(state: CheckInState, action: PayloadAction<GetCheckInsRequest>) {
      state.loading = true;
    },
    getCheckInsSuccess(state: CheckInState, action: PayloadAction<GetCheckInsResponse>) {
      state.loading = false;
      state.total = action.payload.total;
      if (state.offset > 0) {
        state.checkIns = [...state.checkIns, ...action.payload.data];
      } else {
        state.checkIns = action.payload.data;
      }
    },
    getCheckInsFailed(state: CheckInState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    getReservations(state: CheckInState, action: PayloadAction<GetCheckInsRequest>) {
      state.loading = true;
    },
    getReservationsSuccess(
      state: CheckInState,
      action: PayloadAction<GetReservationsResponse>
    ) {
      state.loading = false;
      state.total = action.payload.total;
      if (state.offset > 0) {
        state.reservations = [...state.reservations, ...action.payload.data];
      } else {
        state.reservations = action.payload.data;
      }
    },
    getReservationsFailed(state: CheckInState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },

    createCheckIn(state: CheckInState, action: PayloadAction<CreateCheckInRequest>) {
      state.loading = true;
    },
    createCheckInSuccess(state: CheckInState) {
      state.loading = false;
    },
    createCheckInFailed(state: CheckInState, action: PayloadAction<any>) {
      state.loading = true;
      state.error = action.payload;
    },
    editCheckIn(state: CheckInState, action: PayloadAction<UpdateCheckInRequest>) {
      state.loading = true;
      state.checkIns = state.checkIns.map((checkin) =>
        checkin._id === action.payload.id
          ? { ...checkin, ...action.payload.data }
          : checkin
      );
    },
    editCheckInSuccess(state: CheckInState) {
      state.loading = false;
    },
    editCheckInFailed(state: CheckInState) {
      state.loading = true;
    },
    updateOffset(state: CheckInState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const checkinActions = checkinSlice.actions;

// Reducer
const checkinReducer = checkinSlice.reducer;
export default checkinReducer;
