import { TagDto } from 'models/tag';

export type GetTagsResponse = {
  data: TagDto[];
  total: number;
};

export type GetTagsRequest = {
  query?: string;
  limit?: string;
  offset?: number;
  category?: TagCategoryEnum;
};

export type CreateTagsResponse = Partial<TagDto>;

export type CreateTagsRequest = {
  value: string;
  key: string;
  category: TagCategoryEnum;
};

export type EditTagRequest = {
  data: CreateTagsRequest;
  id: string;
};

export type DeleteTagRequest = {
  id: string;
};

export enum TagCategoryEnum {
  COMMUNITY = 'community',
  INDUSTRY = 'industry',
  INTEREST = 'interest',
  LANGUAGE = 'language',
  PROFESSIONAL = 'professional',
  TRAVEL_GROUP = 'travel_group',
}
