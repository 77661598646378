import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { TagDto } from 'models/tag';
import {
  CreateTagsRequest,
  CreateTagsResponse,
  DeleteTagRequest,
  EditTagRequest,
  GetTagsRequest,
  GetTagsResponse,
  TagCategoryEnum,
} from 'types/tag';

export interface TagsState {
  loading: boolean;
  tags: TagDto[];
  error: any;
  total: number | null;
  offset: number;
  categoryFilter: TagCategoryEnum;
  searchValue: string;
}

const initialState: TagsState = {
  loading: false,
  tags: [],
  error: null,
  total: null,
  offset: 0,
  categoryFilter: TagCategoryEnum.COMMUNITY,
  searchValue: '',
};

const tagsSlice = createSlice({
  name: 'tags',
  initialState: initialState,
  reducers: {
    getTags(state: TagsState, action: PayloadAction<GetTagsRequest>) {
      state.loading = true;
    },
    getTagsSuccess(state: TagsState, action: PayloadAction<GetTagsResponse>) {
      state.loading = false;
      state.total = action.payload.total;
      if (state.offset > 0) {
        state.tags = [...state.tags, ...action.payload.data];
      } else {
        state.tags = action.payload.data;
      }
    },
    setCategoryFilter(state: TagsState, action: PayloadAction<TagCategoryEnum>) {
      state.categoryFilter = action.payload;
    },
    setSearchValue(state: TagsState, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    getTagsFailed(state: TagsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    createTag(state: TagsState, action: PayloadAction<CreateTagsRequest>) {
      state.loading = true;
    },
    createTagSuccess(state: TagsState, action: PayloadAction<CreateTagsResponse>) {
      state.loading = false;
    },
    createTagFailed(state: TagsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    editTag(state: TagsState, action: PayloadAction<EditTagRequest>) {
      state.loading = true;
    },
    editTagSuccess(state: TagsState, action: PayloadAction<CreateTagsResponse>) {
      state.loading = false;
    },
    editTagFailed(state: TagsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteTag(state: TagsState, action: PayloadAction<DeleteTagRequest>) {
      state.loading = true;
    },
    deleteTagSuccess(state: TagsState, action: PayloadAction<any>) {
      state.loading = false;
    },
    deleteTagFailed(state: TagsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOffset(state: TagsState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const tagsActions = tagsSlice.actions;

// Reducer
const tagsReducer = tagsSlice.reducer;
export default tagsReducer;
