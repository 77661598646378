import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Hotspot } from 'models/hotspot';
import {
  CreateHotspotRequest,
  DeleteHotspotResponse,
  EditHotspotRequest,
  HotspotsRequest,
  HotspotsResponse,
} from 'types/hotspots';

export interface HotspotState {
  loading: boolean;
  hotspots: Hotspot[];
  selectedHotspot: Hotspot | null;
  error: any;
  offset: number;
  total: number;
}

const initialState: HotspotState = {
  loading: false,
  error: null,
  hotspots: [],
  selectedHotspot: null,
  offset: 0,
  total: 0,
};

const hotspotSlice = createSlice({
  name: 'hotspots',
  initialState: initialState,
  reducers: {
    getHotspots(state: HotspotState, action: PayloadAction<HotspotsRequest>) {
      state.loading = true;
    },
    getHotspotsSuccess(state: HotspotState, action: PayloadAction<HotspotsResponse>) {
      if (state.offset > 0) {
        state.hotspots = [...state.hotspots, ...action.payload.data];
      } else {
        state.hotspots = action.payload.data;
      }
      state.total = action.payload.total;
      state.loading = false;
    },
    getHotspotsFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOffset(state: HotspotState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    createHotspot(state: HotspotState, action: PayloadAction<CreateHotspotRequest>) {
      state.loading = true;
    },
    createHotspotSuccess(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
    },
    createHotspotFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    editHotspot(state: HotspotState, action: PayloadAction<EditHotspotRequest>) {
      state.loading = true;
    },
    editHotspotSuccess(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
    },
    editHotspotFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getHotspotById(state: HotspotState, action: PayloadAction<string>) {
      state.loading = true;
    },
    getHotspotByIdSuccess(state: HotspotState, action: PayloadAction<Hotspot>) {
      state.loading = false;
      state.selectedHotspot = action.payload;
    },
    getHotspotByIdFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteHotspot(state: HotspotState, action: PayloadAction<string>) {
      state.loading = true;
    },
    deleteHotspotSuccess(state: HotspotState, action: PayloadAction<DeleteHotspotResponse>) {
      state.loading = false;
    },
    deleteHotspotFailed(state: HotspotState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    clearSelectedHotspot: (state: HotspotState) => {
      state.selectedHotspot = null;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const hotspotsActions = hotspotSlice.actions;

// Reducer
const hotspotsReducer = hotspotSlice.reducer;
export default hotspotsReducer;
