import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExperienceByTimeSlotIdDto, ExperienceDto, ExperienceDetailedType } from 'models/experiences';
import { CreateExperienceRequest, DeleteExperienceRequest, GetExperienceByIdRequest, GetExperienceByIdResponse, GetExperienceByTimeSlotIdRequest, GetExperienceByTimeSlotIdResponse, GetExperiencesRequest, GetExperiencesResponse, UpdateExperienceRequest, UpdateExperienceResponse } from 'types/experiences';

export interface ExperienceState {
  loading: boolean;
  upcoming: ExperienceDto[];
  historyExperiences: ExperienceDto[];
  error: any;
  selectedExperience: ExperienceDetailedType | null;
  upcomingOffset: number;
  historyOffset: number;
  totalHistory: number | null;
  totalUpcoming: number | null;
  selectedExperienceTimeSlotData: ExperienceByTimeSlotIdDto | null;
  selectedExperienceTimeSlotsData: ExperienceByTimeSlotIdDto[];
}

const initialState: ExperienceState = {
  loading: false,
  upcoming: [],
  historyExperiences: [],
  totalUpcoming: null,
  totalHistory: null,
  upcomingOffset: 0,
  historyOffset: 0,
  error: null,
  selectedExperience: null,
  selectedExperienceTimeSlotData: null,
  selectedExperienceTimeSlotsData: [],
};

const experiencesSlice = createSlice({
  name: 'experiences',
  initialState: initialState,
  reducers: {
    getUpcomingExperiences(state: ExperienceState, action: PayloadAction<GetExperiencesRequest>) {
      state.loading = true;
    },
    getUpcomingExperiencesSuccess(state: ExperienceState, action: PayloadAction<GetExperiencesResponse>) {
      state.loading = false;
      if (state.upcomingOffset > 0) {
        state.upcoming = [...state.upcoming, ...action.payload.data];
      } else {
        state.upcoming = action.payload.data;
      }
      state.totalUpcoming = action.payload.total;
    },
    getUpcomingExperiencesFailed(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getHistoryExperiences(state: ExperienceState, action: PayloadAction<GetExperiencesRequest>) {
      state.loading = true;
    },
    getHistoryExperiencesSuccess(state: ExperienceState, action: PayloadAction<GetExperiencesResponse>) {
      state.loading = false;
      if (state.historyOffset > 0) {
        state.historyExperiences = [...state.historyExperiences, ...action.payload.data];
      } else {
        state.historyExperiences = action.payload.data;
      }
      state.totalHistory = action.payload.total;
    },
    getHistoryExperiencesFailed(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getExperienceById(state: ExperienceState, action: PayloadAction<GetExperienceByIdRequest>) {
      state.loading = true;
    },
    getExperienceByIdSuccess(state: ExperienceState, action: PayloadAction<GetExperienceByIdResponse>) {
      state.loading = false;
      state.selectedExperience = action.payload;
    },
    getExperienceByIdFailed(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getExperienceByTimeSlotId(state: ExperienceState, action: PayloadAction<GetExperienceByTimeSlotIdRequest>) {
      state.loading = true;
    },
    getExperienceByTimeSlotIdSuccess(state: ExperienceState, action: PayloadAction<GetExperienceByTimeSlotIdResponse>) {
      state.loading = false;
      state.selectedExperienceTimeSlotData = action.payload;
    },
    getExperienceByTimeSlotIdFailed(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateUpcomingOffset(state: ExperienceState, action: PayloadAction<{ offset: number }>) {
      state.upcomingOffset = action.payload.offset;
    },
    updateHistoryOffset(state: ExperienceState, action: PayloadAction<{ offset: number }>) {
      state.historyOffset = action.payload.offset;
    },
    clearSelectedExperience: (state: ExperienceState) => {
      state.selectedExperience = null;
    },
    clearSelectedExperienceTimeSlotData: (state: ExperienceState) => {
      state.selectedExperienceTimeSlotData = null;
    },
    createExperience(state: ExperienceState, action: PayloadAction<CreateExperienceRequest>) {
      state.loading = true;
    },
    createExperienceSuccess(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
    },
    createExperienceError(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateExperience(state: ExperienceState, action: PayloadAction<UpdateExperienceRequest>) {
      state.loading = true;
    },
    updateExperienceSuccess(state: ExperienceState, action: PayloadAction<UpdateExperienceResponse>) {
      state.loading = false;
    },
    updateExperienceError(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteExperience(state: ExperienceState, action: PayloadAction<DeleteExperienceRequest>) {
      state.loading = true;
    },
    deleteExperienceSuccess(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
    },
    deleteExperienceError(state: ExperienceState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getCsv(state: ExperienceState, action: PayloadAction<any>) {
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const experiencesActions = experiencesSlice.actions;

// Reducer
const experiencesReducer = experiencesSlice.reducer;
export default experiencesReducer;
