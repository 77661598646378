import React from 'react';
import styled from '@emotion/styled';
import Avatar, { avatarClasses } from '@mui/material/Avatar';
import { S3_URL } from 'api/config';
import { convertAvatarImagePath } from 'utils/helpers/avatar';
type Props = {
  src: string;
};

const StyledAvatar = styled(Avatar)(() => ({
  [`&.${avatarClasses.root}`]: {
    width: '130px',
    height: '130px',
  },
}));

const UserAvatar: React.FC<Props> = ({ src }) => {
  return <StyledAvatar src={convertAvatarImagePath(src)} />;
};

export default UserAvatar;
