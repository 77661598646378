import React from 'react';
import { Navigate } from 'react-router-dom';
import { Routes, Route } from 'react-router-dom';
import PublicRoute from './common/PublicRoute';
import PrivateRoute from './common/PrivateRoute';
import MainLayout from 'routes/common/MainLayout';
import Login from 'pages/auth/Login';
import HomeDashboard from 'pages/home';
import Membership from 'pages/memberships';
import MemberProfile from 'pages/memberships/memberProfile';
import Places from 'pages/places/placesTable/index';
import CreateNewPlace from 'pages/places/createNewPlace/index';
import PlaceDetails from 'pages/places/placeDetails/index';
import PlaceEdit from 'pages/places/editPlace/index';
import Cities from 'pages/cities/citiesTable';
import UpcomingExperiences from 'pages/experiences/upcomingExperiences';
import Products from 'pages/products';
import Checkins from 'pages/check-in';
import Reservations from 'pages/reservations';
import CreateExperience from 'pages/experiences/experienceProfile/CreateExperience';
import EditExperience from 'pages/experiences/experienceProfile/EditExperience';
import ExperiencesHistory from 'pages/experiences/experiencesHistory';
import ExperienceDetails from 'pages/experiences/experienceDetails';
import Payments from 'pages/transactions/index';
import Rooms from 'pages/rooms';
import Hotspots from 'pages/hotspots/hotspotsTable';
import CreateHotspot from 'pages/hotspots/createHotspot';
import EditHotspot from 'pages/hotspots/editHotspot';
import HotspotDetails from 'pages/hotspots/hotspotDetails';
import Announcements from 'pages/announcements';
import CreateNewCity from 'pages/cities/createCity';
import EditCity from 'pages/cities/editCity';
import HeatMap from 'pages/memberships/heatMap';
import UsersAnalytics from 'pages/home/usersAnalytics/index';
import AwaitingReviewMembers from 'pages/memberships/awaitingReviewMembers';
import CreateCheckin from 'pages/check-in/createCheckin';
import Tags from 'pages/tags';
import Matches from 'pages/matches';
import CreateMatch from 'pages/matches/matchForm';
import CreateNewMatch from 'pages/matches/createMatch';
import EditMatch from 'pages/matches/editMatch';
import MatchesReview from 'pages/matches/matchesReview';

interface Props {
  userRole: string;
}

const MainRouter: React.FC<Props> = ({ userRole }) => {
  return (
    <>
      <Routes>
        <Route
          path="/login"
          element={
            <PublicRoute>
              <Login />
            </PublicRoute>
          }
        />
        <Route
          path="/"
          element={
            <PrivateRoute>
              <MainLayout />
            </PrivateRoute>
          }
        >
          <Route path="/dashboard/home" element={<HomeDashboard />} />
          <Route path="/dashboard/users" element={<UsersAnalytics />} />

          <Route path="/membership/all-members" element={<Membership />} />
          <Route
            path="/membership/awaiting-review-members"
            element={<AwaitingReviewMembers />}
          />
          <Route path="/membership/heat-map" element={<HeatMap />} />
          <Route path="/membership/profile/:memberId" element={<MemberProfile />} />

          <Route path="/experiences/upcoming" element={<UpcomingExperiences />} />
          <Route path="/experiences/new" element={<CreateExperience />} />
          <Route path="/experiences/edit/:experienceId" element={<EditExperience />} />
          <Route
            path="/experiences/details/:experienceId"
            element={<ExperienceDetails />}
          />
          <Route path="/experiences/history" element={<ExperiencesHistory />} />

          <Route path="/products" element={<Products />} />

          <Route path="/cities/all-cities" element={<Cities />} />
          <Route path="/cities/new" element={<CreateNewCity />} />
          <Route path="/cities/edit/:cityId" element={<EditCity />} />

          <Route path="/checkins" element={<Checkins />} />
          <Route path="/checkins/new" element={<CreateCheckin />} />
          <Route path="/rooms" element={<Rooms />} />

          <Route path="/reservations" element={<Reservations />} />

          <Route path="/places/all-places" element={<Places />} />
          <Route path="/places/new" element={<CreateNewPlace />} />
          <Route path="/places/details/:placeId" element={<PlaceDetails />} />
          <Route path="/places/edit/:placeId" element={<PlaceEdit />} />

          <Route path="/announcements" element={<Announcements />} />
          <Route path="/tags" element={<Tags />} />

          <Route path="/transactions" element={<Payments />} />

          <Route path="/" element={<Navigate to={'/dashboard/home'} />} />

          <Route path="/hotspots/all-hotspots" element={<Hotspots />} />
          <Route path="/hotspots/new" element={<CreateHotspot />} />
          <Route path="/hotspots/edit/:hotspotId" element={<EditHotspot />} />
          <Route path="/hotspots/details/:hotspotId" element={<HotspotDetails />} />

          <Route path="/matches/all-matches" element={<Matches />} />
          <Route path="/matches/new" element={<CreateNewMatch />} />
          <Route path="/matches/edit/:matchId" element={<EditMatch />} />
          <Route path="/matches/matches-review" element={<MatchesReview />} />
        </Route>
      </Routes>
    </>
  );
};

export default MainRouter;
