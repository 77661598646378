import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from 'app/hooks';
import { CustomSelect, FormikInput } from 'components';
import { useFormik } from 'formik';
import { TagDto } from 'models/tag';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { tagsActions } from 'redux/reducers/tags';
import { CreateTagsRequest, TagCategoryEnum } from 'types/tag';
import * as yup from 'yup';
import { TAG_FILTERS } from './tagsFilters';

const validationSchema = yup.object().shape({
  key: yup.string().required('Key is required'),
  value: yup.string().required('Value is required'),
  category: yup.string().required('Cateogory is required'),
});

type NewTagForm = {
  key: string;
  value: string;
  category: TagCategoryEnum;
  id: string;
};

type ComponentProps = {
  editableTag: TagDto | null;
  handleEditTag: (tag: TagDto | null) => void;
};

const NewTagForm: React.FC<ComponentProps> = ({ editableTag, handleEditTag }) => {
  const dispatch = useAppDispatch();
  const isEditModeActive = useMemo(() => Boolean(editableTag), [editableTag]);

  const handleSubmit = useCallback(
    async (values: NewTagForm): Promise<void> => {
      const requestData: CreateTagsRequest = {
        value: values.value,
        key: values.key,
        category: values.category,
      };

      if (values.id) {
        await dispatch(
          tagsActions.editTag({
            data: requestData,
            id: values.id,
          })
        );
        resetFormHandler();
      } else {
        await dispatch(tagsActions.createTag(requestData));
        formik.resetForm();
      }
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: {
      key: '',
      value: '',
      category: '' as TagCategoryEnum,
      id: '',
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
  });

  const setFormValues = (tag: TagDto) => {
    const { setValues } = formik;
    setValues({
      id: tag._id,
      key: tag.key,
      value: tag.value,
      category: tag.category,
    });
  };

  useEffect(() => {
    if (editableTag) {
      setFormValues(editableTag);
    }
  }, [editableTag]);

  const resetFormHandler = () => {
    handleEditTag(null);
    formik.resetForm();
  };

  return (
    <Stack component="form" onSubmit={formik.handleSubmit} className="new-tags-form">
      <Typography
        sx={{ fontWeight: '600' }}
        className="form-title"
        variant="body1"
        component="p"
      >
        {isEditModeActive ? 'Edit Tag' : 'New Tag'}
      </Typography>
      <FormikInput formik={formik} name="value" label="Name" />
      <FormikInput formik={formik} name="key" label="Key" />
      <CustomSelect
        className="experience-item"
        name="category"
        label="Category"
        options={TAG_FILTERS}
        value={formik.values.category || ''}
        onChange={formik.handleChange}
        error={formik.touched.category && Boolean(formik.errors.category)}
        helperText={formik.touched.category && formik.errors.category}
      />
      <Stack marginBottom={3}>
        {isEditModeActive ? (
          <Stack>
            <Button
              variant="contained"
              color="secondary"
              fullWidth
              onClick={resetFormHandler}
            >
              CANCEL
            </Button>
          </Stack>
        ) : null}
      </Stack>
      <Button variant="contained" color="primary" fullWidth type="submit">
        {isEditModeActive ? 'SAVE' : 'SUBMIT'}
      </Button>
    </Stack>
  );
};

export default NewTagForm;
