import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { LoginRequest, LoginResponse } from 'types/auth';

export interface AuthState {
  loading: boolean;
  token: string | null;
  isSignedIn: boolean;
  currentUser: any | null;
  error: any;
};

const initialState: AuthState = {
  loading: false,
  token: null,
  isSignedIn: false,
  error: null,
  currentUser: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState: initialState,
  reducers: {
    login(state: AuthState, action: PayloadAction<LoginRequest>) {
      state.loading = true;
    },
    loginSuccess(state: AuthState, action: PayloadAction<LoginResponse>) {
      state.loading = false;
      state.token = action.payload.accessToken;
    },
    loginFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getCurrentUser(state: AuthState, action: PayloadAction) {
      state.loading = true;
    },
    getCurrentUserSuccess(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.currentUser = action.payload;
    },
    getCurrentUserFailed(state: AuthState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const authActions = authSlice.actions;

// Reducer
const authReducer = authSlice.reducer;
export default authReducer;
