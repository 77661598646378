import { PayloadAction } from '@reduxjs/toolkit';
import partnersApi from 'api/partners';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { partnersActions } from 'redux/reducers/partners';
import { GetPartnersRequest, GetPartnersResponse } from 'types/partners';

function* getPartners(data: PayloadAction<GetPartnersRequest>) {
  try {
    const response: GetPartnersResponse = yield call(partnersApi.getPartners, data.payload);
    yield put(partnersActions.getPartnersSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch partners`, error);
    if (axios.isAxiosError(error)) {
      yield put(partnersActions.getPartnersFailed(error.response?.data.message));
    }
  }
};

export default function* partnersSaga() {
  yield takeLatest(partnersActions.getPartners.type, getPartners);
};
