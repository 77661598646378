import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { HotspotState } from 'redux/reducers/hotspots';

export const hotspotsSelector = (state: RootState): HotspotState => state.hotspots;

export const allHotspotsSelector = createSelector(
  hotspotsSelector,
  (hotspots) => hotspots.hotspots
);

export const totalHotspotsSelector = createSelector(
  hotspotsSelector,
  (hotspots) => hotspots.total
);

export const hotspotByIdSelector = createSelector(
  hotspotsSelector,
  (hotspots) => hotspots.selectedHotspot
);

export const hotspotsLoadingSelector = createSelector(
  hotspotsSelector,
  (hotspots) => hotspots.loading
);