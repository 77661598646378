import { FC, useEffect, useCallback, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { placeByIdSelector, subscriptionsSelector } from 'redux/selectors/places';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { placesActions } from 'redux/reducers/places';
import MemberInfoLine from 'pages/memberships/memberProfile/memberInfoLine';
import { placeTypesOptions } from 'models/place';
import { CopyClickboardInput } from 'components';

type Props = {
  membersOnly: boolean | undefined;
  type: string | undefined;
  id: string | undefined;
  code: string | undefined;
};
const PlaceDetailsInfo: FC<Props> = ({ membersOnly, type, id, code }) => {
  const dispatch = useAppDispatch();
  const place = useAppSelector(placeByIdSelector);
  const subscriptions = useAppSelector(subscriptionsSelector);

  useEffect(() => {
    if (!subscriptions.length) {
      dispatch(placesActions.getSubscriptions());
    }
  }, [dispatch, subscriptions]);

  const getTypeName = useCallback((type: string) => {
    const currentType = placeTypesOptions.find((e) => e.value === type);
    return currentType?.label || '';
  }, []);

  return (
    <Stack direction="column">
      <Typography sx={{ marginBottom: '18px' }} variant="h2">
        Information
      </Typography>
      <MemberInfoLine value={id || ''} label="ID" />
      <MemberInfoLine value={code || ''} label="Code" />
      <MemberInfoLine value={membersOnly ? 'Private' : 'Public'} label="Access" />
      <MemberInfoLine value={getTypeName(type || '')} label="Type" />
      {/* <Stack flexDirection={'row'} alignItems={'center'}>
        <Typography sx={{ marginRight: '8px' }} variant="caption">
          Check-in link:
        </Typography>
        <CopyClickboardInput
          inputValue={`${process.env.REACT_APP_API_SIMPLIFIED_URL}/api/d/checkin/${id}`}
        />
      </Stack> */}

      <Stack style={{ marginTop: '16px' }}>
        <Typography sx={{ marginBottom: '16px' }} variant="h2">
          Links
        </Typography>

        <CopyClickboardInput
          sx={{ marginBottom: '12px', justifyContent: 'flex-start' }}
          inputSx={{ width: '350px' }}
          title="Direct to app"
          inputValue={`yayem://spaces?id=${id}`}
        />
        <CopyClickboardInput
          sx={{
            marginBottom: '12px',
            justifyContent: 'flex-start',
          }}
          inputSx={{ width: '350px' }}
          title="Dynamic Link"
          inputValue={`${process.env.REACT_APP_API_SIMPLIFIED_URL}/api/d/s?id=${id}`}
        />

        <CopyClickboardInput
          sx={{ marginBottom: '12px', justifyContent: 'flex-start' }}
          inputSx={{ width: '350px' }}
          title="Check-in link"
          inputValue={`${process.env.REACT_APP_API_SIMPLIFIED_URL}/api/d/checkin/${id}`}
        />
      </Stack>
    </Stack>
  );
};

export default PlaceDetailsInfo;
