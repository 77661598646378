import { City } from './cities';

export type Hotspot = {
  _id: string;
  name: string;
  author: string;
  city: City;
  imageUrl: string;
  pdfUrl: string;
  createdAt: Date;
  updatedAt: Date;
  isGlobal: boolean;
  user: HotspotMemberType;
  description: string;
  category: GuideCategoryEnum;
  cities: City[];
};

export type HotspotMemberType = {
  _id: string;
  email: string;
  profile: {
    firstName: string;
    lastName: string;
  };
};

export enum GuideCategoryEnum {
  HOOD_HOPPING = 'hood_hopping',
  CLASSIC_EATS = 'classic_eats',
  ESCAPE_CITY = 'escape_city',
  WINE_GUIDE = 'wine_guides',
  BEST_BARS = 'best_bars',
  BOUTIQUE_HOTELS = 'boutique_hotels',
  HOURS_48_IN = 'hours_48_in',
  SLAY = 'slay',
  WORK_VIBEZ = 'work_vibez',
  LOCAL_EATS = 'local_eats',
  TOP_6 = 'top_6',
}
