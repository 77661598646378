import { FC } from 'react';
import TableContainer from '@mui/material/TableContainer';
import TableBody from '@mui/material/TableBody';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableRow from '@mui/material/TableRow';
import styled from '@mui/material/styles/styled';
import { CheckinDto } from 'models/checkin';
import moment from 'moment';
import Typography from '@mui/material/Typography';
import { useNavigate } from 'react-router-dom';
import { Match } from 'models/match';
import { Box } from '@mui/material';

interface Props {
  matches: Match[];
  myUserId: string;
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '8px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: '1px solid rgb(247, 247, 255)',
    padding: '8px 16px',
  },
}));

const MemberMatchesTable: FC<Props> = ({ matches, myUserId }) => {
  const navigate = useNavigate();
  if (!matches?.length) return null;

  return (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">Matched User</StyledTableCell>
            <StyledTableCell align="left">Common Tags</StyledTableCell>
            <StyledTableCell align="left">Creator</StyledTableCell>
            <StyledTableCell align="left">Message</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {matches.map((match) => {
            const matchedUser = match.user1._id === myUserId ? match.user2 : match.user1;
            return (
              <StyledTableRow key={match._id}>
                <StyledTableCell align="left">
                  <Typography
                    variant="body2"
                    sx={{
                      textDecoration: 'underline',
                      cursor: 'pointer',
                    }}
                    onClick={() => navigate(`/membership/profile/${matchedUser._id}`)}
                  >
                    {matchedUser.email}
                  </Typography>
                </StyledTableCell>
                <StyledTableCell>
                  {match.commonTags.map((tag) => tag.value).join(', ')}
                </StyledTableCell>
                <StyledTableCell align="left">{match.createdBy}</StyledTableCell>
                <StyledTableCell align="left">{match.message}</StyledTableCell>
                <StyledTableCell align="left">
                  <Box className={`match-status-badge ${match.status}`}>
                    <Typography className="status-badge-label" variant="body2">
                      {match.status}
                    </Typography>
                  </Box>
                </StyledTableCell>
              </StyledTableRow>
            );
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MemberMatchesTable;
