import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AnnouncementDto } from 'models/announcement';
import {
  GetAnnouncementsRequest,
  GetAnnouncementsResponse,
  CreateAnnouncementsRequest,
  CreateAnnouncementsResponse,
  EditAnnouncementRequest,
  DeleteAnnouncementsRequest,
  AnnouncementFilter,
} from 'types/announcements';

export interface AnnouncementsState {
  loading: boolean;
  announcements: AnnouncementDto[];
  error: any;
  total: number | null;
  offset: number;
  expirationDateFilter: AnnouncementFilter;
  searchValue: string;
}

const initialState: AnnouncementsState = {
  loading: false,
  announcements: [],
  error: null,
  total: null,
  offset: 0,
  expirationDateFilter: AnnouncementFilter.ACTIVE,
  searchValue: '',
};

const announcementsSlice = createSlice({
  name: 'announcements',
  initialState: initialState,
  reducers: {
    getAnnouncements(state: AnnouncementsState, action: PayloadAction<GetAnnouncementsRequest>) {
      state.loading = true;
    },
    getAnnouncementsSuccess(state: AnnouncementsState, action: PayloadAction<GetAnnouncementsResponse>) {
      state.loading = false;
      state.total = action.payload.total;
      if (state.offset > 0) {
        state.announcements = [...state.announcements, ...action.payload.data];
      } else {
        state.announcements = action.payload.data;
      }
    },
    setExpirationDateFilter(state: AnnouncementsState, action: PayloadAction<AnnouncementFilter>) {
      state.expirationDateFilter = action.payload;
    },
    setSearchValue(state: AnnouncementsState, action: PayloadAction<string>) {
      state.searchValue = action.payload;
    },
    getAnnouncementsFailed(state: AnnouncementsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    createAnnouncement(state: AnnouncementsState, action: PayloadAction<CreateAnnouncementsRequest>) {
      state.loading = true;
    },
    createAnnouncementSuccess(
      state: AnnouncementsState,
      action: PayloadAction<CreateAnnouncementsResponse>
    ) {
      state.loading = false;
    },
    createAnnouncementFailed(state: AnnouncementsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    editAnnouncement(state: AnnouncementsState, action: PayloadAction<EditAnnouncementRequest>) {
      state.loading = true;
    },
    editAnnouncementSuccess(
      state: AnnouncementsState,
      action: PayloadAction<CreateAnnouncementsResponse>
    ) {
      state.loading = false;
    },
    editAnnouncementFailed(state: AnnouncementsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    deleteAnnouncement(state: AnnouncementsState, action: PayloadAction<DeleteAnnouncementsRequest>) {
      state.loading = true;
    },
    deleteAnnouncementSuccess(
      state: AnnouncementsState,
      action: PayloadAction<any>
    ) {
      state.loading = false;
    },
    deleteAnnouncementFailed(state: AnnouncementsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOffset(state: AnnouncementsState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const announcementsActions = announcementsSlice.actions;

// Reducer
const announcementsReducer = announcementsSlice.reducer;
export default announcementsReducer;
