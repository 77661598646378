import { PayloadAction } from '@reduxjs/toolkit';
import announcementsApi from 'api/announcements';
import { RootState } from 'app/store';
import axios from 'axios';
import moment from 'moment';
import { call, put, takeLatest, select } from 'redux-saga/effects';
import { announcementsActions } from 'redux/reducers/announcements';
import { appActions } from 'redux/reducers/app';
import {
  GetAnnouncementsRequest,
  GetAnnouncementsResponse,
  CreateAnnouncementsRequest,
  CreateAnnouncementsResponse,
  EditAnnouncementRequest,
  DeleteAnnouncementsRequest,
} from 'types/announcements';

function* getAnnouncements(data: PayloadAction<GetAnnouncementsRequest>) {
  try {
    const response: GetAnnouncementsResponse = yield call(
      announcementsApi.getAnnouncements,
      data.payload
    );
    yield put(announcementsActions.getAnnouncementsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch announcements`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        announcementsActions.getAnnouncementsFailed(error.response?.data.message)
      );
    }
  }
}

function* updateAnnouncementsList() {
  try {
    const { searchValue, expirationDateFilter } = yield select(
      (state: RootState) => state.announcements
    );

    const updatedAnnouncements: GetAnnouncementsResponse = yield call(
      announcementsApi.getAnnouncements,
      {
        query: searchValue,
        expirationDateFilter: expirationDateFilter,
        expirationDate: moment().format('YYYY-MM-DDT00:00:00.000[Z]'),
      }
    );
    yield put(announcementsActions.getAnnouncementsSuccess(updatedAnnouncements));
  } catch (error) {
    console.log(`Failed to update announcements list`, error);
  }
}

function* createAnnouncement(data: PayloadAction<CreateAnnouncementsRequest>) {
  try {
    const response: CreateAnnouncementsResponse = yield call(
      announcementsApi.createAnnouncement,
      data.payload
    );
    yield put(announcementsActions.createAnnouncementSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'The announcement was successfuly created!',
        severity: 'success',
      })
    );
    yield call(updateAnnouncementsList);
  } catch (error) {
    console.log(`Failed to create announcement`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        announcementsActions.createAnnouncementFailed(error.response?.data.message)
      );
    }
  }
}

function* editAnnouncement(data: PayloadAction<EditAnnouncementRequest>) {
  try {
    const response: CreateAnnouncementsResponse = yield call(
      announcementsApi.editAnnouncement,
      data.payload
    );
    yield put(announcementsActions.editAnnouncementSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'The announcement was successfuly updated!',
        severity: 'success',
      })
    );
    yield call(updateAnnouncementsList);
  } catch (error) {
    console.log(`Failed to edit announcement`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        announcementsActions.editAnnouncementFailed(error.response?.data.message)
      );
    }
  }
}

function* deleteAnnouncement(data: PayloadAction<DeleteAnnouncementsRequest>) {
  try {
    const response: CreateAnnouncementsResponse = yield call(
      announcementsApi.deleteAnnouncement,
      data.payload
    );
    yield put(announcementsActions.deleteAnnouncementSuccess(response));
    yield call(updateAnnouncementsList);
  } catch (error) {
    console.log(`Failed to delete announcement`, error);
    if (axios.isAxiosError(error)) {
      yield put(
        announcementsActions.deleteAnnouncementFailed(error.response?.data.message)
      );
    }
  }
}

export default function* announcementsSaga() {
  yield takeLatest(announcementsActions.getAnnouncements.type, getAnnouncements);
  yield takeLatest(announcementsActions.createAnnouncement.type, createAnnouncement);
  yield takeLatest(announcementsActions.editAnnouncement.type, editAnnouncement);
  yield takeLatest(announcementsActions.deleteAnnouncement.type, deleteAnnouncement);
}
