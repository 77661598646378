import { PayloadAction } from '@reduxjs/toolkit';
import analyticsApi from 'api/analytics';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { analyticsActions } from 'redux/reducers/analytics';
import {
  GetWeeklyAnalyticsResponse,
  NewUsersStatusAnalyticsResponse,
  ChurnUsersAnalyticsResponse,
  UsersSubscriptionAnalyticsResponse,
  AnalyticsRequest
} from 'types/analytics';

// TODO: add filter queries
function* getWeeklyAnalytics(data: PayloadAction<any>) {
  try {
    const response: GetWeeklyAnalyticsResponse = yield call(
      analyticsApi.getWeeklyAnalytics,
      data.payload
    );
    yield put(analyticsActions.getWeeklyAnalyticsSuccess(response));
  } catch (error) {
    console.log(`Failed to get weekly analyticsResponse`, error);
    if (axios.isAxiosError(error)) {
      yield put(analyticsActions.getWeeklyAnalyticsError(error.response?.data.message));
    }
  }
}

function* getNewUsersStatusAnalytics(data: PayloadAction<AnalyticsRequest>) {
  try {
    const response: NewUsersStatusAnalyticsResponse = yield call(
      analyticsApi.getNewUsersStatusAnalytics,
      data.payload
    );
    yield put(analyticsActions.getNewUsersStatusAnalyticsSuccess(response));
  } catch (error) {
    console.log(`Failed to get users statuses analytics`, error);
    if (axios.isAxiosError(error)) {
      yield put(analyticsActions.getNewUsersStatusAnalyticsError(error.response?.data.message));
    }
  }
}

function* getChurnUsersAnalytics(data: PayloadAction<AnalyticsRequest>) {
  try {
    const response: ChurnUsersAnalyticsResponse = yield call(
      analyticsApi.getChurnUsersAnalytics,
      data.payload
    );
    yield put(analyticsActions.getChurnUsersAnalyticsSuccess(response));
  } catch (error) {
    console.log(`Failed to get churn user analytics`, error);
    if (axios.isAxiosError(error)) {
      yield put(analyticsActions.getChurnUsersAnalyticsError(error.response?.data.message));
    }
  }
}

function* getUsersSubscriptionAnalytics(data: PayloadAction<AnalyticsRequest>) {
  try {
    const response: UsersSubscriptionAnalyticsResponse = yield call(
      analyticsApi.getUsersSubscriptionAnalytics,
      data.payload
    );
    yield put(analyticsActions.getUsersSubscriptionAnalyticsSuccess(response));
  } catch (error) {
    console.log(`Failed to get subscription analytics`, error);
    if (axios.isAxiosError(error)) {
      yield put(analyticsActions.getUsersSubscriptionAnalyticsError(error.response?.data.message));
    }
  }
}

export default function* analyticsSaga() {
  yield takeLatest(analyticsActions.getWeeklyAnalytics.type, getWeeklyAnalytics);
  yield takeLatest(analyticsActions.getNewUsersStatusAnalytics.type, getNewUsersStatusAnalytics);
  yield takeLatest(analyticsActions.getChurnUsersAnalytics.type, getChurnUsersAnalytics);
  yield takeLatest(analyticsActions.getUsersSubscriptionAnalytics.type, getUsersSubscriptionAnalytics);
}
