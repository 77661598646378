import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { CheckInState } from 'redux/reducers/checkIn';

export const checkinsSelector = (state: RootState): CheckInState => state.checkin;

export const allCheckInsSelector = createSelector(
  checkinsSelector,
  (checkins) => checkins.checkIns
);

export const allReservationsSelector = createSelector(
  checkinsSelector,
  (checkins) => checkins.reservations
);

export const checkinsLoadingSelector = createSelector(
  checkinsSelector,
  (checkins) => checkins.loading
);

export const checkinsTotalSelector = createSelector(
  checkinsSelector,
  (checkins) => checkins.total
);

export const checkinsErrorSelector = createSelector(
  checkinsSelector,
  (checkins) => checkins.error
);
