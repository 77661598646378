import {
  CreateMatchRequest,
  DeleteMatchRequest,
  GetMatchByIdRequest,
  GetMatchByIdResponse,
  GetMatchesRequest,
  GetMatchesResponse,
  IgnoreMatchRequest,
  MatchReviewResponse,
  UpdateMatchRequest,
  UpdateMatchResponse,
} from 'types/matches';
import axiosApi from './config';

const matchesApi = {
  getMatchById({ id }: GetMatchByIdRequest): Promise<GetMatchByIdResponse> {
    const url = `matches/${id}`;
    return axiosApi.get(url);
  },
  createMatch(data: CreateMatchRequest): Promise<CreateMatchRequest> {
    const url = 'matches';
    return axiosApi.post(url, data);
  },
  updateMatch(data: UpdateMatchRequest): Promise<UpdateMatchResponse> {
    const url = `matches/${data.id}`;
    return axiosApi.patch(url, data.data);
  },
  getMatches({ status }: GetMatchesRequest): Promise<GetMatchesResponse> {
    const url = `matches?${status ? `&filter[status]=${status}` : ''}`;
    return axiosApi.get(url);
  },
  deleteMatch(data: DeleteMatchRequest): Promise<any> {
    const url = `matches/${data.id}`;
    return axiosApi.delete(url);
  },
  loadQueueMatches(): Promise<any> {
    const url = 'matches/fill-queue';
    return axiosApi.post(url);
  },
  publishAllMatches(): Promise<any> {
    const url = 'matches/publish';
    return axiosApi.post(url);
  },
  ignoreMatch(data: IgnoreMatchRequest): Promise<any> {
    const url = `matches/ignore/${data.id}`;
    return axiosApi.put(url);
  },
  matchesReview(): Promise<MatchReviewResponse> {
    const url = 'matches/matches-review';
    return axiosApi.get(url);
  },
};

export default matchesApi;
