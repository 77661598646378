import React, { FC } from 'react';
import { SxProps, Theme } from '@mui/material/styles';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { GrowthIcon } from 'assets/svg/svg-components';

type Props = {
  isUp: boolean;
  label: string;
  sx?: SxProps<Theme>;
};
const PercentageDiff: FC<Props> = ({ isUp, label, sx }) => {
  return (
    <Stack sx={sx} className={`percentage-diff percentage-item ${isUp ? 'up' : 'down'}`}>
      <Box className="percentage-icon-wrap">
        <GrowthIcon />
      </Box>
      <Typography variant="caption" className="percentage-title">
        {label}
      </Typography>
    </Stack>
  );
};

export default PercentageDiff;
