import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import {
  ReactLoader,
  StyledTableCell,
  StyledTableRow
} from 'components';
import { FC } from 'react';
import { useNavigate } from "react-router-dom";
import { SortOrder } from 'types/common';
import { MembershipSortBy } from 'types/users';

const TABLE_HEAD = [
  {
    orderBy: 'name',
    label: 'Name',
  },
  {
    orderBy: 'email',
    label: 'Email',
  },
];

type Props = {
  onLoadMore: () => void;
  data: any;
  total: number;
  loading?: boolean;
  orderBy?: MembershipSortBy | null;
};

const HeatMapMembersTable: FC<Props> = ({
  onLoadMore,
  data,
  total,
  loading,
  orderBy,
}) => {

  const navigate = useNavigate();

  const onClickHandler = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    userId: string
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(
        `${window.location.origin}/membership/profile/${userId}`,
        '_blank',
        'noreferrer'
      );
    } else {
      navigate(`/membership/profile/${userId}`);
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !data?.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow className="table-head">
                {TABLE_HEAD.map((item) => (
                  <StyledTableCell
                    key={item.label}
                    align="left"
                  >
                    {item.orderBy ? (
                      <TableSortLabel
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((member: any) => (
                <StyledTableRow
                  className="table-row"
                  key={member._id}
                  onClick={(e) => onClickHandler(e, member._id)}
                >
                  <StyledTableCell align="left">{member?.profile?.firstName} {member?.profile?.lastName}</StyledTableCell>
                  <StyledTableCell align="left">
                    {member.email}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {total && data?.length < total ? (
            <Button onClick={onLoadMore} variant="contained" color="primary" fullWidth>
              Load more
            </Button>
           ) : null}
        </TableContainer>
      )}
    </Paper>
  );
};

export default HeatMapMembersTable;
