import { PaymentsResponse, PaymentsRequest } from 'types/payments';
import axiosApi from './config';

const paymentsApi = {
  getPayments({ query = '', limit = '10', offset = 0, sort = null, type = null, startDate, endDate, status }: PaymentsRequest): Promise<PaymentsResponse> {
    const params = new URLSearchParams({
      query,
      limit,
      offset: offset.toString(),
    });
    const url = `/payments?${params.toString()}${type ? `&filter[type]=${type}` : ''}${status && status !== 'all' ? `&filter[status]=${status}` : ''}${sort ? sort : ''}${startDate ? `&filter[startDate]=${startDate}` : ''}${endDate ? `&filter[endDate]=${endDate}` : ''}`;
    return axiosApi.get(url);
  },
  getPaymentsCsv({ query = '', limit = '10', offset = 0, sort = null, type = null, startDate, endDate, status, base = 'USD' }: PaymentsRequest): Promise<PaymentsResponse> {
    const params = new URLSearchParams({
      query,
      limit,
      offset: offset.toString(),
    });
    const url = `/payments/generate/csv?${params.toString()}&base=${base}${type ? `&filter[type]=${type}` : ''}${status && status !== 'all' ? `&filter[status]=${status}` : ''}${sort ? sort : ''}${startDate ? `&filter[startDate]=${startDate}` : ''}${endDate ? `&filter[endDate]=${endDate}` : ''}`;
    return axiosApi.get(url);
  },
};

export default paymentsApi;
