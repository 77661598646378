import {
  GetCheckInsResponse,
  GetCheckInsRequest,
  UpdateCheckInRequest,
  CreateCheckInRequest,
  GetReservationsResponse,
} from 'types/check-in';
import axiosApi from './config';

const CheckInApi = {
  getCheckIns({
    date = null,
    placeId = '',
    query = '',
    limit = '10',
    offset = 0,
    sort = null,
  }: GetCheckInsRequest): Promise<GetCheckInsResponse> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });

    const url = `/check-in?${params.toString()}${'&sort[createdAt]=desc'}${
      placeId ? `&filter[placeId]=${placeId}` : ''
    }${
      date?.startDate && date?.endDate
        ? `&filter[startDate]=${date.startDate}&filter[endDate]=${date.endDate}`
        : ''
    }`;
    return axiosApi.get(url);
  },

  createCheckin(data: CreateCheckInRequest) {
    const url = `/check-in/confirm-checkin-v2`;
    return axiosApi.post(url, data);
  },
  editCheckin(data: UpdateCheckInRequest) {
    const url = `reservations/${data.id}`;
    return axiosApi.put(url, data.data);
  },

  getReservations({
    date = null,
    placeId = '',
    places = undefined,
    query = '',
    limit = '10',
    offset = 0,
    sort = null,
  }: GetCheckInsRequest): Promise<GetReservationsResponse> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });

    const url = `/reservations?${params.toString()}${'&sort[createdAt]=desc'}${
      places && places.length > 0 ? `&filter[places]=${places.join(',')}` : ''
    }${
      date?.startDate && date?.endDate
        ? `&filter[startDate]=${date.startDate}&filter[endDate]=${date.endDate}`
        : ''
    }`;
    return axiosApi.get(url);
  },
};

export default CheckInApi;
