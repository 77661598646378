import { SubscriptionSettings, SubscriptionVisistsEnum } from 'models/place';

export const getDefaultSubscriptionSettingsForPlaces = (): SubscriptionSettings[] => {
  return [
    {
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PRODUCT_ID as string,
      visitsRestrictionType: SubscriptionVisistsEnum.NONE,
    },
    {
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PLUS_PRODUCT_ID as string,
      visitsRestrictionType: SubscriptionVisistsEnum.SPECIFIC_AMOUNT,
      visitsRestrictionAmount: 24,
    },
    {
      subscriptionId: process.env
        .REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_UNLIMITED_PRODUCT_ID as string,
      visitsRestrictionType: SubscriptionVisistsEnum.UNLIMITED,
    },
  ];
};
