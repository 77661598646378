import React, { useEffect, useMemo, useState } from 'react';
import TextField from '@mui/material/TextField';
import { SxProps, Theme } from '@mui/material/styles';
import { downArrow } from 'assets/svg';
import Autocomplete from '@mui/material/Autocomplete';
import Chip from '@mui/material/Chip';
import usersApi from 'api/users';
import { Member } from 'models/member';
import { useDebounceSearch } from 'utils/hooks';
import Typography from '@mui/material/Typography';
import { AutoSelectOptionType } from 'types/common';

type Props = {
  label: string;
  selected?: AutoSelectOptionType | AutoSelectOptionType[];
  onChange: (value: AutoSelectOptionType | AutoSelectOptionType[]) => void;
  sx?: SxProps<Theme>;
  name?: string;
  multiple?: boolean;
  error?: boolean;
  errorText?: string;
};

const UsersAutocomplete: React.FC<Props> = ({
  onChange,
  label,
  sx,
  selected,
  multiple = true,
  error = false,
  errorText,
}) => {
  const [offset, setOffset] = useState(0);
  const [options, setOptions] = useState<Member[]>([]);
  const [searchValue, setSearchValue] = useState('');
  const debounceSearchValue = useDebounceSearch(searchValue, 50);

  useEffect(() => {
    getUsers();
  }, [debounceSearchValue, offset]);

  const getUsers = async () => {
    const result = await usersApi.getUsersForAutocomplete({
      query: searchValue,
      offset: offset,
    });

    setOptions(result.data);
  };

  const availableOptions: AutoSelectOptionType[] = useMemo(() => {
    return options.map((o) => ({
      label: `${o.profile?.firstName || ''} ${o.profile?.lastName || ''} (${o.email})`,
      value: o._id,
    }));
  }, [options]);

  return (
    <>
      <Autocomplete
        className={error ? 'error-border' : ''}
        multiple={multiple}
        sx={sx}
        value={selected}
        onChange={(event, newValue) => {
          if (Array.isArray(newValue)) {
            onChange(newValue);
          } else {
            onChange(newValue);
          }
        }}
        disableClearable={true}
        options={availableOptions}
        getOptionLabel={(option: AutoSelectOptionType) => option.label}
        renderOption={(props, option) => <li {...props}>{option.label}</li>}
        popupIcon={
          <img
            style={{
              padding: '8px',
              backgroundColor: 'transparent',
              right: '18px',
              top: 'calc(50% - 0.4em)',
            }}
            src={downArrow}
            alt=""
          />
        }
        renderInput={(params) => (
          <TextField
            {...params}
            value={searchValue}
            label={label}
            onChange={(e) => setSearchValue(e.target.value)}
          />
        )}
        renderTags={(value, getTagProps) =>
          value.map((option, index) => (
            <Chip
              label={option.label}
              {...getTagProps({ index })}
              sx={{
                marginRight: '5px',
                backgroundColor: 'transparent',
                border: '1px solid rgba(30, 30, 112, 0.05)',
              }}
            />
          ))
        }
      />
      {error && (
        <Typography sx={{ margin: '-10px 0px 0 14px' }} color="error" variant="caption">
          {errorText || ''}
        </Typography>
      )}
    </>
  );
};

export default UsersAutocomplete;
