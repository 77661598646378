import React, { useEffect, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { CustomSelect } from 'components';
import { placesActions } from 'redux/reducers/places';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { allPacesSelector } from 'redux/selectors/places';
import DateFilter from './datePicker';
import moment from 'moment';

type Props = {
  onPlaceChange: (value: string) => void;
  onDateChange: (value: Date) => void;
  selectedDate: Date;
  place: string;
};

const RoomsFilters: React.FC<Props> = ({
  onPlaceChange,
  onDateChange,
  place,
  selectedDate,
}) => {
  const dispatch = useAppDispatch();
  const places = useAppSelector(allPacesSelector);

  useEffect(() => {
    if (!places?.length) {
      dispatch(placesActions.getPlaces({ limit: '100', offset: 0 }));
    }
  }, [dispatch, places]);

  useEffect(() => {
    if (place && selectedDate) {
      dispatch(
        placesActions.getReservationsByPlaceId({
          startDate: selectedDate,
          placeId: place,
        })
      );
    }
  }, [dispatch, place, selectedDate]);

  const placeOptions = useMemo(() => {
    const filteredPlaces = places?.length
      ? places.filter((place) => place?.rooms?.length > 0 && place)
      : [];
    const options = filteredPlaces.map((place) => ({
      label: place.name,
      value: place._id,
    }));
    return options;
  }, [places]);

  useEffect(() => {
    if (placeOptions.length) {
      onPlaceChange(placeOptions[0].value);
    }
  }, [placeOptions]);

  return (
    <Stack
      width="90%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack width="30%" maxWidth={300}>
        <CustomSelect
          label="Place"
          name="place"
          options={placeOptions}
          value={placeOptions.length ? place : ''}
          onChange={(e) => onPlaceChange(e.target.value)}
          disabled={!placeOptions}
        />
      </Stack>
      <DateFilter onDateChange={onDateChange} selectedDate={selectedDate} />
    </Stack>
  );
};

export default RoomsFilters;
