import React, { useEffect, useMemo } from 'react';
import Stack from '@mui/material/Stack';
import { CustomSelect, SearchInput } from 'components';
import { placesActions } from 'redux/reducers/places';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { appPlacesSelector } from 'redux/selectors/app';
import DateFilters from './dateFilter';
import { DateRange } from './dateFilter';
import { DateFilterOption } from 'types/experiences';
import { appActions } from 'redux/reducers/app';
import PlacesAutocomplete from 'components/common/PlacesAutocomplete';

type Props = {
  onSearchChange: (value: string) => void;
  onPlaceChange: (value: string) => void;
  onDateChange: (value: DateRange) => void;
  searchValue: string;
  place: string;
  options: DateFilterOption[];
  defaultOption: DateFilterOption;
};

const CheckinsFilters: React.FC<Props> = ({
  onSearchChange,
  onPlaceChange,
  onDateChange,
  searchValue,
  place,
  options,
  defaultOption,
}) => {
  const dispatch = useAppDispatch();
  const places = useAppSelector(appPlacesSelector);

  useEffect(() => {
    if (!places.length) {
      dispatch(appActions.getAllPlaces());
    }
  }, [dispatch, places]);

    const selectedPlace = useMemo(() => {
      const pl = places.find((e) => e._id === place);
      return({
        label: pl?.name || '',
        value: pl?._id || '',
      })
    }, [places, place]);

  return (
    <Stack
      width="90%"
      flexDirection="row"
      alignItems="center"
      justifyContent="space-between"
    >
      <Stack width="30%" maxWidth={300}>
        <PlacesAutocomplete
          label="Place"
          name="place"
          sx={{ maxWidth: 500 }}
          selected={selectedPlace ?? undefined}
          hasAllOption
          multiple={false}
          onChange={(selected) => {
            if (!Array.isArray(selected)) {
              onPlaceChange(selected.value)
            }
          }}
        />
      </Stack>
      <DateFilters
        options={options}
        defaultOption={defaultOption}
        onDateRangeChange={onDateChange}
      />
      <Stack width="30%" marginLeft={3} maxWidth={300}>
        <SearchInput
          className="filters-item"
          name="search"
          onChange={(v) => onSearchChange(v)}
          value={searchValue}
        />
      </Stack>
    </Stack>
  );
};

export default CheckinsFilters;
