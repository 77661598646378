import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import {
  ContextMenu,
  ReactLoader,
  StatusBadge,
  StyledTableCell,
  StyledTableRow,
} from 'components';
import { FC } from 'react';
import Button from '@mui/material/Button';
import { Member } from 'models/member';
import moment from 'moment';
import TableSortLabel from '@mui/material/TableSortLabel';
import { SortOrder } from 'types/common';
import { MembershipSortBy } from 'types/users';
import Stack from '@mui/material/Stack';
import { useNavigate } from 'react-router-dom';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

type TableHeadCell = {
  orderBy?: MembershipSortBy;
  label: string;
};

const TABLE_HEAD: TableHeadCell[] = [
  {
    orderBy: 'email',
    label: 'Email',
  },
  {
    orderBy: 'profile.firstName',
    label: 'Name',
  },
  {
    orderBy: 'subscription.productName',
    label: 'Subscription',
  },
  {
    orderBy: 'createdAt',
    label: 'Application',
  },
  {
    orderBy: 'subscription.createdAt',
    label: 'Activation',
  },
  {
    orderBy: 'profile.birthday',
    label: 'Birthday',
  },
  {
    orderBy: 'profile.countryOfResidence',
    label: 'Country',
  },
  {
    orderBy: 'successfulReferrals',
    label: 'Referrals',
  },
  {
    orderBy: 'experienceBookingsCountLast3Month',
    label: 'Experiences',
  },
  {
    orderBy: 'checkinsCountLast3Month',
    label: 'Checkins',
  },
  {
    orderBy: 'status',
    label: 'Status',
  },
  {
    orderBy: 'profile.region',
    label: 'Region',
  },
];

type Props = {
  onLoadMore: () => void;
  users: Member[];
  total: number | null;
  loading?: boolean;
  onSort: (orderBy: MembershipSortBy, order: SortOrder) => void;
  order?: SortOrder | null;
  orderBy?: MembershipSortBy | null;
};

const MembersTable: FC<Props> = ({
  onLoadMore,
  users,
  total,
  loading,
  onSort,
  orderBy,
  order,
}) => {
  const navigate = useNavigate();

  const onSortHandler = (orderBy?: MembershipSortBy) => {
    if (!orderBy) return;
    const sortOrder = order === 'asc' ? 'desc' : 'asc';
    onSort(orderBy, sortOrder);
  };

  const onClickHandler = (
    e: React.MouseEvent<HTMLTableRowElement, MouseEvent>,
    userId: string
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(
        `${window.location.origin}/membership/profile/${userId}`,
        '_blank',
        'noreferrer'
      );
    } else {
      navigate(`/membership/profile/${userId}`);
    }
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !users?.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table sx={{ minWidth: 700 }} aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow className="table-head">
                {TABLE_HEAD.map((item) => (
                  <StyledTableCell
                    key={item.label}
                    align="left"
                    sortDirection={
                      orderBy && order && orderBy === item.orderBy ? order : false
                    }
                  >
                    {item.orderBy ? (
                      <TableSortLabel
                        active={orderBy === item.orderBy}
                        direction={
                          orderBy && order && orderBy === item.orderBy ? order : 'asc'
                        }
                        onClick={() => onSortHandler(item?.orderBy)}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {users.map((user) => (
                <StyledTableRow
                  className="table-row"
                  key={user._id}
                  onClick={(e) => onClickHandler(e, user._id)}
                >
                  <StyledTableCell align="left">{user.email}</StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.profile?.firstName || ''} {user?.profile?.lastName || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">{user?.subscription?.productName || '-'}</StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.createdAt ? moment(user?.createdAt).format('DD MMM YY') : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.subscription?.createdAt
                      ? moment(user?.subscription?.createdAt).format('DD MMM YY')
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.profile?.birthday
                      ? moment(user?.profile?.birthday).format('DD MMM')
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.profile?.countryOfResidence || '-'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.successfulReferrals || '0'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.experienceBookingsCountLast3Month || '0'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    {user?.checkinsCountLast3Month || '0'}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <StatusBadge status={user?.status} />
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <Box className={`status-badge new`}>
                      <Typography className="status-badge-label" variant="body2">
                        {user?.profile?.region || ''}
                      </Typography>
                    </Box>
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
          {total && users?.length < total ? (
            <Button onClick={onLoadMore} variant="contained" color="primary" fullWidth>
              Load more
            </Button>
          ) : null}
        </TableContainer>
      )}
    </Paper>
  );
};

export default MembersTable;
