import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CURRENCY } from "constans/currency";
import { Country } from 'models/cities';
import { Place } from 'models/place';
import { ToastParams } from 'types/app';
import { GetAllCountriesResponse } from 'types/countries';

export interface AppState {
  toast: ToastParams;
  currency: any;
  countries: Country[];
  places: Place[];
  loading: boolean;
}

const initialState: AppState = {
  toast: {
    open: false,
    message: '',
    severity: 'success',
  },
  currency: {
    [CURRENCY.USD]: {},
    [CURRENCY.EUR]: {},
    [CURRENCY.GBP]: {},
    [CURRENCY.MXN]: {},
  },
  countries: [],
  places: [],
  loading: false,
};

const appSlice = createSlice({
  name: 'app',
  initialState: initialState,
  reducers: {
    setToast: (state: AppState, action: PayloadAction<ToastParams>) => {
      state.toast = { ...initialState.toast, ...action.payload };
    },
    getCurrencies: (state: AppState, action: PayloadAction<any>) => {},
    getCurrenciesSuccess: (state: AppState, action: PayloadAction<any>) => {
      state.currency[action.payload.base] = action.payload;
    },
    getAllCountries: (state: AppState) => {},
    getAllCountriesSuccess: (state: AppState, action: PayloadAction<GetAllCountriesResponse>) => {
      state.countries = action.payload;
    },
    getAllPlaces: (state: AppState) => {
      state.loading = true;
    },
    getAllPlacesSuccess: (state: AppState, action: PayloadAction<Place[]>) => {
      state.places = action.payload;
      state.loading = false;
    },
    getAllPlacesFailed: (state: AppState, action: PayloadAction<any>) => {
      state.loading = false;
    },
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const appActions = appSlice.actions;

// Reducer
const appReducer = appSlice.reducer;
export default appReducer;
