import React from 'react';
import Stack from '@mui/material/Stack';
import { CustomSelect, SearchInput } from 'components';
import { CommunityManagmentFilters, Region, Status } from 'models/member';

export type StatusOption = Status | 'all';
export type RegionOption = Region | 'all';
export type CMOption = CommunityManagmentFilters | 'all';

type SelectOption = {
  value: string;
  label: string;
};

export const statusOptions: SelectOption[] = [
  { value: 'all', label: 'All' },
  { value: Status.NEW, label: 'New' },
  { value: Status.AWAITING_REVIEW, label: 'Awaiting review' },
  { value: Status.APPROVED, label: 'Approved' },
  { value: Status.REJECTED, label: 'Rejected' },
  { value: Status.ACTIVE, label: 'Active' },
  { value: Status.INACTIVE, label: 'Inactive' },
  { value: Status.PAST_DUE, label: 'Past due' },
  { value: Status.PAUSED, label: 'Paused' },
  { value: Status.GUEST, label: 'Guest' },
  { value: Status.WAITLIST, label: 'Waiting list' },
  { value: Status.FORM, label: 'Form' },
];

export const regionOptions: SelectOption[] = [
  { value: 'all', label: 'All' },
  { value: Region.USA, label: Region.USA },
  { value: Region.LATAM, label: Region.LATAM },
  { value: Region.UK, label: Region.UK },
  { value: Region.Portugal, label: Region.Portugal },
  { value: Region.EU, label: Region.EU },
  { value: Region.Other, label: Region.Other },
];

export const cmOptions: SelectOption[] = [
  { value: 'all', label: 'All' },
  {
    value: CommunityManagmentFilters.MOST_RECENT_APPLICAITONS,
    label: CommunityManagmentFilters.MOST_RECENT_APPLICAITONS,
  },
  {
    value: CommunityManagmentFilters.NEWEST_MEMBERS,
    label: CommunityManagmentFilters.NEWEST_MEMBERS,
  },
  {
    value: CommunityManagmentFilters.LOW_EVENT_PARTICIPATION,
    label: CommunityManagmentFilters.LOW_EVENT_PARTICIPATION,
  },
  {
    value: CommunityManagmentFilters.LOW_SPACE_UTILIZATION,
    label: CommunityManagmentFilters.LOW_SPACE_UTILIZATION,
  },
  {
    value: CommunityManagmentFilters.EXCEED_LISBON_CHECKINS,
    label: CommunityManagmentFilters.EXCEED_LISBON_CHECKINS,
  },
];

type Props = {
  onStatusChange: (status: StatusOption) => void;
  onRegionChange: (region: RegionOption) => void;
  onCmFilterChange: (cmFilter: CMOption) => void;
  onSearchChange: (value: string) => void;
  status: StatusOption;
  region: RegionOption;
  cmFilter: CMOption;
  searchValue: string;
};

const MembersFilters: React.FC<Props> = ({
  onStatusChange,
  onRegionChange,
  onSearchChange,
  onCmFilterChange,
  status,
  region,
  cmFilter,
  searchValue,
}) => {
  return (
    <Stack className="membership-filters" flexDirection={'row'} alignItems="center">
      <CustomSelect
        className="filters-item cm-filter"
        name="CMFilters"
        label="Community Managment Filters"
        options={cmOptions}
        value={cmFilter}
        onChange={(e) => onCmFilterChange(e.target.value)}
      />
      <CustomSelect
        className="filters-item"
        name="status"
        label="Status"
        options={statusOptions}
        value={status}
        onChange={(e) => onStatusChange(e.target.value)}
      />
      <CustomSelect
        className="filters-item"
        name="region"
        label="Region"
        options={regionOptions}
        value={region}
        onChange={(e) => onRegionChange(e.target.value)}
      />
      <SearchInput
        className="filters-item"
        name="search"
        onChange={(v) => onSearchChange(v)}
        value={searchValue}
      />
    </Stack>
  );
};

export default MembersFilters;
