import {
  GetAnnouncementsRequest,
  GetAnnouncementsResponse,
  CreateAnnouncementsRequest,
  CreateAnnouncementsResponse,
  EditAnnouncementRequest,
  DeleteAnnouncementsRequest,
  AnnouncementFilter,
} from 'types/announcements';
import axiosApi from './config';

const announcementsApi = {
  getAnnouncements({
    query = '',
    limit = '10',
    offset = 0,
    expirationDate,
    expirationDateFilter,
  }: GetAnnouncementsRequest): Promise<GetAnnouncementsResponse> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });

    let url = `/announcements?${params.toString()}&sort[expirationDate]=asc`;

    if (expirationDate && expirationDateFilter) {
      const filterOperator =
        expirationDateFilter === AnnouncementFilter.ACTIVE ? 'gte' : 'lt';
      url += `&filter[expirationDate]=$${filterOperator}:${expirationDate}`;
    }
    return axiosApi.get(url);
  },
  createAnnouncement(
    data: CreateAnnouncementsRequest
  ): Promise<CreateAnnouncementsResponse> {
    const url = `/announcements`;
    return axiosApi.post(url, data);
  },
  editAnnouncement(data: EditAnnouncementRequest): Promise<CreateAnnouncementsResponse> {
    const url = `/announcements/${data.id}`;
    return axiosApi.put(url, data.data);
  },
  deleteAnnouncement(data: DeleteAnnouncementsRequest): Promise<any> {
    const url = `/announcements/${data.id}`;
    return axiosApi.delete(url);
  },
};

export default announcementsApi;
