import { Member, MemberProfile, UserType } from './member';
import { Place } from './place';

export type CheckinDto = {
  _id: string;
  invitedBy: Member;
  place: Place;
  user: Member;
  checkinDate: Date;
  checkinSource: CheckinSource;
  experienceOnly: boolean;
};

export enum StayForLunchEnum {
  YES = 'yes',
  NO = 'no',
  MAYBE = 'maybe',
}

export enum CheckinSource {
  ADMIN = 'admin',
  CHECKIN_WEB_APP = 'checkin_web_app',
  MOBILE_APP = 'mobile_app',
}

export type Checkin = {
  invitedBy: Member;
  place: Place;
  user: Member;
  checkinDate: Date;
  checkinSource: CheckinSource;
  experienceOnly: boolean;
  _id: string;
};

export type Reservation = {
  place: Place;
  user: Member;
  date: Date;
  _id: string;
};
