import { PayloadAction } from '@reduxjs/toolkit';
import paymentsApi from 'api/payments';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { paymentsActions } from 'redux/reducers/payments';
import { PaymentsRequest, PaymentsResponse } from 'types/payments';
import { saveAs } from 'file-saver';

function* getPayments(data: PayloadAction<PaymentsRequest>) {
  try {
    const response: PaymentsResponse = yield call(paymentsApi.getPayments, data.payload);
    yield put(paymentsActions.getPaymentsSuccess({ ...response, offset: data.payload.offset}));
  } catch (error) {
    console.log(`Failed to fetch payments`, error);
    if (axios.isAxiosError(error)) {
      yield put(paymentsActions.getPaymentsFailed(error.response?.data.message));
    }
  }
};
function* getPaymentCsv(data: PayloadAction<PaymentsRequest>) {

  try {

    const response: string = yield call(paymentsApi.getPaymentsCsv, data.payload);

    var blob = new Blob([response], {type: "text/csv"});
    saveAs(blob, "Transactions.csv");

  } catch (error) {
    console.log(`Failed to fetch payments`, error);
    if (axios.isAxiosError(error)) {
    }
  }
};

export default function* paymentsSaga() {
  yield takeLatest(paymentsActions.getPayments.type, getPayments);
  yield takeLatest(paymentsActions.getPaymentCsv.type, getPaymentCsv);
};
