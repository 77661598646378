import { PayloadAction } from '@reduxjs/toolkit';
import matchesApi from 'api/matches';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { appActions } from 'redux/reducers/app';
import { matchesActions } from 'redux/reducers/matches';
import {
  CreateMatchRequest,
  CreateMatchResponse,
  DeleteMatchRequest,
  GetMatchByIdRequest,
  GetMatchByIdResponse,
  GetMatchesRequest,
  GetMatchesResponse,
  MatchReviewResponse,
  UpdateMatchRequest,
  UpdateMatchResponse,
} from 'types/matches';
import history from 'utils/route-history';

function* getMatches(data: PayloadAction<GetMatchesRequest>) {
  try {
    const response: GetMatchesResponse = yield call(matchesApi.getMatches, data.payload);
    yield put(matchesActions.getMatchesSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch matches`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.getMatchesFailed(error.response?.data.message));
    }
  }
}

function* getMatchById(data: PayloadAction<GetMatchByIdRequest>) {
  try {
    const response: GetMatchByIdResponse = yield call(
      matchesApi.getMatchById,
      data.payload
    );
    yield put(matchesActions.getMatchByIdSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch match`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.getMatchByIdFailed(error.response?.data.message));
    }
  }
}

function* createMatch(data: PayloadAction<CreateMatchRequest>) {
  try {
    const response: CreateMatchResponse = yield call(
      matchesApi.createMatch,
      data.payload
    );
    yield put(matchesActions.createMatchSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'Match was successfully created!',
        severity: 'success',
      })
    );
    history.push('/matches/all-matches');
  } catch (error) {
    console.log(`Failed to create match`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.createMatchFailed(error.response?.data.message));
      yield put(
        appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        })
      );
    }
  }
}

function* updateMatch(data: PayloadAction<UpdateMatchRequest>) {
  try {
    const response: UpdateMatchResponse = yield call(
      matchesApi.updateMatch,
      data.payload
    );
    yield put(matchesActions.updateMatchSuccess(response));
    yield put(
      appActions.setToast({
        open: true,
        message: 'Match was successfully updated!',
        severity: 'success',
      })
    );
    history.push('/matches/all-matches');
  } catch (error) {
    console.log(`Failed to update match`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.updateMatchFailed(error.response?.data.message));
      yield put(
        appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        })
      );
    }
  }
}

function* deleteMatch(data: PayloadAction<DeleteMatchRequest>) {
  try {
    yield call(matchesApi.deleteMatch, data.payload);
    yield put(matchesActions.deleteMatchSuccess(data.payload));
  } catch (error) {
    console.log(`Failed to delete match`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.deleteMatchFailed(error.response?.data.message));
    }
  }
}

function* publishAllMatches() {
  try {
    yield call(matchesApi.publishAllMatches);
    yield put(matchesActions.publishAllMatchesSuccess());
    yield put(
      appActions.setToast({
        open: true,
        message: 'All matches were successfully published!',
        severity: 'success',
      })
    );
  } catch (error) {
    console.log(`Failed to publish all matches`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.publishAllMatchesFailed(error.response?.data.message));
      yield put(
        appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        })
      );
    }
  }
}

function* fillTheQueueMatches() {
  try {
    yield call(matchesApi.loadQueueMatches);
    yield put(matchesActions.loadQueueMatchesSuccess());
    yield put(
      appActions.setToast({
        open: true,
        message: 'Matches were successfully loaded!',
        severity: 'success',
      })
    );
    yield call(getMatches, { payload: {} } as PayloadAction<GetMatchesRequest>);
  } catch (error) {
    console.log(`Failed to load matches`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.loadQueueMatchesFailed(error.response?.data.message));
      yield put(
        appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        })
      );
    }
  }
}

function* ignoreMatch(data: PayloadAction<DeleteMatchRequest>) {
  try {
    yield call(matchesApi.ignoreMatch, data.payload);
    yield put(matchesActions.ignoreMatchSuccess(data.payload));
  } catch (error) {
    console.log(`Failed to ignore match`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.ignoreMatchFailed(error.response?.data.message));
    }
  }
}

function* matchesReview() {
  try {
    const response: MatchReviewResponse = yield call(matchesApi.matchesReview);
    yield put(matchesActions.getMatchesReviewSuccess(response));
  } catch (error) {
    console.log(`Failed to get matches review`, error);
    if (axios.isAxiosError(error)) {
      yield put(matchesActions.getMatchesReviewFailed(error.response?.data.message));
    }
  }
}

export default function* matchesSaga() {
  yield takeLatest(matchesActions.getMatches.type, getMatches);
  yield takeLatest(matchesActions.getMatchById.type, getMatchById);
  yield takeLatest(matchesActions.createMatch.type, createMatch);
  yield takeLatest(matchesActions.updateMatch.type, updateMatch);
  yield takeLatest(matchesActions.deleteMatch.type, deleteMatch);
  yield takeLatest(matchesActions.publishAllMatches.type, publishAllMatches);
  yield takeLatest(matchesActions.ignoreMatch.type, ignoreMatch);
  yield takeLatest(matchesActions.loadQueueMatches.type, fillTheQueueMatches);
  yield takeLatest(matchesActions.getMatchesReview.type, matchesReview);
}
