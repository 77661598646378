import { Stack, Typography } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page, WhiteBox } from 'components';
import { FC, useEffect, useState } from 'react';
import { matchesActions } from 'redux/reducers/matches';
import { allMatchesSelector, matchesLoadingSelector } from 'redux/selectors/matches';
import MatchesTable from './matchesTable/matchesTable';
import MatchesFilters, { StatusOption } from './matchesTable/matchesFilters';
import { useDidUpdateEffect } from 'utils/hooks';
import { MatchStatus } from 'models/match';
import { useNavigate } from 'react-router-dom';

const Matches: FC = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const matches = useAppSelector(allMatchesSelector);
  const loading = useAppSelector(matchesLoadingSelector);
  const [selectedStatusOption, setSelectedStatusOption] = useState<StatusOption>('all');

  const total = matches.length;

  useEffect(() => {
    dispatch(matchesActions.getMatches({}));
  }, [dispatch]);

  useDidUpdateEffect(() => {
    dispatch(
      matchesActions.getMatches({
        status:
          selectedStatusOption === 'all' ? ('' as MatchStatus) : selectedStatusOption,
      })
    );
  }, [selectedStatusOption]);

  return (
    <Page title="Tags">
      <Stack direction="row" marginTop={2}>
        <WhiteBox width="100%" height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            borderBottom="1px solid rgb(247, 247, 255)"
            paddingBottom="20px"
          >
            <Typography variant="h2">Total: {total}</Typography>
            <MatchesFilters
              status={selectedStatusOption}
              onStatusChange={setSelectedStatusOption}
              onPublishClick={() => {
                dispatch(matchesActions.publishAllMatches());
              }}
              onLoadQueueClick={() => {
                dispatch(matchesActions.loadQueueMatches());
              }}
            />
          </Stack>
          <MatchesTable
            total={total || 0}
            loading={loading}
            matches={matches || []}
            onDelete={(id: string) => dispatch(matchesActions.deleteMatch({ id }))}
            onEdit={(id: string) => navigate(`/matches/edit/${id}`)}
            onIgnore={(id: string) => dispatch(matchesActions.ignoreMatch({ id }))}
          />
        </WhiteBox>
      </Stack>
    </Page>
  );
};

export default Matches;
