import { FC } from 'react';
import Table from '@mui/material/Table';
import Button from '@mui/material/Button';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import moment from 'moment';
import TableSortLabel from '@mui/material/TableSortLabel';
import { CustomSelect, ReactLoader, StyledTableCell, StyledTableRow } from 'components';
import { Checkin, CheckinSource } from 'models/checkin';
import { SortOrder } from 'types/common';
import { useNavigate } from 'react-router-dom';
import { checkinActions } from 'redux/reducers/checkIn';
import { useAppDispatch } from 'app/hooks';

type TableHeadCell = {
  label: string;
  orderBy?: string;
};

const renderTableHead = (): TableHeadCell[] => {
  const cells = [
    {
      label: 'User',
      orderBy: 'user.email',
    },
    {
      label: 'Invited By',
      orderBy: 'invitedBy.email',
    },
    {
      label: 'Place',
      orderBy: 'place.name',
    },
    {
      label: 'Subscriptions',
      orderBy: 'user.subscription.productName',
    },
    {
      label: 'Date/Time',
      orderBy: 'createdAt',
    },
    {
      label: 'Type',
    },
  ];

  return cells;
};

type Props = {
  checkins: Checkin[];
  loading?: boolean;
  total: number | null;
  order: SortOrder | null;
  orderBy: string | null;
  loadMore: () => void;
  onSort: (orderBy: string, order: SortOrder) => void;
};

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

const CheckinsTable: FC<Props> = ({
  loading,
  checkins,
  loadMore,
  total,
  orderBy,
  order,
  onSort,
}) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();

  const onProfileOpen = (
    e: React.MouseEvent<HTMLTableCellElement, MouseEvent>,
    userId: string
  ) => {
    if (e.metaKey || e.ctrlKey) {
      window.open(
        `${window.location.origin}/membership/profile/${userId}`,
        '_blank',
        'noreferrer'
      );
    } else {
      navigate(`/membership/profile/${userId}`);
    }
  };

  const onSortHandler = (orderBy?: string) => {
    if (!orderBy) return;
    const sortOrder = order === 'asc' ? 'desc' : 'asc';
    onSort(orderBy, sortOrder);
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !checkins.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow>
                {renderTableHead().map((item) => (
                  <StyledTableCell key={item.label} align="left">
                    <TableSortLabel
                      // active={orderBy === item.orderBy}
                      direction={
                        orderBy && order && orderBy === item.orderBy ? order : 'asc'
                      }
                      onClick={() => onSortHandler(item?.orderBy)}
                      disabled
                    >
                      {item.label}
                    </TableSortLabel>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {checkins.map((checkin) => (
                <StyledTableRow key={checkin._id}>
                  <StyledTableCell
                    sx={mainTextStyles}
                    align="left"
                    onClick={(e) => onProfileOpen(e, checkin.user?._id)}
                    className="table-cell-hover"
                  >
                    {`${checkin.user?.profile?.firstName} ${checkin.user?.profile?.lastName}`}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={mainTextStyles}
                    align="left"
                    onClick={(e) => onProfileOpen(e, checkin.invitedBy?._id)}
                    className={checkin.invitedBy ? 'table-cell-hover' : ''}
                  >
                    {checkin.invitedBy
                      ? `${checkin.invitedBy?.profile?.firstName} ${checkin.invitedBy?.profile?.lastName}`
                      : '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {checkin.place ? checkin.place?.name : '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {checkin.user?.subscription?.productName || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {checkin?.place
                      ? checkin.checkinSource === CheckinSource.ADMIN
                        ? moment(checkin.checkinDate).format('DD-MM-YYYY')
                        : moment(checkin.checkinDate)
                            .tz(checkin?.place?.city?.timeZone)
                            .format('DD-MM-YYYY')
                      : moment(checkin.checkinDate).format('DD-MM-YYYY')}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    <CustomSelect
                      className="experience-item"
                      name="experienceOnly"
                      label=""
                      options={[
                        { label: 'Event Only', value: 'true' },
                        { label: 'Whole Day', value: 'false' },
                      ]}
                      value={checkin.experienceOnly?.toString()}
                      onChange={() => {
                        dispatch(
                          checkinActions.editCheckIn({
                            id: checkin._id,
                            data: { experienceOnly: !checkin.experienceOnly },
                          })
                        );
                      }}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {total && checkins?.length < total ? (
        <Button onClick={loadMore} variant="contained" color="primary" fullWidth>
          Load more
        </Button>
      ) : null}
    </Paper>
  );
};

export default CheckinsTable;
