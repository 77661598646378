import {
  CreateTagsRequest,
  CreateTagsResponse,
  DeleteTagRequest,
  EditTagRequest,
  GetTagsRequest,
  GetTagsResponse,
} from 'types/tag';
import axiosApi from './config';

const tagsApi = {
  getTags({
    query = '',
    limit = '10',
    offset = 0,
    category = undefined,
  }: GetTagsRequest): Promise<GetTagsResponse> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });

    let url = `/tags?${params.toString()}${
      category ? `&filter[category]=${category}` : ''
    }&sort[value]=asc`;
    return axiosApi.get(url);
  },
  createTag(data: CreateTagsRequest): Promise<CreateTagsResponse> {
    const url = `/tags`;
    return axiosApi.post(url, data);
  },
  editTag(data: EditTagRequest): Promise<CreateTagsResponse> {
    const url = `/tags/${data.id}`;
    return axiosApi.put(url, data.data);
  },
  deleteTag(data: DeleteTagRequest): Promise<any> {
    const url = `/tags/${data.id}`;
    return axiosApi.delete(url);
  },
};

export default tagsApi;
