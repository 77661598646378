import { FC } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import Button from '@mui/material/Button';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { ContextMenu, ReactLoader, StyledTableCell, StyledTableRow } from 'components';
import { AnnouncementDto } from 'models/announcement';
import moment from 'moment';

type TableHeadCell = {
  label: string;
};

const TABLE_HEAD: TableHeadCell[] = [
  {
    label: 'City',
  },
  {
    label: 'Content',
  },
  {
    label: 'Expiration Date',
  },
  {
    label: 'Actions',
  },
];

type Props = {
  loading?: boolean;
  announcements: AnnouncementDto[];
  total: number;
  loadMore: () => void;
  onDelete: (id: string) => void;
  handleEditAnnouncement: (announcement: AnnouncementDto | null) => void;
};

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

const AnnouncementsTable: FC<Props> = ({
  loading,
  announcements,
  handleEditAnnouncement,
  loadMore,
  onDelete,
  total
}) => {

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !announcements.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow>
                {TABLE_HEAD.map((item) => (
                  <StyledTableCell key={item.label} align="left">
                    <Typography sx={{ ...mainTextStyles, fontWeight: 700 }}>
                      {item.label}
                    </Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {announcements.map((announcement) => (
                <StyledTableRow key={announcement._id}>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {announcement.city?.name || 'Global'}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    <Typography variant='body2' sx={{
                        textOverflow: 'ellipsis',
                        overflow: 'hidden', 
                        maxWidth: '200px',
                        whiteSpace: 'nowrap',
                        fontSize: '14px',
                    }}>{announcement.content}</Typography>
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {moment(announcement.expirationDate).utc(false).format('DD-MM-YYYY')}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ContextMenu
                      options={[
                        {
                          label: 'Edit announcement',
                          onClick: () => handleEditAnnouncement(announcement),
                        },
                        { label: 'Delete announcement', onClick: () => onDelete(announcement._id) },
                      ]}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {total && announcements?.length < total ? (
        <Button onClick={loadMore} variant="contained" color="primary" fullWidth>
          Load more
        </Button>
      ) : null}
    </Paper>
  );
};

export default AnnouncementsTable;
