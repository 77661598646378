import { PayloadAction } from '@reduxjs/toolkit';
import checkinApi from 'api/checkin';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { appActions } from 'redux/reducers/app';
import { checkinActions } from 'redux/reducers/checkIn';
import {
  GetCheckInsResponse,
  GetCheckInsRequest,
  UpdateCheckInRequest,
  CreateCheckInRequest,
  GetReservationsResponse,
} from 'types/check-in';
import history from 'utils/route-history';

function* getCheckins(data: PayloadAction<GetCheckInsRequest>) {
  try {
    const response: GetCheckInsResponse = yield call(
      checkinApi.getCheckIns,
      data.payload
    );
    yield put(checkinActions.getCheckInsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch check-ins`, error);
    if (axios.isAxiosError(error)) {
      yield put(checkinActions.getCheckInsFailed(error.response?.data.message));
    }
  }
}

function* getReservations(data: PayloadAction<GetCheckInsRequest>) {
  try {
    const response: GetReservationsResponse = yield call(
      checkinApi.getReservations,
      data.payload
    );
    yield put(checkinActions.getReservationsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch reservations`, error);
    if (axios.isAxiosError(error)) {
      yield put(checkinActions.getReservationsFailed(error.response?.data.message));
    }
  }
}

function* editCheckin(data: PayloadAction<UpdateCheckInRequest>) {
  try {
    yield call(checkinApi.editCheckin, data.payload);
    yield put(checkinActions.editCheckInSuccess());
  } catch (error) {
    console.log(`Failed to edit check-in`, error);
    if (axios.isAxiosError(error)) {
      yield put(checkinActions.editCheckInFailed(error.response?.data.message));
    }
  }
}

function* createCheckin(data: PayloadAction<CreateCheckInRequest>) {
  try {
    yield call(checkinApi.createCheckin, data.payload);
    yield put(checkinActions.createCheckInSuccess());
    history.push('/checkins');
  } catch (error) {
    console.log(`Failed to create check-in`, error);
    if (axios.isAxiosError(error)) {
      yield put(checkinActions.createCheckInFailed(error.response?.data.message));
      yield put(
        appActions.setToast({
          open: true,
          message: error.response?.data.message,
          severity: 'error',
        })
      );
    }
  }
}

export default function* checkinsSaga() {
  yield takeLatest(checkinActions.getCheckIns.type, getCheckins);
  yield takeLatest(checkinActions.editCheckIn.type, editCheckin);
  yield takeLatest(checkinActions.createCheckIn.type, createCheckin);
  yield takeLatest(checkinActions.getReservations.type, getReservations);
}
