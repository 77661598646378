import { useState } from 'react';
import Stack from '@mui/material/Stack';
import { Page, WhiteBox } from 'components';
import RoomsFilters from './roomsFilters';
import RoomsTable from './roomsTable';
import { ROOMS_PLACE_FILTER, ROOMS_DATE_FILTER } from 'utils/storageKeys';
import moment from 'moment';

const Rooms: React.FC = () => {
  const placeStorage = sessionStorage.getItem(ROOMS_PLACE_FILTER);
  const dateStorage = sessionStorage.getItem(ROOMS_DATE_FILTER);
  const [selectedDate, setSelectedDate] = useState(
    dateStorage
      ? moment(JSON.parse(dateStorage)).toDate()
      : moment().startOf('day').toDate()
  );
  const [selectedPlaceId, setSelectedPlaceId] = useState(placeStorage || '');

  const onDateChange = (date: Date) => {
    setSelectedDate(date);
    sessionStorage.setItem(ROOMS_DATE_FILTER, JSON.stringify(date));
  };

  const onPlaceChange = (placeId: string) => {
    setSelectedPlaceId(placeId);
    sessionStorage.setItem(ROOMS_PLACE_FILTER, placeId);
  };

  return (
    <Page title="Rooms">
      <WhiteBox>
        <Stack
          direction="row"
          justifyContent="space-between"
          borderBottom="1px solid rgb(247, 247, 255)"
          paddingBottom="20px"
        >
          <RoomsFilters
            onPlaceChange={onPlaceChange}
            place={selectedPlaceId}
            onDateChange={onDateChange}
            selectedDate={selectedDate}
          />
        </Stack>
        <RoomsTable selectedPlaceId={selectedPlaceId} />
      </WhiteBox>
    </Page>
  );
};

export default Rooms;
