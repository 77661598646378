import React, { useState, useCallback, useEffect } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import AnnouncementsFilters from './announcementsFilters';
import { Page, WhiteBox } from 'components';
import {
  allAnnouncementsSelector,
  announcementsLoadingSelector,
  announcementsSearchValueSelector,
  announcementsTotalSelector,
  announcementsExpirationDateFilterSelector
} from 'redux/selectors/announcements';
import { AnnouncementDto } from 'models/announcement';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { announcementsActions } from 'redux/reducers/announcements';
import AnnouncementsTable from './announcementsTable';
import NewAnnouncementForm from './newAnnouncementForm';
import { useDidUpdateEffect } from 'utils/hooks';
import moment from 'moment';

const Announcements: React.FC = () => {
  const dispatch = useAppDispatch();
  const announcements = useAppSelector(allAnnouncementsSelector);
  const searchValue = useAppSelector(announcementsSearchValueSelector);
  const expirationDateFilter = useAppSelector(announcementsExpirationDateFilterSelector);

  const loading = useAppSelector(announcementsLoadingSelector);
  const total = useAppSelector(announcementsTotalSelector);

  const [editableAnnouncement, setEditableAnnouncement] =
    useState<AnnouncementDto | null>(null);

  const [offset, setOffset] = useState(0);

  const onSearchChange = useCallback((searchText: string) => {
    dispatch(announcementsActions.setSearchValue(searchText));
  }, []);

  const handleEditAnnouncement = useCallback(
    (announcement: typeof editableAnnouncement) => {
      setEditableAnnouncement(announcement);
    },
    []
  );

  useEffect(() => {
    setOffset(0);
    dispatch(
      announcementsActions.getAnnouncements({
        expirationDateFilter: expirationDateFilter,
        expirationDate: moment().format('YYYY-MM-DDT00:00:00.000[Z]'),
      })
    );

    return () => {
      dispatch(announcementsActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(
      announcementsActions.getAnnouncements({
        query: searchValue,
        offset: 0,
        expirationDateFilter: expirationDateFilter,
        expirationDate: moment().format('YYYY-MM-DDT00:00:00.000[Z]'),
      })
    );
  }, [searchValue, expirationDateFilter]);

  const loadMore = () => {
    dispatch(
      announcementsActions.getAnnouncements({
        query: searchValue,
        offset: offset + 10,
        expirationDate: moment().format('YYYY-MM-DDT00:00:00.000[Z]'),
        expirationDateFilter: expirationDateFilter,
      })
    );
    setOffset(offset + 10);
  };

  useDidUpdateEffect(() => {
    dispatch(announcementsActions.updateOffset({ offset: offset }));
  }, [offset]);

  return (
    <Page title="Announcements">
      <Stack direction="row" marginTop={2}>
        <WhiteBox width="66%" height="fit-content">
          <Stack
            direction="row"
            justifyContent="space-between"
            borderBottom="1px solid rgb(247, 247, 255)"
            paddingBottom="20px"
          >
            <Typography variant="h2">Total: {total}</Typography>
            <AnnouncementsFilters
              expirationDateSelectValue={expirationDateFilter}
              onExpirationDateChange={(val) => dispatch(announcementsActions.setExpirationDateFilter(val))}
              onSearchChange={onSearchChange}
              searchValue={searchValue}
            />
          </Stack>
          <AnnouncementsTable
            total={total || 0}
            loading={loading}
            announcements={announcements || []}
            loadMore={loadMore}
            handleEditAnnouncement={handleEditAnnouncement}
            onDelete={(id: string) =>
              dispatch(announcementsActions.deleteAnnouncement({ id }))
            }
          />
        </WhiteBox>
        <WhiteBox width="33%" margin="0 0 0 16px" height="fit-content">
          <NewAnnouncementForm
            editableAnnouncement={editableAnnouncement}
            handleEditAnnouncement={handleEditAnnouncement}
          />
        </WhiteBox>
      </Stack>
    </Page>
  );
};

export default Announcements;
