import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { ReactLoader, StyledTableCell, StyledTableRow } from 'components';
import { MatchReview } from 'models/match';
import { FC } from 'react';

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

type Props = {
  loading?: boolean;
  matchReview: MatchReview[];
};

const MatchesReviewTable: FC<Props> = ({ loading, matchReview }) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {!loading ? (
        matchReview?.length ? (
          <TableContainer>
            <Table aria-label="customized table" stickyHeader>
              <TableHead>
                <StyledTableRow>
                  <StyledTableCell align="left">
                    <TableSortLabel disabled>Email</TableSortLabel>
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <TableSortLabel disabled>Amount of matches remaining</TableSortLabel>
                  </StyledTableCell>
                </StyledTableRow>
              </TableHead>
              <TableBody>
                {matchReview.map((match) => (
                  <StyledTableRow key={match.email} className="table-row">
                    <StyledTableCell sx={mainTextStyles} align="left">
                      {match.email}
                    </StyledTableCell>
                    <StyledTableCell sx={mainTextStyles} align="left">
                      {match.amountOfUsersToMatch}
                    </StyledTableCell>
                  </StyledTableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : null
      ) : (
        <ReactLoader />
      )}
    </Paper>
  );
};

export default MatchesReviewTable;
