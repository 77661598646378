import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { get } from 'lodash';
import { CheckinDto } from 'models/checkin';
import { Match } from 'models/match';
import { Member, SuggestedMemberDto } from 'models/member';
import { ReferralDto } from 'models/referral';
import {
  GetUserByIdRequest,
  GetUserByIdResponse,
  GetUsersRequest,
  GetUsersResponse,
  GetUserCheckinsResponse,
  GetUserReferralsResponse,
  UpdateUserProfileRequest,
  UpdateUserStatusRequest,
  GetUserSuggestedMembersResponse,
  SetUserSuggestionsRequest,
  GetUserMatchesResponse,
} from 'types/users';

export interface UsersState {
  loading: boolean;
  users: Member[];
  totalUsers: number | null;
  error: any;
  selectedUser: Member | null;
  offset: number;
  selectedUserReferrals: ReferralDto[];
  selectedUserCheckins: CheckinDto[];
  selectedUserSuggestedMembers: SuggestedMemberDto[];
  selectedUserMatches: Match[];
  locations: Member[];
  locationsMembers: {
    total: number;
    data: Member[];
  };
  locationsCount: any;
  locationOffset: number;
}

const initialState: UsersState = {
  loading: false,
  users: [],
  error: null,
  selectedUser: null,
  totalUsers: null,
  selectedUserReferrals: [],
  selectedUserCheckins: [],
  selectedUserSuggestedMembers: [],
  selectedUserMatches: [],
  offset: 0,
  locations: [],
  locationsCount: [],
  locationsMembers: {
    total: 0,
    data: [],
  },
  locationOffset: 0,
};

const usersSlice = createSlice({
  name: 'users',
  initialState: initialState,
  reducers: {
    getUsers(state: UsersState, action: PayloadAction<GetUsersRequest>) {
      state.loading = true;
    },
    getUsersSuccess(state: UsersState, action: PayloadAction<GetUsersResponse>) {
      state.loading = false;
      if (state.offset > 0) {
        state.users = [...state.users, ...action.payload.data];
      } else {
        state.users = action.payload.data;
      }
      state.totalUsers = action.payload.total;
    },
    getUsersFailed(state: UsersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getUserById(state: UsersState, action: PayloadAction<GetUserByIdRequest>) {
      state.loading = true;
    },
    getUserByIdSuccess(state: UsersState, action: PayloadAction<GetUserByIdResponse>) {
      state.loading = false;
      state.selectedUser = action.payload;
    },
    getUserByIdFailed(state: UsersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getUserReferrals(state: UsersState, action: PayloadAction<GetUserByIdRequest>) {
      state.loading = true;
    },
    getUserReferralsSuccess(
      state: UsersState,
      action: PayloadAction<GetUserReferralsResponse>
    ) {
      state.loading = false;
      state.selectedUserReferrals = action.payload;
    },
    getUserReferralsFailed(state: UsersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getUserCheckins(state: UsersState, action: PayloadAction<GetUserByIdRequest>) {
      state.loading = true;
    },
    getUserCheckinsSuccess(
      state: UsersState,
      action: PayloadAction<GetUserCheckinsResponse>
    ) {
      state.loading = false;
      state.selectedUserCheckins = action.payload;
    },
    getUserCheckinsFailed(state: UsersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    getUserMatches(state: UsersState, action: PayloadAction<GetUserByIdRequest>) {
      state.loading = true;
    },
    getUserMatchesSuccess(
      state: UsersState,
      action: PayloadAction<GetUserMatchesResponse>
    ) {
      state.loading = false;
      state.selectedUserMatches = action.payload;
    },
    getUserMatchesFailed(state: UsersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    setUserSuggestions(
      state: UsersState,
      action: PayloadAction<SetUserSuggestionsRequest>
    ) {
      state.loading = false;
      state.error = action.payload;
    },
    getUserSuggestedMembers(
      state: UsersState,
      action: PayloadAction<GetUserByIdRequest>
    ) {
      state.loading = true;
    },
    getUserSuggestedMembersSuccess(
      state: UsersState,
      action: PayloadAction<GetUserSuggestedMembersResponse>
    ) {
      state.loading = false;
      state.selectedUserSuggestedMembers = action.payload;
    },
    getUserSuggestedMembersFailed(state: UsersState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOffset(state: UsersState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    clearSelectedUser: (state: UsersState) => {
      state.selectedUser = null;
      state.selectedUserReferrals = [];
      state.selectedUserCheckins = [];
    },

    updateUserStatus(state: UsersState, action: PayloadAction<UpdateUserStatusRequest>) {
      state.loading = true;
    },
    updateUserStatusSuccess(
      state: UsersState,
      action: PayloadAction<GetUserByIdResponse>
    ) {
      state.loading = false;
      state.users = state.users.map((user, i) =>
        user._id === action.payload._id ? { ...action.payload } : user
      );
    },
    updateUserStatusError(state: UsersState) {
      state.loading = false;
    },

    updateUserProfile(
      state: UsersState,
      action: PayloadAction<UpdateUserProfileRequest>
    ) {
      state.loading = true;
    },
    updateUserProfileSuccess(
      state: UsersState,
      action: PayloadAction<GetUserByIdResponse>
    ) {
      state.loading = false;
      state.selectedUser = action.payload;
    },
    updateUserProfileError(state: UsersState) {
      state.loading = false;
    },
    getLocations(state: UsersState, action: PayloadAction<any>) {},
    getLocationsCount() {},
    getLocationsMembers(state: UsersState, action: PayloadAction<any>) {
      state.locationOffset = action.payload.offset;
    },
    getLocationsMembersSuccess(state: UsersState, action: PayloadAction<any>) {
      if (state.locationOffset < action.payload.data.length) {
        state.locationsMembers = action.payload;
      } else {
        state.locationsMembers = {
          total: action.payload.total,
          data: [...state.locationsMembers.data, ...action.payload.data],
        };
      }
    },
    getLocationsCountSuccess(state: UsersState, action: PayloadAction<any>) {
      state.locationsCount = action.payload;
    },
    getLocationsSuccess(state: UsersState, action: PayloadAction<any>) {
      state.locations = action.payload;
    },
    getLocationsMembersCsv: (state: UsersState, action: PayloadAction<any>) => {},
    reset: (state) => {
      return initialState;
    },
  },
});

// Actions
export const usersActions = usersSlice.actions;

// Reducer
const usesrsReducer = usersSlice.reducer;
export default usesrsReducer;
