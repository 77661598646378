import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import {
  ReactLoader,
  StyledTableCell,
  StyledTableRow
} from 'components';
import { FC } from 'react';


const TABLE_HEAD = [
  {
    orderBy: 'city',
    label: 'City',
  },
  {
    orderBy: 'count',
    label: 'Count',
  },
];

type Props = {
  data: any;
  loading?: boolean;
  onClickRow: (index: number, city: any) => void;
};

const HeatMapCitiesTable: FC<Props> = ({
  data,
  loading,
  onClickRow,
}) => {

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !data?.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table sx={{ minWidth: 300 }} aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow className="table-head">
                {TABLE_HEAD.map((item) => (
                  <StyledTableCell
                    key={item.label}
                    align="left"
                  >
                    {item.label}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {data.map((city: any, index: number) => (
                <StyledTableRow
                  className="table-row"
                  key={city._id}
                  onClick={(e) => onClickRow(index, city)}
                >
                  <StyledTableCell align="left">{city.city}</StyledTableCell>
                  <StyledTableCell align="left">
                    {city.count}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
    </Paper>
  );
};

export default HeatMapCitiesTable;
