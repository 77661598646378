import { createSelector } from 'reselect';
import { RootState } from 'app/store';
import { TagsState } from 'redux/reducers/tags';

export const tagsSelector = (state: RootState): TagsState => state.tags;

export const allTagsSelector = createSelector(tagsSelector, (tags) => tags.tags);

export const tagsLoadingSelector = createSelector(tagsSelector, (tags) => tags.loading);

export const tagsErrorSelector = createSelector(tagsSelector, (tags) => tags.error);

export const tagsTotalSelector = createSelector(tagsSelector, (tags) => tags.total);

export const tagsSearchValueSelector = createSelector(
  tagsSelector,
  (tags) => tags.searchValue
);

export const tagsCategoryFilterSelector = createSelector(
  tagsSelector,
  (tags) => tags.categoryFilter
);
