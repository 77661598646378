import { FC, useEffect, useState } from 'react';
import Stack from '@mui/material/Stack';
import { Page, WhiteBox } from 'components';
import { useParams, useNavigate } from 'react-router-dom';
import { hotspotByIdSelector } from 'redux/selectors/hotspots';
import { useAppSelector, useAppDispatch } from 'app/hooks';
import { hotspotsActions } from 'redux/reducers/hotspots';
import ConfirmationModal from 'components/confirmModal';
import Button from '@mui/material/Button';
import HotspotPreview from './hotspotPreview';
import HotspotDetailsInfo from './hotspotInfo';

type QueryParams = {
  hotspotId: string;
};

const HotspotDetails: FC = () => {
  const { hotspotId } = useParams<QueryParams>();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const hotspot = useAppSelector(hotspotByIdSelector);

  const [isModalOpen, setModalOpen] = useState(false);

  useEffect(() => {
    if (hotspotId) {
      dispatch(hotspotsActions.getHotspotById(hotspotId));
    }

    return () => {
      dispatch(hotspotsActions.clearSelectedHotspot());
    };
  }, [hotspotId, dispatch]);

  const handleModalOpen = (value: boolean) => {
    setModalOpen(value);
  };

  const handleDelete = () => {
    if (hotspot?._id) {
      dispatch(hotspotsActions.deleteHotspot(hotspot._id));
    }
    handleModalOpen(false);
  };

  return (
    <Page onBackClick={() => navigate(-1)}>
      <Stack flexDirection="row" width="100%">
        <HotspotPreview hotspot={hotspot} />
        <Stack width="50%" flexDirection={'column'} marginLeft={3}>
        <WhiteBox>
          <HotspotDetailsInfo hotspot={hotspot} />
          </WhiteBox>
          <WhiteBox sx={{ marginTop: 3 }}>
            <Button
              sx={{ marginBottom: 2 }}
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => navigate(`/hotspots/edit/${hotspot?._id}`)}
            >
              EDIT
            </Button>
            <Button
              onClick={() => handleModalOpen(true)}
              variant="outlined"
              color="error"
              fullWidth
            >
              DELETE
            </Button>
          </WhiteBox>
        </Stack>
      </Stack>
      <ConfirmationModal
        isModalOpen={isModalOpen}
        setIsModalOpen={handleModalOpen}
        onSubmit={handleDelete}
        okButtonText="Delete"
        message="Are you sure you want to delete this hotspot?"
      />
    </Page>
  );
};

export default HotspotDetails;
