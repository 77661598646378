export const SelectArrow = () => {
  return (
    <svg width="60" height="20" viewBox="0 0 20 20" fill="none">
      <path
        d="M15.5893 6.83914C15.433 6.68292 15.2211 6.59515 15.0001 6.59515C14.7791 6.59515 14.5672 6.68292 14.4109 6.83914L10.5893 10.6608C10.4308 10.8129 10.2197 10.8978 10.0001 10.8978C9.78048 10.8978 9.56937 10.8129 9.41093 10.6608L5.58926 6.83914C5.43209 6.68734 5.22159 6.60335 5.00309 6.60525C4.7846 6.60715 4.57559 6.69479 4.42108 6.84929C4.26657 7.0038 4.17893 7.21281 4.17703 7.43131C4.17513 7.64981 4.25913 7.86031 4.41093 8.01748L8.23176 11.8391C8.46391 12.0713 8.73953 12.2555 9.04287 12.3812C9.34621 12.5068 9.67134 12.5715 9.99968 12.5715C10.328 12.5715 10.6531 12.5068 10.9565 12.3812C11.2598 12.2555 11.5354 12.0713 11.7676 11.8391L15.5893 8.01748C15.7455 7.8612 15.8333 7.64928 15.8333 7.42831C15.8333 7.20734 15.7455 6.99542 15.5893 6.83914Z"
        fill="#1E1E70"
      />
    </svg>
  );
};

export const CalendarIcon = () => (
  <svg width="20" height="20" viewBox="0 0 20 20" fill="none">
    <g clipPath="url(#clip0_969_303)">
      <path
        d="M15.8333 1.66667H15V0.833333C15 0.61232 14.9122 0.400358 14.7559 0.244078C14.5996 0.0877974 14.3877 0 14.1667 0C13.9457 0 13.7337 0.0877974 13.5774 0.244078C13.4211 0.400358 13.3333 0.61232 13.3333 0.833333V1.66667H6.66667V0.833333C6.66667 0.61232 6.57887 0.400358 6.42259 0.244078C6.26631 0.0877974 6.05435 0 5.83333 0C5.61232 0 5.40036 0.0877974 5.24408 0.244078C5.0878 0.400358 5 0.61232 5 0.833333V1.66667H4.16667C3.062 1.66799 2.00296 2.1074 1.22185 2.88852C0.440735 3.66963 0.00132321 4.72867 0 5.83333L0 15.8333C0.00132321 16.938 0.440735 17.997 1.22185 18.7782C2.00296 19.5593 3.062 19.9987 4.16667 20H15.8333C16.938 19.9987 17.997 19.5593 18.7782 18.7782C19.5593 17.997 19.9987 16.938 20 15.8333V5.83333C19.9987 4.72867 19.5593 3.66963 18.7782 2.88852C17.997 2.1074 16.938 1.66799 15.8333 1.66667ZM1.66667 5.83333C1.66667 5.17029 1.93006 4.53441 2.3989 4.06557C2.86774 3.59673 3.50363 3.33333 4.16667 3.33333H15.8333C16.4964 3.33333 17.1323 3.59673 17.6011 4.06557C18.0699 4.53441 18.3333 5.17029 18.3333 5.83333V6.66667H1.66667V5.83333ZM15.8333 18.3333H4.16667C3.50363 18.3333 2.86774 18.0699 2.3989 17.6011C1.93006 17.1323 1.66667 16.4964 1.66667 15.8333V8.33333H18.3333V15.8333C18.3333 16.4964 18.0699 17.1323 17.6011 17.6011C17.1323 18.0699 16.4964 18.3333 15.8333 18.3333Z"
        fill="#1E1E70"
      />
      <path
        d="M10 13.75C10.6904 13.75 11.25 13.1904 11.25 12.5C11.25 11.8096 10.6904 11.25 10 11.25C9.30964 11.25 8.75 11.8096 8.75 12.5C8.75 13.1904 9.30964 13.75 10 13.75Z"
        fill="#1E1E70"
      />
      <path
        d="M5.83325 13.75C6.52361 13.75 7.08325 13.1904 7.08325 12.5C7.08325 11.8096 6.52361 11.25 5.83325 11.25C5.1429 11.25 4.58325 11.8096 4.58325 12.5C4.58325 13.1904 5.1429 13.75 5.83325 13.75Z"
        fill="#1E1E70"
      />
      <path
        d="M14.1667 13.75C14.8571 13.75 15.4167 13.1904 15.4167 12.5C15.4167 11.8096 14.8571 11.25 14.1667 11.25C13.4764 11.25 12.9167 11.8096 12.9167 12.5C12.9167 13.1904 13.4764 13.75 14.1667 13.75Z"
        fill="#1E1E70"
      />
    </g>
    <defs>
      <clipPath id="clip0_969_303">
        <rect width="20" height="20" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export const ClockIcon = () => (
  <svg
    width="16"
    height="16"
    viewBox="0 0 16 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8 2C4.6875 2 2 4.6875 2 8C2 11.3125 4.6875 14 8 14C11.3125 14 14 11.3125 14 8C14 4.6875 11.3125 2 8 2Z"
      stroke="#1E1E70"
      stroke-miterlimit="10"
    />
    <path
      d="M8 4V8.5H11"
      stroke="#1E1E70"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
);

export const GrowthIcon = () => (
  <svg
    viewBox="0 0 1024 1024"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M935.253333 282.453333a42.666667 42.666667 0 0 0-23.04-23.04A42.666667 42.666667 0 0 0 896 256h-213.333333a42.666667 42.666667 0 0 0 0 85.333333h110.506666L554.666667 579.84l-140.373334-140.8a42.666667 42.666667 0 0 0-60.586666 0l-256 256a42.666667 42.666667 0 0 0 0 60.586667 42.666667 42.666667 0 0 0 60.586666 0L384 529.493333l140.373333 140.8a42.666667 42.666667 0 0 0 60.586667 0L853.333333 401.493333V512a42.666667 42.666667 0 0 0 85.333334 0V298.666667a42.666667 42.666667 0 0 0-3.413334-16.213334z" />
  </svg>
);
