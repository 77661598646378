import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import usersApi from 'api/users';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { FormikInput } from 'components';
import UsersAutocomplete from 'components/common/UsersAutocomplete';
import { FormikProps, useFormik } from 'formik';
import { Match, MatchStatus, MatchType } from 'models/match';
import React, { useCallback, useEffect, useState } from 'react';
import { matchesActions } from 'redux/reducers/matches';
import { selectedMatchSelector } from 'redux/selectors/matches';
import { AutoSelectOptionType } from 'types/common';
import { CreateMatchRequest } from 'types/matches';
import * as yup from 'yup';

const validationSchema = yup.object().shape({
  user1: yup.object().required('User 1 is required'),
  user2: yup.object().required('User 2 is required'),
  message: yup.string().required('Message is required'),
  createdBy: yup.string().required('Creator is required'),
});

type MatchFormProps = {
  user1: AutoSelectOptionType | null;
  user2: AutoSelectOptionType | null;
  message: string;
  createdBy: string;
};

type ComponentProps = {
  matchId: string | undefined;
};

const MatchForm: React.FC<ComponentProps> = ({ matchId }) => {
  const dispatch = useAppDispatch();
  const selectedMatch = useAppSelector(selectedMatchSelector);
  const isEditModeActive = !!matchId;
  const [commonTags, setCommonTags] = useState<string>('');
  const [isCommonTagsLoading, setIsCommonTagsLoading] = useState(false);

  useEffect(() => {
    if (matchId) {
      dispatch(matchesActions.getMatchById({ id: matchId }));
    }

    return () => {
      formik.resetForm();
    };
  }, [matchId, dispatch]);

  useEffect(() => {
    if (matchId && selectedMatch) {
      setFormValues(selectedMatch);
    }
  }, [selectedMatch, matchId]);

  const handleButtonClick = async (
    status: MatchStatus.LIVE | MatchStatus.QUEUED,
    formikProps: FormikProps<MatchFormProps>
  ) => {
    const { values, resetForm, setFieldError, setFieldTouched } = formikProps;

    let isValid = true;

    if (!values.createdBy) {
      setFieldError('createdBy', 'Creator is required');
      setFieldTouched('createdBy');
      isValid = false;
    }

    if (!values.message) {
      setFieldError('message', 'Message is required');
      setFieldTouched('message');
      isValid = false;
    }

    if (values.user1 && !values.user1.value) {
      setFieldError('user1', 'User 1 is required');
      setFieldTouched('user1');
      isValid = false;
    }

    if (values.user2 && !values.user2.value) {
      setFieldError('user2', 'User 2 is required');
      setFieldTouched('user2');
      isValid = false;
    }

    if (!isValid) {
      return;
    }

    if (!values.user1 || !values.user2) return;
    const requestData: CreateMatchRequest = {
      user1: values.user1.value,
      user2: values.user2.value,
      message: values.message,
      createdBy: values.createdBy,
      matchType: MatchType.MANUAL,
      status: status,
    };
    if (matchId) {
      dispatch(
        matchesActions.updateMatch({
          data: requestData,
          id: matchId,
        })
      );
    } else {
      dispatch(matchesActions.createMatch(requestData));
    }
    resetForm();
  };

  const formik = useFormik<MatchFormProps>({
    initialValues: {
      user1: {
        label: '',
        value: '',
      },
      user2: {
        label: '',
        value: '',
      },
      message: '',
      createdBy: '',
    },
    validationSchema: validationSchema,
    onSubmit: () => {},
    validateOnBlur: true,
  });

  const setFormValues = useCallback(
    (match: Match) => {
      const { setValues } = formik;
      setValues({
        createdBy: match.createdBy || '',
        message: match.message,
        user1: {
          label: match.user1?.email || '',
          value: match.user1?._id || '',
        },
        user2: {
          label: match.user2?.email || '',
          value: match.user2?._id || '',
        },
      });
    },
    [formik]
  );

  const getUsersCommonTags = async (user1: string, user2: string) => {
    if (user1 && user2) {
      setIsCommonTagsLoading(true);
      const commonTags = await usersApi.getCommonTags(user1, user2);
      setCommonTags(commonTags.map((tag) => tag.value).join(', '));
      setIsCommonTagsLoading(false);
    }
  };

  return (
    <Stack
      component="form"
      onSubmit={formik.handleSubmit}
      className="new-matches-form"
      sx={{ width: '33%', gap: '20px' }}
    >
      <Typography
        sx={{ fontWeight: '600' }}
        className="form-title"
        variant="body1"
        component="p"
      >
        {'New Match'}
      </Typography>
      <UsersAutocomplete
        label="Select member 1"
        sx={{ marginBottom: '18px', width: '100%', maxWidth: 500 }}
        selected={formik.values.user1 ? formik.values.user1 : undefined}
        multiple={false}
        onChange={(selected) => {
          formik.setFieldValue('user1', selected);
          getUsersCommonTags(
            (selected as AutoSelectOptionType).value,
            formik.values.user2?.value || ''
          );
        }}
        error={formik.touched.user1 && Boolean(formik.errors.user1)}
        errorText={(formik.touched.user1 && formik.errors.user1) || ''}
      />

      <UsersAutocomplete
        label="Select member 2"
        sx={{ marginBottom: '18px', width: '100%', maxWidth: 500 }}
        selected={formik.values.user2 ? formik.values.user2 : undefined}
        multiple={false}
        onChange={(selected) => {
          formik.setFieldValue('user2', selected);
          getUsersCommonTags(
            (selected as AutoSelectOptionType).value,
            formik.values.user1?.value || ''
          );
        }}
        error={formik.touched.user2 && Boolean(formik.errors.user2)}
        errorText={(formik.touched.user2 && formik.errors.user2) || ''}
      />

      {commonTags && (
        <FormikInput
          multiline
          disabled
          value={commonTags}
          formik={formik}
          name="commonTags"
          label=""
        />
      )}

      <FormikInput
        multiline
        rowsCount={3}
        formik={formik}
        name="message"
        label="Message"
      />
      <FormikInput formik={formik} name="createdBy" label="Creator" />
      {isEditModeActive ? (
        <Button
          name="save"
          variant="outlined"
          color="custom"
          fullWidth
          type="button"
          onClick={() => handleButtonClick(MatchStatus.QUEUED, formik)}
        >
          {'Update'}
        </Button>
      ) : (
        <>
          <Button
            name="save"
            variant="outlined"
            color="custom"
            fullWidth
            type="button"
            onClick={() => handleButtonClick(MatchStatus.QUEUED, formik)}
          >
            {'ADD TO QUEUE'}
          </Button>
          <Button
            name="publish"
            variant="contained"
            color="primary"
            fullWidth
            type="button"
            onClick={() => handleButtonClick(MatchStatus.LIVE, formik)}
          >
            {'PUBLISH'}
          </Button>
        </>
      )}
    </Stack>
  );
};

export default MatchForm;
