import React, { FC } from 'react';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import { analyticsLoadingSelector } from 'redux/selectors/analytics';
import { determineChangeUsersStatus } from 'utils/analytics';
import PercentageDiff from './percentageDiff';
import 'styles/pages/home.sass';
import { useAppSelector } from 'app/hooks';
import { ReactLoader } from 'components';
import { ReportData } from 'types/analytics';

interface Props {
  title: string;
  totalLabel?: string;
  report: ReportData;
  percentageVisible?: boolean;
}

const backgroundColors = {
  primary: 'rgb(72, 86, 108)',
  secondary: 'rgb(161, 128, 132)',
};

const labelTextStyles = {
  color: 'rgb(36, 41, 46)',
  fontSize: 14,
  marginRight: 2,
};

const AnalyticReportsBlock: FC<Props> = ({
  title,
  report,
  percentageVisible = true,
  totalLabel,
}) => {
  const loading = useAppSelector(analyticsLoadingSelector);

  if (!report?.reportData?.length) return <></>;

  return (
    <Stack className="analytics-report-wrapper">
      <Box className="analytics-report-wrapper__header home-page">
        <Typography sx={labelTextStyles} variant="h2">
          {title}
        </Typography>
        {!loading && percentageVisible && (
          <PercentageDiff
            sx={{ left: '100px' }}
            {...determineChangeUsersStatus(
              report.total || 0,
              report.reportData[0].count + report.reportData[1].count
            )}
          />
        )}
        {totalLabel && (
          <Typography sx={labelTextStyles} variant="h2">
            {`${totalLabel}: ${report.total}`}
          </Typography>
        )}
      </Box>
      <Stack flexDirection="row" width="100%">
        {!loading ? (
          report.reportData.map((data) => (
            <Stack
              className="analytics-report-view"
              sx={{ backgroundColor: backgroundColors[data.color || 'primary'] }}
              key={data.label}
            >
              <Box className="analytics-report-view__content">
                <Box className="analytics-report-view__content_header">{data.label}</Box>
                <Box className="analytics-report-view__content_counter">{data.count}</Box>
              </Box>
            </Stack>
          ))
        ) : (
          <ReactLoader height={140} width={140} />
        )}
      </Stack>
    </Stack>
  );
};

export default AnalyticReportsBlock;
