import { Button, Checkbox, FormControlLabel } from '@mui/material';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { useAppDispatch } from 'app/hooks';
import { CustomDatePicker, Page, WhiteBox } from 'components';
import PlacesAutocomplete from 'components/common/PlacesAutocomplete';
import UsersAutocomplete from 'components/common/UsersAutocomplete';
import { useFormik } from 'formik';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { appActions } from 'redux/reducers/app';
import { checkinActions } from 'redux/reducers/checkIn';
import { CreateCheckInRequest } from 'types/check-in';
import { AutoSelectOptionType } from 'types/common';
import { DateType } from 'types/experiences';
import * as yup from 'yup';

interface CheckinValues {
  place: AutoSelectOptionType | null;
  user: AutoSelectOptionType | null;
  experienceOnly: boolean;
  date: string;
}

const validationSchema = yup.object().shape({
  place: yup.object().required('Required'),
  user: yup.object().required('Required'),
  experienceOnly: yup.boolean().required('Required'),
  date: yup.string().required('Required'),
});

const CreateCheckin: React.FC = () => {
  const dispatch = useAppDispatch();
  const [dateError, setDateError] = useState<string | null>(null);
  const navigate = useNavigate();

  const handleSubmit = async (values: CheckinValues): Promise<void> => {
    if (dateError) setDateError(null);
    const dateValidation = validateExpirationDate(values.date);

    if (!dateValidation?.isValid) {
      setDateError(dateValidation.message);
      return;
    }

    let data: CreateCheckInRequest = {
      userId: values.user?.value,
      placeId: values.place?.value as string,
      experienceOnly: values.experienceOnly,
      date: values.date,
    };

    dispatch(checkinActions.createCheckIn(data));
    dispatch(
      checkinActions.getCheckIns({
        query: '',
        placeId: 'all',
        date: null,
        sort: 'asc',
      })
    );
    //formik.resetForm();
  };

  const formik = useFormik<CheckinValues>({
    initialValues: {
      user: null,
      place: null,
      experienceOnly: false,
      date: moment().toDate().toISOString(),
    },
    validationSchema: validationSchema,
    onSubmit: handleSubmit,
    validateOnBlur: true,
  });

  useEffect(() => {
    return () => {
      formik.resetForm();
    };
  }, []);

  useEffect(() => {
    dispatch(appActions.getAllPlaces());
  }, [dispatch]);

  const validateExpirationDate = (date: string) => {
    if (!date || !moment(date).isValid()) {
      return { isValid: false, message: 'Invalid date' };
    }

    return { isValid: true, message: '' };
  };

  const handleDateChange = (date: DateType) => {
    if (dateError) setDateError(null);
    const momentDate = moment(date).utc(true).toDate();
    formik.setFieldValue('date', momentDate);
  };

  return (
    <Page onBackClick={() => navigate(-1)} title="" className="experience-profile-page">
      <Stack component="form" onSubmit={formik.handleSubmit}>
        <Grid container sx={{ marginBottom: '24px' }} spacing={2}>
          <Grid item xs={5}>
            <WhiteBox margin="0 0 12px 0">
              <Typography sx={{ marginBottom: '24px' }} variant="h2">
                Manual check-in
              </Typography>
              <Stack height={'100%'}>
                <CustomDatePicker
                  label="Date"
                  sx={{ marginBottom: '18px', width: '100%', maxWidth: 500 }}
                  value={moment.utc(formik.values.date)}
                  onChange={(date) => handleDateChange(date)}
                  disablePast={false}
                />
                {dateError && (
                  <Typography
                    sx={{ margin: '0 14px 14px 14px' }}
                    color="error"
                    variant="caption"
                  >
                    {dateError}
                  </Typography>
                )}
                <PlacesAutocomplete
                  label="Place"
                  name="place"
                  sx={{ marginBottom: '18px', width: '100%', maxWidth: 500 }}
                  selected={formik.values.place ?? undefined}
                  multiple={false}
                  onChange={(selected) => {
                    if (!Array.isArray(selected)) {
                      formik.setFieldValue('place', selected);
                    }
                  }}
                  error={formik.touched.place && Boolean(formik.errors.place)}
                  errorText={(formik.touched.place && formik.errors.place) || ''}
                />
                <UsersAutocomplete
                  label="Select member"
                  sx={{ marginBottom: '18px', width: '100%', maxWidth: 500 }}
                  selected={formik.values.user ? formik.values.user : undefined}
                  multiple={false}
                  onChange={(selected) => {
                    formik.setFieldValue('user', selected);
                  }}
                  error={formik.touched.user && Boolean(formik.errors.user)}
                  errorText={(formik.touched.user && formik.errors.user) || ''}
                />
                <FormControlLabel
                  sx={{
                    marginBottom: '24px',
                  }}
                  control={
                    <Checkbox
                      name="experienceOnly"
                      color="info"
                      onChange={formik.handleChange}
                      value={formik.values.experienceOnly}
                      checked={formik.values.experienceOnly}
                    />
                  }
                  label="Experience Only"
                />
                <Button
                  variant="contained"
                  color="primary"
                  fullWidth
                  type="submit"
                  sx={{ marginBottom: '18px', width: '100%', maxWidth: 500 }}
                >
                  SAVE
                </Button>
              </Stack>
            </WhiteBox>
          </Grid>
        </Grid>
      </Stack>
    </Page>
  );
};

export default CreateCheckin;
