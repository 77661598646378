import styled from '@mui/material/styles/styled';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Typography from '@mui/material/Typography';
import { StatusBadge } from 'components';
import { SuggestedMemberDto } from 'models/member';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';

interface Props {
  suggestions: SuggestedMemberDto[];
}

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  '&:last-child td, &:last-child th': {
    border: 0,
  },
}));

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {
    backgroundColor: '#fff',
    color: '#000',
    padding: '8px 16px',
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 14,
    borderBottom: '1px solid rgb(247, 247, 255)',
    padding: '8px 16px',
  },
}));

const MemberSuggestionsTable: FC<Props> = ({ suggestions }) => {
  const navigate = useNavigate();
  if (!suggestions?.length) return null;

  return (
    <TableContainer sx={{ maxHeight: '500px' }}>
      <Table sx={{ minWidth: 650 }} aria-label="simple table" stickyHeader>
        <TableHead>
          <StyledTableRow>
            <StyledTableCell align="left">Email</StyledTableCell>
            <StyledTableCell align="left">Name</StyledTableCell>
            <StyledTableCell align="left">Status</StyledTableCell>
          </StyledTableRow>
        </TableHead>
        <TableBody>
          {suggestions.map((ref) => (
            <StyledTableRow key={ref.email}>
              <StyledTableCell align="left">
                <Typography
                  variant="body2"
                  sx={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  onClick={() => navigate(`/membership/profile/${ref._id}`)}
                >
                  {ref.email || ''}
                </Typography>
              </StyledTableCell>
              <StyledTableCell>
                {ref.profile?.firstName || ''} {ref.profile?.lastName || ''}
              </StyledTableCell>
              <StyledTableCell align="left">
                <StatusBadge className="member-table-status" status={ref.status} />
              </StyledTableCell>
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default MemberSuggestionsTable;
