import {
  CitiesResponse,
  EnableCityRequest,
  DisableCityRequest,
  ChangeCityResponse,
  CityResponse,
  UpdateCityRequest,
} from 'types/cities';
import axiosApi from './config';

const CitiesApi = {
  getEnabledCities(): Promise<CitiesResponse> {
    const url = 'cities';
    return axiosApi.get(url);
  },
  getAllCities(): Promise<CitiesResponse> {
    const url = 'cities';
    return axiosApi.get(url, {
      baseURL: process.env.REACT_APP_API_URL + '/api/dictionary',
    });
  },
  getCityById(id: string): Promise<CityResponse> {
    const url = `cities/${id}`;
    return axiosApi.get(url);
  },
  enableCity(data: EnableCityRequest): Promise<ChangeCityResponse> {
    const url = `cities/enable/${data.id}`;
    return axiosApi.patch(url, data.data);
  },
  disableCity(data: DisableCityRequest): Promise<ChangeCityResponse> {
    const url = `cities/disable/${data.id}`;
    return axiosApi.patch(url);
  },
  updateCity(data: UpdateCityRequest): Promise<ChangeCityResponse> {
    const url = `cities/${data.id}`;
    return axiosApi.put(url, data.data);
  },
};

export default CitiesApi;
