import { FC, useEffect, useState, useMemo } from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TableSortLabel from '@mui/material/TableSortLabel';
import { StyledTableCell, StyledTableRow, ReactLoader } from 'components';
import { useAppSelector } from 'app/hooks';
import { placeReservationsSelector, allPacesSelector } from 'redux/selectors/places';
import { ReservedRoom, Room } from 'types/places';

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

const RoomsTable: FC<{ selectedPlaceId: string }> = ({ selectedPlaceId }) => {
  const placeReservations = useAppSelector(placeReservationsSelector);
  const [reservedTimeSlots, setReservedTimeSlots] = useState<Room[]>([]);
  const places = useAppSelector(allPacesSelector);

  useEffect(() => {
    if (!placeReservations) return;
    const rooms: Room[] = placeReservations.reduce(
      (result: any[], current: ReservedRoom) => {
        current.rooms.forEach((room) => {
          const existingRoom = result.find((r: Room) => r._id === room._id);
          if (existingRoom) {
            existingRoom.times = [...existingRoom.times, ...room.times];
          } else {
            result.push({ name: room.name, times: room.times, _id: room._id });
          }
        });
        return result;
      },
      []
    );

    const roomsWithUniqueTime = rooms.map((room) => {
      return {
        name: room.name,
        _id: room._id,
        times: room.times.filter((time: string, index: number) => {
          return room.times.indexOf(time) === index;
        }),
      };
    });

    setReservedTimeSlots(roomsWithUniqueTime);
  }, [placeReservations]);

  const checkTimeSlotAvailability = (roomName: string, time: string) => {
    let isTimeSlotReserved = false;
    if (reservedTimeSlots.length > 0) {
      reservedTimeSlots.forEach((room) => {
        if (room.name === roomName) {
          const existingTime = room.times.find((t) => t === time);
          if (existingTime) {
            isTimeSlotReserved = true;
          }
        }
      });
    }
    return isTimeSlotReserved;
  };

  const getTimeSlotUserName = (roomName: string, time: string) => {
    let result = '';

    if (placeReservations && placeReservations?.length > 0) {
      placeReservations.forEach((reservation) => {
        reservation.rooms.forEach((room) => {
          if (room.name === roomName) {
            const existingTime = room.times.find((t: string) => t === time);
            if (existingTime) {
              result = `${reservation.user?.profile?.firstName} ${reservation.user?.profile?.lastName?.[0]}.`;
            }
          }
        });
      });
    }
    return result;
  };

  const timeSlots = useMemo(() => {
    const placeWithRooms = places?.length
      ? places.find((place) => place?.rooms?.length > 0 && place)
      : undefined;
    if (placeWithRooms) {
      return placeWithRooms.rooms[0].times;
    }
    return [];
  }, [places]);

  const roomsNames = useMemo(() => {
    if (!places?.length) return [];
    const currentPlace = places.find((pl) => pl._id === selectedPlaceId);
    const names = currentPlace?.rooms.map((room) => room.name) || [];
    return names;
  }, [places, selectedPlaceId]);

  const columnWidth = 100 / (roomsNames.length + 1);

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {timeSlots.length && roomsNames ? (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow>
                <StyledTableCell align="left">
                  <TableSortLabel disabled>Time</TableSortLabel>
                </StyledTableCell>
                {roomsNames.map((room) => (
                  <StyledTableCell align="left" key={room}>
                    <TableSortLabel disabled>{room || ''}</TableSortLabel>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {timeSlots.map((time) => (
                <StyledTableRow key={time}>
                  <StyledTableCell
                    sx={mainTextStyles}
                    align="left"
                    className="table-cell room-text"
                    width={`${columnWidth}%`}
                  >
                    {time}
                  </StyledTableCell>
                  {roomsNames.map((room) => (
                    <StyledTableCell
                      sx={mainTextStyles}
                      align="left"
                      width={`${columnWidth}%`}
                      key={room}
                    >
                      <Stack
                        className={
                          checkTimeSlotAvailability(room, time)
                            ? 'room-cell room-cell-reserved'
                            : 'room-cell room-cell-empty'
                        }
                      >
                        <p className="room-cell-name">
                          {getTimeSlotUserName(room, time)}
                        </p>
                      </Stack>
                    </StyledTableCell>
                  ))}
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : (
        <ReactLoader />
      )}
    </Paper>
  );
};

export default RoomsTable;
