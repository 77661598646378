import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { Payment } from 'models/payment';
import { PaymentsRequest, PaymentsResponse } from 'types/payments';

export interface PaymentsState {
  loading: boolean;
  payments: Payment[];
  offset: number;
  total: number;
  error: any;
};

const initialState: PaymentsState = {
  loading: false,
  payments: [],
  offset: 0,
  total: 0,
  error: null,
};

const paymentsSlice = createSlice({
  name: 'payments',
  initialState: initialState,
  reducers: {
    getPayments(state: PaymentsState, action: PayloadAction<PaymentsRequest>) {
      state.loading = true;
    },
    getPaymentsSuccess(state: PaymentsState, action: PayloadAction<PaymentsResponse>) {
      state.loading = false;
      state.total = action.payload.total;

      if (action.payload.offset && action.payload.offset > 0) {
        state.payments = [...state.payments, ...action.payload.data];
      } else {
        state.payments = action.payload.data;
      }
    },
    getPaymentsFailed(state: PaymentsState, action: PayloadAction<any>) {
      state.loading = false;
      state.error = action.payload;
    },
    updateOffset(state: PaymentsState, action: PayloadAction<{ offset: number }>) {
      state.offset = action.payload.offset;
    },
    reset: (state) => {
      return initialState;
    },
    getPaymentCsv: (state: PaymentsState, action: PayloadAction<any>) => {
    },
  },
});

// Actions
export const paymentsActions = paymentsSlice.actions;

// Reducer
const paymentsReducer = paymentsSlice.reducer;
export default paymentsReducer;
