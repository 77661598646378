import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import Typography from '@mui/material/Typography';
import { ContextMenu, ReactLoader, StyledTableCell, StyledTableRow } from 'components';
import { TagDto } from 'models/tag';
import { FC } from 'react';

type TableHeadCell = {
  label: string;
};

const TABLE_HEAD: TableHeadCell[] = [
  {
    label: 'Value',
  },
  {
    label: 'Key',
  },
  {
    label: 'Category',
  },
  {
    label: 'Actions',
  },
];

type Props = {
  loading?: boolean;
  tags: TagDto[];
  total: number;
  loadMore: () => void;
  onDelete: (id: string) => void;
  handleEditTag: (tag: TagDto | null) => void;
};

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

const TagsTable: FC<Props> = ({
  loading,
  tags,
  handleEditTag,
  loadMore,
  onDelete,
  total,
}) => {
  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {loading && !tags.length ? (
        <Stack alignItems="center" direction="column" marginTop={2}>
          <ReactLoader />
        </Stack>
      ) : (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow>
                {TABLE_HEAD.map((item) => (
                  <StyledTableCell key={item.label} align="left">
                    <Typography sx={{ ...mainTextStyles, fontWeight: 700 }}>
                      {item.label}
                    </Typography>
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {tags.map((tag) => (
                <StyledTableRow key={tag._id}>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {tag.value}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {tag.key}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {tag.category}
                  </StyledTableCell>
                  <StyledTableCell align="left">
                    <ContextMenu
                      options={[
                        {
                          label: 'Edit tag',
                          onClick: () => handleEditTag(tag),
                        },
                        { label: 'Delete tag', onClick: () => onDelete(tag._id) },
                      ]}
                    />
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      )}
      {total && tags?.length < total ? (
        <Button onClick={loadMore} variant="contained" color="primary" fullWidth>
          Load more
        </Button>
      ) : null}
    </Paper>
  );
};

export default TagsTable;
