import { Button, TextField, TextFieldProps } from '@mui/material';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { Page, ReactLoader } from 'components';
import { Form, Formik, useField } from 'formik';
import { Member, Status } from 'models/member';
import { useEffect } from 'react';
import { usersActions } from 'redux/reducers/users';
import { allUsersSelector, usersLoadingSelector } from 'redux/selectors/users';
import { UpdateUserStatus } from 'types/users';

interface AwaitingReviewMemberItemProps {
  member: Member;
}

// Custom TextField component for Formik with TypeScript types
const FormikTextField: React.FC<TextFieldProps & { name: string }> = (props) => {
  const [field, meta] = useField(props.name);
  return (
    <TextField
      {...field}
      {...props}
      error={meta.touched && Boolean(meta.error)}
      helperText={meta.touched && meta.error}
    />
  );
};

const AwaitingReviewMembers: React.FC = () => {
  const dispatch = useAppDispatch();

  const users = useAppSelector(allUsersSelector);
  const loading = useAppSelector(usersLoadingSelector);

  useEffect(() => {
    dispatch(
      usersActions.getUsers({
        status: Status.AWAITING_REVIEW,
        offset: 0,
        limit: '100',
      })
    );
  }, [dispatch]);

  const AwaitingReviewMemberItem: React.FC<AwaitingReviewMemberItemProps> = ({
    member,
  }) => {
    const handleSubmit = (
      values: { promoCode: string },
      { setSubmitting }: { setSubmitting: (isSubmitting: boolean) => void }
    ) => {
      dispatch(
        usersActions.updateUserStatus({
          id: member._id,
          status: UpdateUserStatus.APPROVE,
          promoCode: values.promoCode,
        })
      );

      setSubmitting(false);
    };

    return (
      <Formik
        initialValues={{ promoCode: member?.onboardingSettings?.promoCode }}
        onSubmit={handleSubmit}
        enableReinitialize
      >
        {({ isSubmitting }) => (
          <Form className="formik-container">
            <div
              key={member._id}
              className="item-container"
              style={{
                backgroundColor:
                  member.status === Status.AWAITING_REVIEW
                    ? 'white'
                    : 'rgba(193, 242, 182, 0.25)',
              }}
            >
              <div className="member-name-text">
                {member.profile?.firstName} {member.profile?.lastName}
              </div>
              <div style={{ marginTop: 24 }}>
                <FormikTextField
                  name="promoCode"
                  type="text"
                  sx={{
                    width: '100%',
                    boxShadow: '1px 5px 5px rgba(0, 0, 0, 0.2)',
                    borderRadius: 3,
                    backgroundColor: '#FBFBFF',
                    borderColor: 'black',
                  }}
                  label="Promo"
                  InputLabelProps={{ style: { color: 'black' } }}
                  InputProps={{ style: { color: 'black', borderColor: 'black' } }}
                />
              </div>
              <div style={{ marginTop: 24 }}>
                <Button
                  type="submit"
                  sx={{
                    backgroundColor: '#78C36A',
                    color: '#fff',
                    '&.Mui-disabled': {
                      background: 'rgba(120, 195, 106, 0.1)',
                      color: '#78C36A',
                    },
                    '&:hover': {
                      background: 'rgba(120, 195, 106, 0.5)',
                    },
                  }}
                  variant="contained"
                  disabled={isSubmitting || member.status !== Status.AWAITING_REVIEW}
                  fullWidth
                >
                  {member.status === Status.AWAITING_REVIEW ? 'APPROVE' : 'APPROVED'}
                </Button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    );
  };

  return (
    <Page className="awaiting-review-members-page" title="Awaiting Review">
      <div className="container">
        {loading && <ReactLoader />}
        {users.map((member, i) => (
          <AwaitingReviewMemberItem key={member._id} member={member} />
        ))}
      </div>
    </Page>
  );
};

export default AwaitingReviewMembers;
