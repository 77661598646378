import React from 'react';
import Stack from '@mui/material/Stack';
import { CustomSelect, SearchInput } from 'components';
import { AnnouncementFilter } from 'types/announcements';

const FILTERS = [
  {
    value: AnnouncementFilter.ACTIVE,
    label: 'Active',
  },
  {
    value: AnnouncementFilter.EXPIRED,
    label: 'Expired',
  },
];

type Props = {
  onSearchChange: (value: string) => void;
  searchValue: string;
  onExpirationDateChange: (value: AnnouncementFilter) => void;
  expirationDateSelectValue: string;
};

const ProductsFilters: React.FC<Props> = ({
  onSearchChange,
  searchValue,
  expirationDateSelectValue,
  onExpirationDateChange,
}) => {
  return (
    <Stack flexDirection="row" alignItems="center">
      <CustomSelect
        sx={{marginRight: '24px', width: '180px'}}
        label="Expiration Date"
        name="expirationDate"
        options={FILTERS}
        value={expirationDateSelectValue}
        onChange={(e) => onExpirationDateChange(e.target.value)}
      />
      <SearchInput
        sx={{width: '250px'}}
        name="search"
        onChange={(v) => onSearchChange(v)}
        value={searchValue}
      />
    </Stack>
  );
};

export default ProductsFilters;
