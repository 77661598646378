import { FC, useState } from 'react';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { WhiteBox } from 'components';
import { imagePlaceholder } from 'assets/svg';
import { Hotspot } from 'models/hotspot';
import { Document, Page, pdfjs } from 'react-pdf';
import { hotspotCitiesToString } from 'utils/helpers/hotspots';

type Props = {
  hotspot: Hotspot | null;
};

const mb = 2;

const HotspotPreview: FC<Props> = ({ hotspot }) => {
  const [pdfPagesNum, setPdfPagesNum] = useState(0);

  function onDocumentLoadSuccess({ numPages }: { numPages: number }) {
    setPdfPagesNum(numPages);
  }

  return (
    <WhiteBox width="50%">
      <Typography sx={{ marginBottom: '18px' }} variant="h2">
        Preview
      </Typography>
      <Stack marginBottom={mb}>
        <img
          src={hotspot?.imageUrl || imagePlaceholder}
          alt="preview_image"
          className="preview-image"
        />
      </Stack>
      <Typography
        sx={{ fontWeight: 600, marginBottom: mb }}
        variant="body1"
        component="p"
      >
        {hotspot?.name}
      </Typography>
      <Typography
        sx={{ fontWeight: 600, marginBottom: mb }}
        variant="body2"
        component="p"
      >
        {hotspot?.user ? `${hotspot?.user?.profile?.firstName} ${hotspot?.user?.profile?.lastName}` : hotspot?.author || ''}
      </Typography>
      <Stack marginBottom={'48px'} flexDirection={'row'} alignItems={'center'}>
        {hotspot ? (
          <Stack className="city-label">
            <Typography variant="body2" component="p" sx={{ color: '#FFFFFF' }}>
            {hotspot?.isGlobal ? 'Global' : hotspotCitiesToString(hotspot.cities)}
            </Typography>
          </Stack>
        ) : null}
      </Stack>
      <Typography marginBottom={'12px'} variant="h2">
        PDF Preview
      </Typography>
      <Stack
        flexDirection={'column'}
        sx={{
          width: '100%',
          maxHeight: '600px',
          overflow: 'hidden',
          borderRadius: '12px',
        }}
      >
        <Stack sx={{ overflowY: 'scroll' }}>
          <Document file={hotspot?.pdfUrl} onLoadSuccess={onDocumentLoadSuccess}>
            {Array.from({ length: pdfPagesNum }, (_, index) => (
              <Page key={index + 1} pageNumber={index + 1} />
            ))}
          </Document>
        </Stack>
      </Stack>
    </WhiteBox>
  );
};

export default HotspotPreview;
