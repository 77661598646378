import { PayloadAction } from '@reduxjs/toolkit';
import usersApi from 'api/users';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { usersActions } from 'redux/reducers/users';
import {
  GetUserByIdRequest,
  GetUserByIdResponse,
  GetUsersResponse,
  GetUsersRequest,
  GetUserCheckinsResponse,
  GetUserReferralsResponse,
  UpdateUserProfileRequest,
  UpdateUserStatusRequest,
  GetCitiesCount,
  SetUserSuggestionsRequest,
  GetUserMatchesResponse,
} from 'types/users';
import { saveAs } from 'file-saver';

function* getUsers(data: PayloadAction<GetUsersRequest>) {
  try {
    const response: GetUsersResponse = yield call(usersApi.getUsers, data.payload);
    yield put(usersActions.getUsersSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch users`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.getUsersFailed(error.response?.data.message));
    }
  }
}

function* getUserById(data: PayloadAction<GetUserByIdRequest>) {
  try {
    const response: GetUserByIdResponse = yield call(usersApi.getUserById, data.payload);
    yield put(usersActions.getUserByIdSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch place by id`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.getUserByIdFailed(error.response?.data.message));
    }
  }
}

function* getUserCheckins(data: PayloadAction<GetUserByIdRequest>) {
  try {
    const response: GetUserCheckinsResponse = yield call(
      usersApi.getUserCheckins,
      data.payload
    );
    yield put(usersActions.getUserCheckinsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch user's checkins`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.getUserCheckinsFailed(error.response?.data.message));
    }
  }
}

function* getUserReferrals(data: PayloadAction<GetUserByIdRequest>) {
  try {
    const response: GetUserReferralsResponse = yield call(
      usersApi.getUserReferrals,
      data.payload
    );
    yield put(usersActions.getUserReferralsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch user's referrals`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.getUserReferralsFailed(error.response?.data.message));
    }
  }
}

function* getUserSuggestedMembers(data: PayloadAction<GetUserByIdRequest>) {
  try {
    const response: GetUserReferralsResponse = yield call(
      usersApi.getUserSuggestions,
      data.payload
    );
    yield put(usersActions.getUserSuggestedMembersSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch user's suggestions`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.getUserSuggestedMembersFailed(error.response?.data.message));
    }
  }
}

function* updateUserStatus(data: PayloadAction<UpdateUserStatusRequest>) {
  try {
    yield call(usersApi.updateUserStatus, data.payload);
    const response: GetUserByIdResponse = yield call(usersApi.getUserById, data.payload);
    yield put(usersActions.getUserByIdSuccess(response));
    yield put(usersActions.updateUserStatusSuccess(response));
  } catch (error) {
    console.log(`Failed to update user status`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.updateUserStatusError(error.response?.data.message));
    }
  }
}

function* updateUserProfile(data: PayloadAction<UpdateUserProfileRequest>) {
  try {
    const response: GetUserByIdResponse = yield call(
      usersApi.updateUserProfile,
      data.payload
    );
    yield put(usersActions.updateUserProfileSuccess(response));
  } catch (error) {
    console.log(`Failed to update user`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.updateUserProfileError(error.response?.data.message));
    }
  }
}

function* setUserSuggestions(data: PayloadAction<SetUserSuggestionsRequest>) {
  try {
    yield call(usersApi.setUserSuggestions, data.payload);
    yield put(usersActions.getUserSuggestedMembers({ id: data.payload.id }));
  } catch (error) {
    console.log(`Failed to set user suggestions`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.updateUserProfileError(error.response?.data.message));
    }
  }
}

function* getLocations({ payload }: any) {
  try {
    const response: GetUsersResponse = yield call(usersApi.getLocation, payload);
    yield put(usersActions.getLocationsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch locations`, error);
  }
}
function* getLocationsCount() {
  try {
    const response: GetCitiesCount[] = yield call(usersApi.getLocationCount);

    const result = response.filter((resp) => resp.count > 0);

    yield put(usersActions.getLocationsCountSuccess(result));
  } catch (error) {
    console.log(`Failed to fetch locations count`, error);
  }
}
function* getLocationsMembers({ payload }: any) {
  try {
    const response: GetUsersResponse = yield call(usersApi.getLocationMembers, payload);
    yield put(usersActions.getLocationsMembersSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch locations members`, error);
  }
}

function* getLocationsMembersCsv({ payload }: any) {
  try {
    const response: string = yield call(usersApi.getLocationsMembersCsv, {
      lat: payload.location[1],
      lng: payload.location[0],
      offset: payload.offset,
      limit: payload.limit,
    });

    var blob = new Blob([response], { type: 'text/csv' });
    saveAs(blob, `${payload.city}.csv`);
  } catch (error) {
    console.log(`Failed to fetch csv`, error);
    if (axios.isAxiosError(error)) {
    }
  }
}

function* getUserMatches(data: PayloadAction<GetUserByIdRequest>) {
  try {
    const response: GetUserMatchesResponse = yield call(
      usersApi.getUserMatches,
      data.payload
    );
    yield put(usersActions.getUserMatchesSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch user's matches`, error);
    if (axios.isAxiosError(error)) {
      yield put(usersActions.getUserMatchesFailed(error.response?.data.message));
    }
  }
}

export default function* usersSaga() {
  yield takeLatest(usersActions.getUsers.type, getUsers);
  yield takeLatest(usersActions.getUserById.type, getUserById);
  yield takeLatest(usersActions.getUserCheckins.type, getUserCheckins);
  yield takeLatest(usersActions.getUserReferrals.type, getUserReferrals);
  yield takeLatest(usersActions.updateUserStatus.type, updateUserStatus);
  yield takeLatest(usersActions.updateUserProfile.type, updateUserProfile);
  yield takeLatest(usersActions.getLocations.type, getLocations);
  yield takeLatest(usersActions.getLocationsCount.type, getLocationsCount);
  yield takeLatest(usersActions.getLocationsMembers.type, getLocationsMembers);
  yield takeLatest(usersActions.getLocationsMembersCsv.type, getLocationsMembersCsv);
  yield takeLatest(usersActions.getUserSuggestedMembers.type, getUserSuggestedMembers);
  yield takeLatest(usersActions.setUserSuggestions.type, setUserSuggestions);
  yield takeLatest(usersActions.getUserMatches.type, getUserMatches);
}
