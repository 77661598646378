import { FC, useEffect } from 'react';
import { Page, WhiteBox } from 'components';
import HotspotForm from '../components/hotspotForm';
import { useParams } from 'react-router-dom';
import { useAppDispatch } from 'app/hooks';
import { hotspotsActions } from 'redux/reducers/hotspots';

type QueryParams = {
  hotspotId: string;
};

const EditHotspot: FC = () => {
  const { hotspotId } = useParams<QueryParams>();
  return (
    <Page title="Edit hotspot">
      <WhiteBox>
        <HotspotForm hotspotId={hotspotId} />
      </WhiteBox>
    </Page>
  );
};

export default EditHotspot;
