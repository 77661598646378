import Button from '@mui/material/Button';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableSortLabel from '@mui/material/TableSortLabel';
import { useAppSelector } from 'app/hooks';
import { ReactLoader, StyledTableCell, StyledTableRow } from 'components';
import getSymbolFromCurrency from 'currency-symbol-map';
import { Payment } from 'models/payment';
import moment from 'moment';
import { FC } from 'react';
import { useNavigate } from 'react-router-dom';
import { allPaymentsSelector, paymentsLoadingSelector } from 'redux/selectors/payments';
import { SortOrder } from 'types/common';
import { PaymentsSortBy } from 'types/payments';

const mainTextStyles = {
  color: '#1E1E70',
  fontSize: '12px',
};

type TableHeadCell = {
  orderBy?: PaymentsSortBy;
  label: string;
};

const TABLE_HEAD: TableHeadCell[] = [
  {
    label: 'User',
  },
  {
    label: 'Type',
  },
  {
    label: 'Title',
    orderBy: 'name',
  },
  {
    label: 'Amount',
  },
  {
    label: 'Place',
  },
  {
    orderBy: 'place.city.country.name',
    label: 'Country',
  },
  {
    orderBy: 'place.city.name',
    label: 'City',
  },
  {
    label: 'Date',
    orderBy: 'transactionDate',
  },
  {
    label: 'Status',
  },
  {
    label: 'Receipt',
  },
];

type Props = {
  onSort: (orderBy: PaymentsSortBy, order: SortOrder) => void;
  order?: SortOrder | null;
  orderBy?: PaymentsSortBy | null;
  loadMore: () => void;
  total: number;
  currency: string;
};
const PaymentsTable: FC<Props> = ({
  loadMore,
  total,
  onSort,
  order,
  orderBy,
  currency,
}) => {
  const navigate = useNavigate();
  const payments = useAppSelector(allPaymentsSelector);
  const loading = useAppSelector(paymentsLoadingSelector);
  const currencies = useAppSelector((state) => state.app.currency);

  const onClickHandler = (url: string) => {
    window.open(`${url}`, '_blank', 'noreferrer');
  };

  const onMemberClickHandler = (userId: string) => {
    if (!userId) return;
    navigate(`/membership/profile/${userId}`);
  };

  const onSortHandler = (orderBy?: PaymentsSortBy) => {
    if (!orderBy) return;
    const sortOrder = order === 'asc' ? 'desc' : 'asc';
    onSort(orderBy, sortOrder);
  };

  const handleAmountRedirect = (chargeId: string) => {
    if (process.env.NODE_ENV === 'development') {
      window.open(`https://dashboard.stripe.com/test/payments/${chargeId}`);
      return;
    }

    window.open(`https://dashboard.stripe.com/payments/${chargeId}`);
  };
  const handleProductRedirect = (productId: string) => {
    if (!productId) return;

    if (process.env.NODE_ENV === 'development') {
      window.open(`https://dashboard.stripe.com/test/products/${productId}`);
      return;
    }

    window.open(`https://dashboard.stripe.com/products/${productId}`);
  };

  const rendeUserName = (payment: Payment) => {
    if (payment.user) {
      return `${payment.user?.profile?.firstName || ''} ${
        payment.user?.profile?.lastName || ''
      }`;
    }

    if (payment?.email) {
      return payment.email;
    }

    return '-';
  };

  const getTransactionItemTitle = (item: Payment) => {
    switch (item.type) {
      case 'product':
        return item.product?.name;
      case 'experience':
        return item.experience?.title;
      default:
        return item.name || '-';
    }
  };

  const getCurrentAmount = (amount: number, c: string = 'USD') => {
    if (!currencies || !currencies[c]?.rates) return amount;
    const rate = currencies[c].rates[currency];
    return parseFloat((amount * rate).toFixed(2));
  };

  return (
    <Paper sx={{ width: '100%', overflow: 'hidden', boxShadow: 'none' }}>
      {payments?.length ? (
        <TableContainer sx={{ maxHeight: '69vh' }}>
          <Table aria-label="customized table" stickyHeader>
            <TableHead>
              <StyledTableRow className="table-head">
                {TABLE_HEAD.map((item) => (
                  <StyledTableCell
                    key={item.label}
                    align="left"
                    sortDirection={
                      orderBy && order && orderBy === item.orderBy ? order : false
                    }
                  >
                    {item.orderBy ? (
                      <TableSortLabel
                        active={orderBy === item.orderBy}
                        direction={
                          orderBy && order && orderBy == item.orderBy ? order : 'asc'
                        }
                        onClick={() => onSortHandler(item?.orderBy)}
                      >
                        {item.label}
                      </TableSortLabel>
                    ) : (
                      item.label
                    )}
                  </StyledTableCell>
                ))}
              </StyledTableRow>
            </TableHead>
            <TableBody>
              {payments.map((payment) => (
                <StyledTableRow key={payment._id}>
                  <StyledTableCell
                    sx={mainTextStyles}
                    align="left"
                    onClick={() => onMemberClickHandler(payment?.user?._id)}
                    className={payment.user ? 'table-cell-hover' : ''}
                  >
                    {rendeUserName(payment)}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {payment.type}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={mainTextStyles}
                    className={payment.productId ? 'table-cell-hover' : ''}
                    align="left"
                    onClick={() => handleProductRedirect(payment.productId)}
                  >
                    {getTransactionItemTitle(payment)}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={mainTextStyles}
                    className="table-cell-hover"
                    align="left"
                    onClick={() => handleAmountRedirect(payment.chargeId)}
                  >
                    {`${getSymbolFromCurrency(currency)}${getCurrentAmount(
                      payment.amount / 100,
                      payment.currency.toUpperCase()
                    )} `}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {payment.place?.name || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {payment.place?.city?.country?.name || '-'}
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {payment.place?.city?.name || '-'}
                  </StyledTableCell>

                  <StyledTableCell sx={mainTextStyles} align="left">
                    {moment(payment.transactionDate).format('DD-MM-YYYY')}
                  </StyledTableCell>
                  <StyledTableCell
                    sx={mainTextStyles}
                    className={`table-cell-transaction-status ${payment.transactionStatus}`}
                    align="left"
                  >
                    <span>{payment.transactionStatus || '-'}</span>
                  </StyledTableCell>
                  <StyledTableCell sx={mainTextStyles} align="left">
                    {payment?.receiptUrl && (
                      <Button
                        onClick={() => onClickHandler(payment.receiptUrl)}
                        variant="contained"
                        color="primary"
                        fullWidth
                      >
                        Receipt
                      </Button>
                    )}
                  </StyledTableCell>
                </StyledTableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      ) : !loading && total === 0 ? (
        <>
          <p style={{ textAlign: 'center', marginTop: '40px' }}> No transactions</p>
        </>
      ) : (
        <ReactLoader />
      )}
      {total && payments?.length < total ? (
        <Button onClick={loadMore} variant="contained" color="primary" fullWidth>
          {loading ? 'Loading...' : 'Load more'}
        </Button>
      ) : null}
    </Paper>
  );
};

export default PaymentsTable;
