import { FC, useState, useEffect, useMemo } from 'react';
import Button from '@mui/material/Button';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { Page, WhiteBox } from 'components';
import HotspotsTable from './hotspotsTable';
import { totalHotspotsSelector, allHotspotsSelector } from 'redux/selectors/hotspots';
import HotspotsFilters from './hotspotsFilters';
import { useAppDispatch, useAppSelector } from 'app/hooks';
import { useDidUpdateEffect } from 'utils/hooks';
import { hotspotsActions } from 'redux/reducers/hotspots';

const Hotspots: FC = () => {
  const hotspots = useAppSelector(allHotspotsSelector);
  const total = useAppSelector(totalHotspotsSelector);
  const [searchValue, setSearchValue] = useState('');
  const [selectedCityOption, setSelectedCityOption] = useState('all');
  const [offset, setOffset] = useState(0);
  const dispatch = useAppDispatch();

  const onSearch = (value: string) => {
    setSearchValue(value);
  };

  useEffect(() => {
    setOffset(0);
    dispatch(
      hotspotsActions.getHotspots({
        query: searchValue,
        offset: 0,
      })
    );

    return () => {
      dispatch(hotspotsActions.updateOffset({ offset: 0 }));
    };
  }, [dispatch]);

  useDidUpdateEffect(() => {
    setOffset(0);
    dispatch(
      hotspotsActions.getHotspots({
        query: searchValue,
        offset: 0,
        cities: selectedCityOption === 'all' || selectedCityOption === 'global' ? '' : selectedCityOption,
        isGlobal: selectedCityOption === 'global',
      })
    );
  }, [searchValue, selectedCityOption]);

  const loadMore = () => {
    dispatch(
      hotspotsActions.getHotspots({
        query: searchValue,
        offset: offset + 10,
        cities: selectedCityOption === 'all' ? '' : selectedCityOption,
        isGlobal: selectedCityOption === 'global',
      })
    );
    setOffset(offset + 10);
  };

  useDidUpdateEffect(() => {
    dispatch(hotspotsActions.updateOffset({ offset: offset }));
  }, [offset]);

  return (
    <Page title="Hotspots">
      <WhiteBox>
        <Stack
          direction="row"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="1px solid rgb(247, 247, 255)"
          paddingBottom="20px"
        >
          <Typography variant="h2">Total: {total}</Typography>
          <HotspotsFilters
            searchValue={searchValue}
            onSearchChange={onSearch}
            onCityChange={setSelectedCityOption}
            selectedCityOption={selectedCityOption}
          />
        </Stack>
        <HotspotsTable />
        {total && hotspots?.length < total ? (
          <Button onClick={loadMore} variant="contained" color="primary" fullWidth>
            Load more
          </Button>
        ) : null}
      </WhiteBox>
    </Page>
  );
};

export default Hotspots;
