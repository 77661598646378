import { PayloadAction } from '@reduxjs/toolkit';
import productsApi from 'api/products';
import axios from 'axios';
import { call, put, takeLatest } from 'redux-saga/effects';
import { productsActions } from 'redux/reducers/products';
import { GetProductsResponse, GetProductsRequest, CreateProductRequest, CreateProductResponse, EditProductRequest, DeleteProductRequest } from 'types/products';

function* getProducts(data: PayloadAction<GetProductsRequest>) {
  try {
    const response: GetProductsResponse = yield call(productsApi.getProducts, data.payload);
    yield put(productsActions.getProductsSuccess(response));
  } catch (error) {
    console.log(`Failed to fetch products`, error);
    if (axios.isAxiosError(error)) {
      yield put(productsActions.getProductsFailed(error.response?.data.message));
    }
  }
};

function* updateProductsList() {
  try {
    const updatedProducts: GetProductsResponse = yield call(productsApi.getProducts, {});
    yield put(productsActions.getProductsSuccess(updatedProducts));
  } catch (error) {
    console.log(`Failed to update products list`, error);
  }
};

function* createProduct(data: PayloadAction<CreateProductRequest>) {
  try {
    const response: CreateProductResponse = yield call(productsApi.createProduct, data.payload);
    yield put(productsActions.createProductSuccess(response));
    yield call(updateProductsList)
  } catch (error) {
    console.log(`Failed to create product`, error);
    if (axios.isAxiosError(error)) {
      yield put(productsActions.createProductFailed(error.response?.data.message));
    }
  }
}

function* editProduct(data: PayloadAction<EditProductRequest>) {
  try {
    const response: CreateProductResponse = yield call(productsApi.editProduct, data.payload);
    yield put(productsActions.editProductSuccess(response));
    yield call(updateProductsList)
  } catch (error) {
    console.log(`Failed to edit product`, error);
    if (axios.isAxiosError(error)) {
      yield put(productsActions.editProductFailed(error.response?.data.message));
    }
  }
}

function* deleteProduct(data: PayloadAction<DeleteProductRequest>) {
  try {
    const response: CreateProductResponse = yield call(productsApi.deleteProduct, data.payload);
    yield put(productsActions.deleteProductSuccess(response));
    yield call(updateProductsList)
  } catch (error) {
    console.log(`Failed to delete product`, error);
    if (axios.isAxiosError(error)) {
      yield put(productsActions.deleteProductFailed(error.response?.data.message));
    }
  }
}

export default function* productsSaga() {
  yield takeLatest(productsActions.getProducts.type, getProducts);
  yield takeLatest(productsActions.createProduct.type, createProduct);
  yield takeLatest(productsActions.editProduct.type, editProduct);
  yield takeLatest(productsActions.deleteProduct.type, deleteProduct);
};
