import { Tag } from './member';

export type MatchUserProfile = {
  firstName: string;
  lastName: string;
};

export type MatchUser = {
  _id: string;
  email: string;
  profile: MatchUserProfile;
};

export type Match = {
  _id: string;
  user1: MatchUser;
  user2: MatchUser;
  commonTags: Tag[];
  message: string;
  createdBy: string;
  matchType: MatchType;
  isViewed: boolean;
  status: MatchStatus;
  createdAt: Date;
  updatedAt: Date;
};

export type MatchReview = {
  email: string;
  amountOfUsersToMatch: number;
};

export enum MatchStatus {
  LIVE = 'live',
  CONNECTED = 'connected',
  IGNORED = 'ignored',
  QUEUED = 'queued',
}

export enum MatchType {
  AUTOMATED = 'automated',
  MANUAL = 'manual',
}
