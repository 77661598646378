import { CommunityManagmentFilters, SuggestedMemberDto, Tag } from 'models/member';
import {
  GetUsersResponse,
  GetUserByIdResponse,
  GetUserByIdRequest,
  GetUsersRequest,
  GetUserReferralsResponse,
  GetUserCheckinsResponse,
  SetUserPromoRequest,
  UpdateUserProfileRequest,
  UpdateUserStatusRequest,
  UpdateUserStatus,
  GetUserSuggestedMembersResponse,
  SetUserSuggestionsRequest,
  GetUserMatchesResponse,
} from 'types/users';
import axiosApi from './config';

const usersApi = {
  getUsers({
    query = '',
    status = '',
    region = '',
    cmFilter = '',
    limit = '10',
    offset = 0,
    sort = null,
  }: GetUsersRequest): Promise<GetUsersResponse> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });

    let url = `/members?${params.toString()}${status ? `&filter[status]=${status}` : ''}${
      region ? `&filter[profile.region]=${region}` : ''
    }`;

    switch (cmFilter) {
      case CommunityManagmentFilters.NEWEST_MEMBERS: {
        url = url + `&sort[subscription.createdAt]=desc`;
        break;
      }
      case CommunityManagmentFilters.MOST_RECENT_APPLICAITONS: {
        url = url + `&sort[createdAt]=desc`;
        break;
      }
      case CommunityManagmentFilters.LOW_EVENT_PARTICIPATION: {
        url =
          url + `${sort ? sort : ''}&filter[experienceBookingsCountLast3Month]=$lte:2`;
        break;
      }
      case CommunityManagmentFilters.LOW_SPACE_UTILIZATION: {
        url = url + `${sort ? sort : ''}&filter[checkinsCountLast3Month]=$lte:2`;
        break;
      }
      case CommunityManagmentFilters.EXCEED_LISBON_CHECKINS: {
        url =
          url +
          `${
            sort ? sort : ''
          }&filter[checkinsLisbonCount]=$gt:12&filter[subscription.productId]=${
            process.env.REACT_APP_STRIPE_SUBSCRIPTION_Y_PASS_PLUS_PRODUCT_ID
          }`;
        break;
      }
      default: {
        url = url + `${sort ? sort : ''}`;
        break;
      }
    }

    return axiosApi.get(url);
  },
  getUsersForAutocomplete({
    query = '',
    limit = '10',
    offset = 0,
    sort = null,
  }: GetUsersRequest): Promise<GetUsersResponse> {
    const params = new URLSearchParams({
      query: query,
      limit,
      offset: offset.toString(),
    });

    let url = `/members/autocomplete?${params.toString()}${sort ? sort : ''}`;
    return axiosApi.get(url);
  },
  getUserById(data: GetUserByIdRequest): Promise<GetUserByIdResponse> {
    const url = `members/${data.id}`;
    return axiosApi.get(url);
  },
  getUserReferrals(data: GetUserByIdRequest): Promise<GetUserReferralsResponse> {
    const url = `members/${data.id}/referrals`;
    return axiosApi.get(url);
  },
  getUserSuggestions(data: GetUserByIdRequest): Promise<GetUserSuggestedMembersResponse> {
    const url = `members/${data.id}/suggested-members`;
    return axiosApi.get(url);
  },
  getUserCheckins(data: GetUserByIdRequest): Promise<GetUserCheckinsResponse> {
    const url = `members/${data.id}/checkins`;
    return axiosApi.get(url);
  },
  getUserMatches(data: GetUserByIdRequest): Promise<GetUserMatchesResponse> {
    const url = `members/${data.id}/matches`;
    return axiosApi.get(url);
  },
  updateUserStatus(data: UpdateUserStatusRequest): Promise<void> {
    const baseUrl = `members/${data.id}`;
    let url = '';
    switch (data.status) {
      case UpdateUserStatus.APPROVE:
        url = `${baseUrl}/approve?promoCode=${
          data.promoCode ? data.promoCode : ''
        }&expediteCode=${data.expediteCode ? data.expediteCode : ''}`;
        break;
      case UpdateUserStatus.REJECT:
        url = `${baseUrl}/reject`;
        break;
      case UpdateUserStatus.WAITLIST:
        url = `${baseUrl}/waitlist`;
        break;
      case UpdateUserStatus.APPROVE_FORM:
        url = `${baseUrl}/approve-form`;
        break;
      case UpdateUserStatus.REJECT_FORM:
        url = `${baseUrl}/reject-form`;
        break;
      default:
        throw new Error('Invalid user status');
    }

    return axiosApi.patch(url);
  },
  updateUserProfile(data: UpdateUserProfileRequest): Promise<void> {
    const url = `members/${data.id}/profile`;
    return axiosApi.put(url, data.profile);
  },
  setUserPromoCodes(data: Partial<SetUserPromoRequest>): Promise<void> {
    const url = `members/${data.id}/set-onboarding-settings`;
    return axiosApi.patch(url, {
      expediteCode: data.expediteCode,
      promoCode: data.promoCode,
    });
  },
  setUserSuggestions(data: Partial<SetUserSuggestionsRequest>): Promise<void> {
    const url = `members/${data.id}/set-suggested-members`;
    return axiosApi.patch(url, {
      suggestedMembers: data.suggestedMembers,
    });
  },
  getLocation(payload: any): Promise<void> {
    const url = `members/locations`;
    const query = convertObjectToQueryString(payload);
    return axiosApi.get(`${url}${query}`);
  },
  getLocationCount(): Promise<void> {
    const url = `members/locations/count`;
    return axiosApi.get(url);
  },
  getLocationMembers(payload: any): Promise<void> {
    const queryParams = Object.entries(payload).map(
      ([key, value]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );
    const queryString = queryParams.join('&');

    const url = `members/locations/members?${queryString}`;
    return axiosApi.get(url);
  },
  getLocationsMembersCsv(payload: any): Promise<void> {
    const queryParams = Object.entries(payload).map(
      ([key, value]: any) => `${encodeURIComponent(key)}=${encodeURIComponent(value)}`
    );
    const queryString = queryParams.join('&');

    const url = `members/locations/members/csv?${queryString}`;
    return axiosApi.get(url);
  },
  getCommonTags(user1: string, user2: string): Promise<Tag[]> {
    const url = `members/common-tags?user1=${user1}&user2=${user2}`;
    return axiosApi.get(url);
  },
};

function convertObjectToQueryString(obj: Record<string, any>) {
  const queryString = Object.keys(obj)
    .map((key) => {
      const subObj = obj[key];
      return Object.keys(subObj)
        .map((subKey) => `${key}[${subKey}]=${subObj[subKey]}`)
        .join('&');
    })
    .join('&');

  return `?${queryString}`;
}

export default usersApi;
