import { PayloadAction } from '@reduxjs/toolkit';
import appApi from "api/app";
import { call, put, takeEvery } from 'redux-saga/effects';
import { appActions } from "redux/reducers/app";
import { LoginResponse } from 'types/auth';
import { GetAllCountriesResponse } from 'types/countries';
import { PlaceResponse } from 'types/places';
import placesApi from 'api/places';
import axios from 'axios';

const MAX_PLACES_LIMIT = 100;

function* getCurrencies({payload}: PayloadAction<any>) {
  try {
    const response: LoginResponse = yield call(appApi.getCurrencies, payload);
    yield put(appActions.getCurrenciesSuccess(response));

  } catch (error) {
    console.log(`Failed to get currencies`, error);
  }
}

function* getAllCountries() {
  try {
    const response: GetAllCountriesResponse = yield call(appApi.getAllCountries);
    yield put(appActions.getAllCountriesSuccess(response));

  } catch (error) {
    console.log(`Failed to get countries`, error);
  }
}

function* getAllPlaces() {
  try {
    const limit = MAX_PLACES_LIMIT;
    let offset = 0;
    const result = [];

    let response: PlaceResponse = yield call(placesApi.getPlaces, {
      limit: limit.toString(),
      offset,
    });

    result.push(...response.data);

    while (result.length < response.total) {
      offset += limit;
      const res: PlaceResponse = yield call(placesApi.getPlaces, {
        limit: limit.toString(),
        offset,
      });

      if (res.data.length === 0) {
        break;
      }

      result.push(...res.data);
    }

    yield put(appActions.getAllPlacesSuccess(result));
  } catch (error) {
    console.log('Failed to fetch all places', error);
    if (axios.isAxiosError(error)) {
      yield put(appActions.getAllPlacesFailed(error.response?.data.message));
    }
  }
}

export default function* appSaga() {
  yield takeEvery(appActions.getCurrencies, getCurrencies);
  yield takeEvery(appActions.getAllCountries, getAllCountries);
  yield takeEvery(appActions.getAllPlaces, getAllPlaces);
}
